import React, { useEffect, useState } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import Badge from "./Badge";
import Skeleton from "./Skeleton";
import { tailwindConfig } from "../utils/Utils";
import StepsBullets from "./StepsBullets";
import Button from "./Button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminStats(props) {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState();
  const [data, setData] = useState();
  const [stepData, setStepData] = useState();
  // const data = props.data;

  // useEffect(() => {
  //   if (props.open === false) {
  //     setStats(null);
  //     console.log("GetStats open", open, stats);
  //   }
  // }, [props.open]);

  useEffect(() => {
    setStats([]);
    let thisData = props.data;
    console.log("GetStats thisData", thisData);
    if (
      thisData !== "loading" &&
      thisData !== undefined &&
      thisData !== null &&
      thisData.length > 0 &&
      Array.isArray(thisData)
    ) {
      // console.log("GetStats data", thisData);
      setData(data);
      setStats(thisData);
      //   processData(thisData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [props.data]);

  const getStepOptions = (data) => {
    let stepOptions = [];
    // setLoading(true);
    if (data == "Beginner") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "current" },
        { name: "Intermediate", href: "#", status: "upcoming" },
        { name: "Advanced", href: "#", status: "upcoming" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Intermediate") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "current" },
        { name: "Advanced", href: "#", status: "upcoming" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Advanced") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "complete" },
        { name: "Advanced", href: "#", status: "current" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Expert") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "complete" },
        { name: "Advanced", href: "#", status: "complete" },
        { name: "Expert", href: "#", status: "current" },
      ];
    }

    return stepOptions;
  };

  const getLevelName = (score) => {
    if (score <= 2) {
      return "Beginner";
    } else if (score <= 3) {
      return "Intermediate";
    } else if (score < 4) {
      return "Advanced";
    } else if (score <= 5) {
      return "Expert";
    }
    return "What?";
  };
  const handleClick = (e, value) => {
    e.preventDefault();
    console.log("The link was clicked.", true, value);
    props.handleSetCompetencyModalOpen(true, value);
  };

  const getLevelColor = (score) => {
    if (score < 2) {
      //beginner
      return "text-red-700";
    } else if (score < 3) {
      //intermediate
      return "text-orange-500";
    } else if (score < 4) {
      //advanced
      return "text-amber-500 ";
    } else if (score <= 5) {
      //expert
      return "text-emerald-500";
    } else {
      //default
      return "text-slate-600";
    }
  };

  const getLevelBackgroundColor = (score) => {
    if (score <= 2) {
      //beginner
      return "bg-red-700";
    } else if (score <= 3) {
      //intermediate
      return "bg-orange-500";
    } else if (score < 4) {
      //advanced
      return "bg-amber-500 ";
    } else if (score <= 5) {
      //expert
      return "bg-emerald-500";
    } else {
      //default
      return "bg-slate-600";
    }
  };

  return (
    <div className="flex flex-col w-full space-y-2 gap-4">
      {stats.map((items, index) => {
        return (
          // <div className="sm:flex sm:justify-between sm:items-center ">
          // <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
          //   Teams <span className="text-slate-400 font-medium"></span>
          // </h1>

          // {/* Right: Actions */}
          // <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

          // </div>
          //   </div>

          <div className="col-span-10 bg-white rounded-md shadow-lg space-y-6 gap-6">
            {/* Left Items */}
            <header className=" grid grid-flow-col align-middle px-5 py-4 border-b border-gray-100">
              <div className="flex items-center align-middle  text-lg font-semibold text-slate-800 2 ml-0">
                <UserGroupIcon className="flex h-6 w-6 mr-2 fill-accent-600 " />{" "}
                {items["Team Name"]}{" "}
              </div>
              {/* Right Items */}
              <div className="grid grid-flow-col space-x-6 items-center sm:auto-cols-max justify-start sm:justify-end gap-2">
                <div
                  className={
                    " inline-block text-lg font-bold  " +
                    getLevelColor(items["Maturity Score"])
                  }
                >
                  {items["Maturity Level"]}
                </div>
                <div className="inline-block">
                  <StepsBullets
                    steps={() => getStepOptions(items["Maturity Level"])}
                  />
                </div>

                <div
                  className={
                    "text-lg  font-semibold " +
                    getLevelColor(items["Maturity Score"])
                  }
                >
                  {items["Maturity Score"]
                    ? items["Maturity Score"].toFixed(2)
                    : 0}
                </div>
                <div>
                  <Button
                    rounded
                    disabled={items["Maturity Level"] === "None"}
                    size="xs"
                    handleClick={(e) => {
                      handleClick(e, items.competencies.value);
                    }}
                    color="secondary"
                    //   className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  >
                    View Score Details
                  </Button>
                </div>
              </div>
            </header>
            <dl className="mt- p-2 grid grid-cols-4 md:grid-cols-8 divide-y  divide-gray-200   md:divide-y-0 md:divide-x">
              {Object.entries(items).map(([key, v], index) => {
                let label = key;
                let value =
                  items && items[key] && items[key].value
                    ? items[key].value
                    : items[key];
                let measure =
                  items && items[key] && items[key].measure
                    ? items[key].measure
                    : null;
                if (value == undefined) {
                  console.log(
                    "SOMETHING WRONG key: " + key + " value: " + value
                  );
                }

                if (
                  key !== "Team Name" &&
                  key !== "Maturity Level" &&
                  key !== "Maturity Score" &&
                  key !== "summary" &&
                  key !== "competencies"
                ) {
                  return (
                    <div className="flex flex-col items-center justify-between">
                      <header className="p-6">
                        <h3 className="text-sm h-12 font-semibold text-slate-500 uppercase mb-1">
                          {key}
                        </h3>
                        <div className="relative text-2xl font-bold text-slate-800 mb-1">
                          {/* use toFixed(2) only if value has decimals */}
                          {items[key].value && items[key].value % 1 != 0
                            ? items[key].value.toFixed(2)
                            : items[key].value}
                        </div>
                        <div className="text-sm text-slate-500">
                          <span>{measure}</span>
                        </div>
                      </header>
                    </div>
                  );
                }
              })}
            </dl>
          </div>
        );
      })}
    </div>
  );
}
