import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Tabs from "../component/Tabs";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import DeleteButton from "../../partials/actions/DeleteButton";
import DateSelect from "../../components/DateSelect";
import FilterButton from "../../components/DropdownFilter";
import CustomersTable from "../../partials/customers/CustomersTable";
import PaginationClassic from "../../components/PaginationClassic";
import { customers } from "../../modules/_customers";
import ModalForm from "../../components/ModalForm";
import { _assessment } from "../../modules/_assessment";
import ModalBasic from "../../components/ModalBasic";
import AnswerList from "../../components/AnswerList";
import InviteComponent from "../../components/InviteComponent";
import { FeatureFlag } from "../../components/FeatureFlag";
import Button from "../../components/Button";
import AssessmentSelect from "../AssessmentSelect";
import { ListSelector } from "../../components/ListSelector";
import { useStore } from "../../store/usestore";
import SolicitationList from "../projects/SolicitationList";

function Customers() {
  const history = useHistory();
  const tabs = [
    { name: "Regulations", href: "../campaigns", current: false },
    { name: "Assessment history", href: "ecommerce/customers", current: true },
  ];
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [done, setDone] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [datacheck, setDataCheck] = useState(null);
  const [modalItem, setModalItems] = useState({
    questions: [{ questions: [] }],
  });
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [deletedArr, setDeletedArr] = useState([]);
  const productID = 323;
  const { sendAssessment } = useStore();

  useEffect(() => {
    (async () => {
      const data = await _assessment.getAssessmentHistory();
      if (data) {
        setDataCheck(data);
      }
      // setDataCheck(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let assessment_history = await _assessment.getAssessmentHistory();
      setDataCheck(assessment_history);
    })();
  }, [deletedArr]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const onShowShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  const setModalItem = (item) => {
    setModalItems(item);
    showHideModal();
  };

  const showHideModal1 = () => {
    setShowModal(false);
  };

  const showHideModal = () => {
    setShowModal(!showModal);
  };

  const showHideShareModal1 = () => {
    setShowShareModal(!showModal);
    // setShowShareModal(false);
  };

  const showHideShareModal = () => {
    console.info("ATTENTION - CALLING showHideShareModal");
    setShowShareModal(!showShareModal);
  };

  const handleSelectAssessment = (e, item) => {
    e.preventDefault();
    console.info("handleSelectAssessment", item);
    setSelectedAssessment(item);
    // showHideShareModal();
  };

  const onDone = (e, people, type) => {
    sendAssessment(people, type);
  };

  const goToActionPlan = (thisAssessment, counts) => {
    return history.push({
      pathname: "/actionPlan",

      state: {
        test: counts,
        data: counts,
        thisAssessment: thisAssessment,
      },
    });
  };

  const deleteAssessment = async (id) => {
    console.log("deleteAssessment", id);
    const deleted = await _assessment.deleteAssessment(id);

    if (deleted) {
      console.log("deleted", deleted);

      //delete from list by id and update state
      setDeletedArr([...deletedArr, id]);
    }
  };

  const resumeAssessment = (thisAssessment, type) => {
    let isPreassessment = false;
    if (type === "pre") {
      isPreassessment = true;
    }
    debugger;
    let state = {
      isPreassessment: false,

      thisAssessment: thisAssessment,
      assessment_type: type,
      resume: true,
    };

    return history.push({
      pathname: "/assessment",
      state: state,
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto  overflow-hidden">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        <div className="mb-0 sm:mb-0 inline-flex ">
          <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
            Assessment History
          </h1>
        </div>
        <div>
          <p className="text-sm text-slate-600 ml-2 mt-1">
            Here you can view your assessment progress and results, and resume
            an assessment.
          </p>
        </div>
        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Delete button */}
          {/* <DeleteButton selectedItems={selectedItems} /> */}
          {/* Dropdown */}
          {/* <DateSelect /> */}
          {/* Filter button */}
          {/* <FilterButton align="right" /> */}
          {/* Add customer button */}
          <Link to="../assessmentselect" className="hidden md:block">
            <button className="btn bg-secondary-600 hover:bg-secondary-700 text-white">
              <span className="hidden xs:block ml-2">Take Assessment</span>
            </button>
          </Link>
        </div>
      </div>
      {/* Table */}
      <SolicitationList
        onItemClick={null}
        project={{}}
        items={datacheck !== null ? datacheck : []}
        goToActionPlan={goToActionPlan}
        handleDeleteAssessment={deleteAssessment}
        setModalItem={(item) => setModalItem(item)}
      />
      {/* <div className="bg-white hidden lg:block shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4">
          <div className="sm:flex sm:justify-between sm:items-center mb-8">
            <div className="mb-4 sm:mb-0">
              <h2 className="font-semibold text-slate-800">
                Self Assessments
                <span className="text-slate-400 font-medium">
                  {datacheck !== null && datacheck.length}
                </span>
              </h2>
            </div>
          </div>
        </header>
        <CustomersTable
          className="hidden"
          selectedItems={handleSelectedItems}
          data={datacheck !== null && datacheck}
          setModalItem={setModalItem}
          fromDashboard={false}
          // goToActionPlan={goToActionPlan}
          // counts={counts}
        />
      </div> */}

      <ModalBasic
        id="scrollbar-modal"
        modalOpen={showModal}
        setModalOpen={showHideModal1}
        title="All Questions"
        size="lg"
        scrollable={true}
      >
        {/* Modal content */}
        <div className="px-5 py-4">
          <div className="text-sm">{<AnswerList questions={modalItem} />}</div>
        </div>
        {/* Modal footer */}
        <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
              onClick={(e) => {
                e.stopPropagation();
                showHideModal();
              }}
            >
              Done
            </button>
            {/* <button className="btn-sm bg-primary-500 hover:bg-primary-600 text-white">
              I Understand
            </button> */}
          </div>
        </div>
      </ModalBasic>
      <ModalBasic
        id="share-assessment-modal"
        modalOpen={showShareModal}
        setModalOpen={showHideShareModal}
        title="Share Assessment"
        showClose={false}
      >
        {/* Modal content */}
        <div className="px-5 py-4">
          <InviteComponent
            done={done}
            onModal={() => onShowShareModal()}
            modal={showShareModal}
          />
        </div>
        {/* Modal footer */}
      </ModalBasic>
      <ModalBasic
        id="assessment-modal"
        modalOpen={assessmentModalOpen}
        setModalOpen={setAssessmentModalOpen}
        title="Take Assessment"
        showClose={true}
        showFooter
        size="lg"
        scrollable={true}
        closeButtonText="Cancel"
      >
        <AssessmentSelect />
        {/* closeModal={setAssessmentModalOpen(false)} */}
      </ModalBasic>
    </div>

    //     </main>

    //   </div>

    // </div>
  );
}

export default Customers;
