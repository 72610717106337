import {
  AcademicCapIcon,
  BadgeCheckIcon,
  BanknotesIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeGrid(props) {
  const showHideModal = (e) => {
    console.log("showHideModal", e);
    props.handleOpenAssessmentModal(e);
  };
  const setShowContactModal = (e) => {
    console.log("setShowContactModal", e);
    props.setShowContactModal(e);
  };
  const history = useHistory();

  const goToTemplates = (e) => {
    e.preventDefault();
    return history.push({
      pathname: "/settings/account",

      state: {
        panel: "assessment_templates",
      },
    });
  };
  let actions = [
    // {
    //   title: "Knowledge Center",
    //   href: "/campaigns",
    //   icon: ClockIcon,
    //   iconForeground: "text-teal-700",
    //   iconBackground: "bg-teal-50",
    //   description:
    //     "Check out articles and videos related to various jurisdictions. From there you can choose to take an assessment.",
    // },
    // {
    //   title: "Knowledge Center",
    //   href: "/learn",
    //   icon: AcademicCapIcon,
    //   iconForeground: "text-purple-700",
    //   iconBackground: "bg-purple-50",
    //   description:
    //     "Check out articles and videos related to various jurisdication and brush up on your knowledge before diving into the assessments.",
    // },

    // {
    //   title: "Create an Assessment Template",
    //   href: "#",
    //   onClick: (e) => {
    //     goToTemplates(e);
    //   },
    //   icon: AcademicCapIcon,
    //   iconForeground: "text-purple-700",
    //   iconBackground: "bg-purple-50",
    //   description:
    //     "Need to add your own assessment?  Click here and you'll be able to create a new assessment template and add, remove, or import questions.",
    // },

    {
      title: "Take the self-assessment",
      allowed: ["Super Admin", "Administrator", "Manager"],
      href: "#",
      onClick: (e) => {
        console.info("onClick", e);
        showHideModal(e);
      },
      icon: ReceiptRefundIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      description:
        "This is where you start your journey.  Take the competency assessment to see where you stand today and to see where you can improve.",
    },
    {
      title: "View Competencies",
      allowed: ["Collaborator"],
      href: "/competencies",
      icon: ReceiptRefundIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      description:
        "Check out how your team scored on the competency self-assessment and see the areas your team can improve on.",
    },
    {
      title: "Invite Team Members",
      allowed: ["Super Admin", "Administrator", "Manager"],
      href: "/settings/account",
      icon: UsersIcon,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
      description:
        "Collaborate with your team, share assessments, and track progress.  Invite your team members to join you on your journey.",
    },
    {
      title: "View Impediments",
      allowed: ["Collaborator"],
      href: "/impediments",
      icon: UsersIcon,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
      description:
        "Check out your team's impediments.  Help your team out by adding impediments that you see and help your team remove impediments.",
    },
    {
      title: "Get Help",
      allowed: [
        "Super Admin",
        "Administrator",
        "Manager",
        "Collaborator",
        "Observer",
        "Guest",
      ],
      href: "#",
      onClick: (e) => {
        console.info("onClick", e);
        props.handleOpenContactModal(e);
      },
      icon: BanknotesIcon,
      iconForeground: "text-indigo-700",
      iconBackground: "bg-indigo-50",
      description:
        "Not sure where to start? Need help? Click here to get in touch with us and we'll be happy to help you out.",
    },
    {
      title: "Education Center",
      allowed: [
        "Super Admin",
        "Administrator",
        "Manager",
        "Collaborator",
        "Observer",
        "Guest",
      ],
      // href: "/learn",
      href: "https://einstein.kcura.com/pages/viewpage.action?pageId=301068648",
      icon: AcademicCapIcon,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      description:
        "Check out the Playbooks for a refresher on DevOps.  We will update this link soon with more resources so look for the UPDATED label.",
    },
  ];

  actions = actions.filter((action) =>
    action.allowed.includes(props.profile.role)
  );

  return (
    <div className=" border row-span-1  rounded-lg bg-slate-200  shadow divide-y divide-slate-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
      {
        (console.log("actions", actions),
        actions.map(
          (action, actionIdx) =>
            action.allowed.includes(props.profile.role) && (
              <div
                key={action.title}
                className={classNames(
                  actionIdx === 0
                    ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                    : "",
                  actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                  actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                  actionIdx === actions.length - 1
                    ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                    : "",
                  " hover:bg-primary-50 relative group bg-white pt-4 pl-6 pr-6 pb-12   focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary-500"
                )}
              >
                <div>
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      "rounded-lg inline-flex p-3 ring-4 ring-white"
                    )}
                  >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                  </span>
                  {/* </div>
          <div className="mt-8"> */}
                  <h3 className="text-lg text-slate-700 font-medium inline ml-4">
                    {action.title != "Education Center" ? (
                      <NavLink
                        className="focus:outline-none "
                        to={action.href}
                        onClick={action.onClick}
                      >
                        <span className="absolute inset-0" aria-hidden="true" />
                        {action.title}
                      </NavLink>
                    ) : (
                      <a
                        href={action.href}
                        target="_blank"
                        className="focus:outline-none"
                      >
                        <span className="absolute inset-0" aria-hidden="true" />
                        {action.title}
                      </a>
                    )}
                  </h3>
                  <p className="mt-2 text-sm text-slate-500">
                    {action.description}
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-slate-300 group-hover:text-slate-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            )
        ))
      }
    </div>
  );
}
