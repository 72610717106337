import React, { useState } from "react";
import { useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

import Table, {
  SelectColumnFilter,
  GlobalFilter,
} from "../components/ReactTable";

import Button from "../components/Button";
import { FeatureFlag } from "../components/FeatureFlag";
import { DocumentPlusIcon, PencilIcon } from "@heroicons/react/20/solid";

import { _competencies } from "../modules/competencies";
import SimpleCard from "../components/SimpleCard";
import RightActionArea from "../components/ActionAreaRight";
import ButtonGroup from "../components/ButtonGroup";
import AssessmentSelect from "./AssessmentSelect";
import ModalBasic from "../components/ModalBasic";
import Skeleton from "../components/Skeleton";
import AssessmentTemplateCreator from "../components/AssessmentTemplatesCreator";
import AssessmentTemplateQuestionEditor from "../components/AssessmentTemplatesQuestEditor";
import { Icon } from "@iconify/react";
import { _auth } from "../modules/_auth";
import { audit } from "../modules/_audit";

function Competencies() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [questions, setQuestions] = useState([{}]);
  const [teamCompetencies, setTeamCompetencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [view, setView] = useState("grid");
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [impedimentModalOpen, setImpedimentModalOpen] = useState(false);
  const [viewImpedimentModalOpen, setViewImpedimentModalOpen] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [selectedImpediment, setSelectedImpediment] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [thisAssessment, setThisAssessment] = useState(null);
  const [isDraft, setIsDraft] = useState(false);
  const history = useHistory();
  const stc = true;
  const RenderActions = (props) => {
    // console.log("RenderActions", props.data);
    return (
      <div className="flex items-center space-x-3">
        <Button
          size="sm"
          rounded
          color="secondary"
          loader={loading}
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={(e) => claim(e, props.row.original, props.data)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-4 h-4 mr-2 -ml-1"
          >
            <path
              fill-rule="evenodd"
              d="M2.232 12.207a.75.75 0 011.06.025l3.958 4.146V6.375a5.375 5.375 0 0110.75 0V9.25a.75.75 0 01-1.5 0V6.375a3.875 3.875 0 00-7.75 0v10.003l3.957-4.146a.75.75 0 011.085 1.036l-5.25 5.5a.75.75 0 01-1.085 0l-5.25-5.5a.75.75 0 01.025-1.06z"
              clip-rule="evenodd"
            />
          </svg>

          <span>Claim</span>
        </Button>
        <Button
          size="sm"
          rounded
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={() => viewDetails(props.row.original, props.data)}
        >
          <svg
            className="w-4 h-4 mr-2 -ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>View</span>
        </Button>
      </div>
    );
  };
  const profile = _auth.getUserProfile();

  const addQuestionRef = useRef(null);

  //get employees from api

  useEffect(() => {
    (async () => {
      let teamCompList = await _competencies.getTeamCompetenciesFromSurvey();
      console.log("TeamCompList", teamCompList);
      if (teamCompList && teamCompList.data && teamCompList.data.length > 0) {
        console.log("TeamCompList ID", teamCompList.assessmentId);
        let newCompList = teamCompList.data;
        setAssessmentId(teamCompList.assessmentId);
        setCompetencies(newCompList);
        setThisAssessment(teamCompList.thisAssessment);
      } else {
        setIsDraft(
          teamCompList && teamCompList.assessment_status == "Draft"
            ? true
            : false
        );
        setThisAssessment(teamCompList.thisAssessment);
        let compList = await _competencies.getCompetencies();
        if (compList) {
          console.log("CompList", compList);
          setCompetencies(compList);
        }
      }
    })();
  }, [stc]);

  useEffect(() => {
    (async () => {
      let teamCompList = await _competencies.getTeamCompetenciesFromSurvey();
      console.log("TeamCompList", teamCompList);
      if (teamCompList && teamCompList.data && teamCompList.data.length > 0) {
        console.log("TeamCompList ID", teamCompList.assessmentId);

        setAssessmentId(teamCompList.assessmentId);
        // setCompetencies(newCompList);
        setThisAssessment(teamCompList.thisAssessment);
      }
    })();
  }, [competencies]);

  const addImpedimentToCompetency = (impediment) => {
    console.log("addImpedimentToCompetency", impediment);
    const newCompetency = { ...selectedCompetency };
    newCompetency.impediments.push(impediment);
    // find and remove old competency and add new one
    const newCompetencies = competencies.map((c) => {
      if (c._id === newCompetency._id) {
        return newCompetency;
      }
      return c;
    });
    setCompetencies(newCompetencies);
  };

  const removeQuestionByIndex = (index) => {
    const newQuestions = questions.filter((q, i) => i !== index);
    setQuestions(newQuestions);
    // props.setQuestions(newQuestions);
  };
  const handleChangeCompetencyLevel = async (data, new_answer) => {
    console.log(
      "handleChangeCompetencyLevel1",
      assessmentId,
      data._id,
      new_answer
    );
    const updated = await _competencies.changeCompetencyLevel(
      assessmentId,
      data._id,
      new_answer
    );
    console.log("handleChangeCompetencyLevel2", updated);
    if (updated) {
      setCompetencies((old) =>
        old.map((row, index) => {
          if (row._id === data._id) {
            return {
              ...row,
              your_answer: new_answer,
            };
          }
          return row;
        })
      );
    }
    const auditDetails = {
      assessmentId: assessmentId,
      user: profile.email,
      area: data.Area,
      name: data.Name,
      old_answer: data.your_answer,
      new_answer: new_answer,
    };
    audit.logAction("Change Competency Level", auditDetails);
  };

  const updateQuestionsByIndex = (index, update) => {
    const field = Object.keys(update)[0];
    const value = Object.values(update)[0];
    console.log("Update_question", field, value);

    setQuestions((questions) => {
      const newQuestions = [...questions];
      newQuestions[index][field] = value;
      return newQuestions;
    });
  };

  const replaceQuestion = (question) => {
    console.log("replaceQuestion", question);
    const newQuestions = questions.map((q) => {
      if (q._id == question._id) {
        return question;
      } else {
        return q;
      }
    });
    setQuestions(newQuestions);
  };

  const viewDetails = (row, list) => {
    // console.log("ViewDetails", list);
    let state = { data: row, list: list };
    let path = "/employees/profile";
    return history.push({
      pathname: path,
      state: state,
    });
  };

  const claim = (e, row, list) => {
    e.preventDefault();
    // console.log("ViewDetails", list);
    //set loading for 2 second
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Team",
        accessor: "Team",
        Filter: SelectColumnFilter, // new
        filter: "includes", // new
      },
      {
        Header: "Status",
        accessor: "status",
      },
      //   {
      //     Header: "Role",
      //     accessor: "Role",
      //     Filter: SelectColumnFilter, // new
      //     filter: "includes", // new
      //   },
      //   {
      //     Header: "Manager",
      //     accessor: "Manager",
      //     Filter: SelectColumnFilter, // new
      //     filter: "includes", // new
      //   },
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: RenderActions,
      },
    ],
    []
  );

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handleClick = (viewType) => {
    console.log("setView", viewType);
    setView(viewType);
  };

  const handleSetImpedimentModalOPen = (data) => {
    console.log("handleSetImpedimentModalOPen", true, data);
    setImpedimentModalOpen(true);
    setSelectedCompetency(data);
  };

  const handleSetViewImpedimentModalOpen = (data) => {
    console.log("handleSetViewImpedimentModalOpen", true, data);
    setViewImpedimentModalOpen(true);
    setSelectedCompetency(data);
    setSelectedImpediment(data.impediments);
  };

  const areas = [
    "Culture",
    "Plan",
    "Code",
    "Build",
    "Test",
    "Release",
    "Deploy",
    "Operate",
    "Monitor",
    "Respond",
  ];

  const goToActionPlan = () => {
    return history.push({
      pathname: "/actionPlan",

      state: {
        test: "props.counts",
        data: "props.counts",
        thisAssessment: thisAssessment,
      },
    });
  };

  const resumeAssessment = () => {
    let state = {
      isPreassessment: false,

      thisAssessment: thisAssessment,
      assessment_type: thisAssessment.assessment_type,
      resume: true,
    };

    // getUserAssessmentHistory()

    return history.push({
      pathname: "/assessment",
      state: state,
    });
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto space-y-6">
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          <div className="mb-0 sm:mb-0 inline-flex ">
            <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
              Competencies
            </h1>
          </div>
          <RightActionArea>
            {/* <ButtonGroup handleClick={(viewType) => handleClick(viewType)} /> */}
            {thisAssessment &&
              thisAssessment !== null &&
              thisAssessment !== undefined &&
              thisAssessment.assessment_status == "Finished" && (
                <Button
                  color="accent"
                  shadow
                  handleClick={() => goToActionPlan()}
                  className="ml-4"
                >
                  {/* <DocumentPlusIcon className="h-6 w-6" /> */}
                  <span className="hidden xs:block ml-2">See Action Plan </span>
                </Button>
              )}

            {thisAssessment &&
            thisAssessment !== null &&
            thisAssessment !== undefined &&
            thisAssessment.assessment_status &&
            thisAssessment.assessment_status !== "Finished" ? (
              <Button
                rounded
                color="indigo"
                type="button"
                handleClick={() => resumeAssessment()}

                // className="inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-accent-600 hover:bg-accent-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
              >
                <PencilIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                Resume Assessment
              </Button>
            ) : (
              <Button
                color="secondary"
                shadow
                handleClick={() => setAssessmentModalOpen(true)}
                className="ml-4"
              >
                <DocumentPlusIcon className="h-6 w-6" />
                <span className="hidden xs:block ml-2">Take Assessment</span>
              </Button>
            )}
          </RightActionArea>
        </div>
        {/* map areas and create div for each area */}
        {loading ? (
          <Skeleton type="spinner" fill="primary" />
        ) : (
          view !== "table" &&
          areas.map((area) => (
            <div className="bg-white shadow-lg  items-center border border-gray-200  justify-between p-4 space-y-8">
              <h2 className="text-xl font-semibold text-slate-800">{area}</h2>
              {competencies.map(
                (competency) =>
                  competency.Area === area && (
                    <>
                      <div>
                        <SimpleCard
                          setImpedimentModalOpen={() =>
                            handleSetImpedimentModalOPen(competency)
                          }
                          setViewImpedimentModalOpen={() =>
                            handleSetViewImpedimentModalOpen(competency)
                          }
                          setSelectedCompetency={(comp) =>
                            setSelectedCompetency(comp)
                          }
                          handleChangeCompetencyLevel={
                            handleChangeCompetencyLevel
                          }
                          data={competency}
                          view={view}
                        />
                      </div>
                    </>
                  )
              )}
            </div>
          ))
        )}
      </div>
      <ModalBasic
        id="assessment-modal"
        modalOpen={assessmentModalOpen}
        setModalOpen={setAssessmentModalOpen}
        title="Take Assessment"
        showClose={true}
        showFooter
        closeButtonText="Cancel"
      >
        <AssessmentSelect />
        {/* closeModal={setAssessmentModalOpen(false)} */}
      </ModalBasic>

      <ModalBasic
        id="add-impediment-modal"
        modalOpen={impedimentModalOpen}
        setModalOpen={setImpedimentModalOpen}
        title={
          selectedCompetency !== null
            ? "Impediments related to " +
              (selectedCompetency.Area + " - " + selectedCompetency.Name)
            : "Impediments"
        }
        showClose={true}
        showFooter
        closeButtonText="Close"
        scrollable={true}
        size="lg"
      >
        <AssessmentTemplateQuestionEditor
          assessment_type={"impediment"}
          selectedCompetency={selectedCompetency !== null && selectedCompetency}
          questions={
            selectedCompetency !== null && selectedCompetency.impediments
              ? selectedCompetency.impediments
              : []
          }
          addQuestionRef={null} //{addQuestionRef}
          refreshQuestions={addImpedimentToCompetency} //{refreshQuestions}
          setQuestions={() => addImpedimentToCompetency()} //{setQuestions}
          modalItem={null}
          title={"Impediment "}
          description={"Add a new impdiment below"}
        />
      </ModalBasic>
      <ModalBasic
        id="view-impediment-modal"
        modalOpen={viewImpedimentModalOpen}
        setModalOpen={setViewImpedimentModalOpen}
        title={
          "View related impediments to " +
          (selectedCompetency !== null && selectedCompetency.Name)
        }
        showClose={true}
        showFooter
        closeButtonText="Close"
        scrollable={true}
        size="lg"
      >
        {selectedImpediment && selectedImpediment.length > 0 && (
          <div className="mt-6 space-y-4">
            {selectedImpediment.map((impediment) => (
              <div className=" mx-6 bg-white rounded-md shadow-lg text-slate-800  items-center border border-gray-200  justify-between p-4 space-y-8">
                <div className="grid grid-flow-col grid-cols-12 justify-start sm:justify-start gap-2">
                  <div className="font-medium">{selectedCompetency.Area}</div>
                  <div className="font-medium"> {selectedCompetency.Name}</div>
                  {/* </div>
                <div className="inline-flex grid grid-flow-col  justify-start sm:justify-end gap-2"> */}
                  <div className="font-medium col-start-11">
                    {impediment.score && impediment.score > 0
                      ? "Burden:" + impediment.score
                      : null}
                  </div>
                  <div className="font-medium col-start-12 ">
                    {impediment.effort && impediment.effort > 0
                      ? "Effort:" + impediment.effort
                      : null}
                  </div>
                </div>
                <div className="flex justify-between">
                  {" "}
                  {impediment.Description}
                </div>
              </div>
            ))}
          </div>
        )}
      </ModalBasic>
    </>
  );
}

export default Competencies;
