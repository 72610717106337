import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const _impediments = {
  // two ways to get impediments
  // 1. get all impediments for a company
  // 2. get all impediments for a team

  getImpedimentTypeList() {
    let options = [
      {
        id: 1,
        value: "Infrastructure",
        name: "Infrastructure",
        area: "Technical",
        description: "",
        Examples: [],
      },
      {
        id: 1,
        value: "Architecture",
        name: "Architecture",
        area: "Technical",
        description: "",
        Examples: [],
      },
      {
        id: 1,
        value: "Other",
        name: "Other",
        area: "Technical",

        description: "",
        Examples: [],
      },
      {
        id: 2,
        value: "Insufficient Tooling",
        name: "Insufficient Tooling",
        area: "Operational",
        description: "",
        Examples: [],
      },
      {
        id: 3,
        value: "Process",
        name: "Process",
        area: "Procedural",
        description: "",
        Examples: [],
      },
      {
        id: 4,
        value: "Org Chart",
        name: "Org Chart",
        area: "Organizational",
        description: "",
        Examples: [],
      },
    ];
    return options;
  },

  async getImpediments(scope) {
    let profile = _auth.getUserProfile();
    //how do we know which one to get?
    // if the user is a company admin, get all impediments for the company
    let data;
    if (scope == true) {
      console.log(
        profile.tenantInfo.type,
        "Getting impediments for all teams in company"
      );
      data = {
        tenantId: null,
        companyId: profile.companyId,
      };
    } else {
      console.log(
        profile.tenantInfo.type,
        "Getting impediments for this team",
        profile.tenantId
      );
      data = {
        tenantId: profile.tenantId,
        companyId: profile.companyId,
      };
    }

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/impediments/get", data);
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log("/impediments/get", response.data.data.data);
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async createImpediment(data) {
    let profile = _auth.getUserProfile();

    // try {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let body = {
      updates: data,
      tenantId: profile.tenantId,
    };

    console.log("Create impediment as follows", data);

    let response = await postRequest("/impediments/create", body);
    //   console.log("/impediments/get", response);
    if (response && response.data) {
      console.log("/impediments/create", response.data.data.data);
      return response.data.data.data;
    } else {
      return [];
    }
    // } catch (e) {
    //   return [];
    // }
  },
  async deleteImpediment(data) {
    let profile = _auth.getUserProfile();
    let set = await console.log("Create impediment as follows", data);
    if (set) {
      return set;
    } else {
      return [];
    }

    // try {
    //   let token = localStorage.getItem("jwtToken");
    //   setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    //   let data = {
    //     tenantId: profile.tenantId,
    //     teamId: profile.teamId,
    //     ids: [impedimentId],
    //     updates: {},
    //     competencyId: competencyId,
    //   };
    //   data.updates[fieldname] = value;

    //   console.log("Update impediment as follows", data);

    //   let response = await postRequest("/impediments/update", data);
    //   //   console.log("/impediments/get", response);
    //   if (response && response.data) {
    //     console.log("/impediments/update", response.data.data.data);
    //     return response.data.data.data;
    //   } else {
    //     return [];
    //   }
    // } catch (e) {
    //   return [];
    // }
  },
  async updateImpediment(
    impedimentId,
    competencyId,
    fieldname,
    value,
    otherData
  ) {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        tenantId: profile.tenantId,
        teamId: profile.teamId,
        ids: [impedimentId],
        updates: {},
        competencyId: competencyId,
      };
      data.updates[fieldname] = value;
      if (otherData) {
        data.updates = { ...data.updates, ...otherData };
      }

      console.log("Update impediment as follows", data);

      let response = await postRequest("/impediments/update", data);
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log("/impediments/update", response.data.data.data);
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async updateImpedimentCompetencyId(impedimentId, competencyId) {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        tenantId: profile.tenantId,
        teamId: profile.teamId,
        impedimentId: impedimentId,
        competencyId: competencyId,
      };

      let response = await postRequest(
        "/impediments/update-competency-id",
        data
      );
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log(
          "/impediments/update-competency-id",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async claimImpediment(impedimentId, claimed) {
    try {
      let profile = _auth.getUserProfile();
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      console.log("claimImpediment", profile, token);
      let data = {
        tenantId: profile.tenantId,
        // teamId: profile.teamId ? profile.teamId : profile.tenantId,

        ids: [impedimentId],
        updates: {
          Claimed_by_team: claimed ? profile.tenantId : null,
          Claimed_by_person: profile._id,
          Claimed: claimed,
        },
      };

      let response = await postRequest("/impediments/claim", data);

      if (response && response.data) {
        console.log("/impediments/claim", response.data.data.data);
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getResolvedAndRemainingBurden(admin) {
    let profile = _auth.getUserProfile();

    console.log("getResolvedAndRemainingBurden", admin);

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        tenantId: profile.tenantId,
        company_id: profile.companyId,
        admin: admin,
      };

      let response = await postRequest(
        "/dashboard/get-resolved-and-remaining-burden",
        data
      );

      if (response && response.data) {
        console.log(
          "/dashboard/get-resolved-and-remaining-burden",
          response.data.data
        );
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async resolveImpediment(impedimentId, competencyId, fieldname, value) {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        tenantId: profile.tenantId,
        teamId: profile.teamId,
        ids: [impedimentId],
        updates: {},
        competencyId: competencyId,
      };
      data.updates[fieldname] = value;
      data.updates["resolveDate"] = value == true ? new Date() : null;
      data.updates["resolvedBy"] = profile._id;

      console.log("Update impediment as follows", data);

      let response = await postRequest("/impediments/update", data);
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log("/impediments/update", response.data.data.data);
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getImpedimentSummary() {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        companyId: profile.companyId,
      };

      let response = await postRequest(
        "/impediments/get-impediment-summary",
        data
      );

      if (response && response.data) {
        console.log("/impediments/get-impediment-summary", response.data);
        return response.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
};
