import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Tooltip from "../../components/Tooltip";
import {
  BadgeCheckIcon,
  CollectionIcon,
  DocumentChartBarIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { useStore } from "../../store/usestore";
import Button from "../../components/Button";
import { TrashIcon } from "@heroicons/react/20/solid";

function CustomersTableItem(props) {
  const history = useHistory();
  const fromDashboard = props.fromDashboard;
  const counts = props.counts;
  const { getUserAssessmentHistory } = useStore();
  // const showHideModal = props.showHideModal;
  const setModalItem = () => {
    // console.info("ATTENTION - C-TABLE-ITEM - SHOW MODAL");
    props.setModalItem(props.thisAssessment);
  };
  // console.info("C-Table", props);

  const goToActionPlan = () => {
    return history.push({
      pathname: "/actionPlan",

      state: {
        test: props.counts,
        data: props.counts,
        thisAssessment: props.thisAssessment,
      },
    });
  };

  const deleteAssessment = () => {
    console.log("deleteAssessment Button Click", props.thisAssessment._id);
    props.handleDeleteAssessment(props.thisAssessment._id);
  };

  const resumeAssessment = () => {
    let isPreassessment = false;
    if (props.type === "pre") {
      isPreassessment = true;
    }
    debugger;
    let state = {
      isPreassessment: isPreassessment,

      thisAssessment: props.thisAssessment,
      assessment_type: props.type,
      resume: true,
    };

    // getUserAssessmentHistory()

    return history.push({
      pathname: "/assessment",
      state: state,
    });
  };
  // const goToActionPlan = props.goToActionPlan;
  const typeColor = (type, status) => {
    if (type == "risk") {
      switch (status) {
        case "Normal":
          return "bg-green-100 text-green-600";
        case "Medium":
          return "bg-yellow-100 text-yellow-600";
        case "High":
          return "bg-red-100 text-red-600";
        default:
          return "";
      }
    }
    if (type == "status") {
      switch (status) {
        case "Finished":
          return "text-green-600";
        case "In Progress":
          return "text-red-600";
        default:
          return "text-slate-500";
      }
    }
  };
  return (
    <tr>
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input
              id={props.id}
              className="form-checkbox"
              type="checkbox"
              onChange={props.handleClick}
              checked={props.isChecked}
            />
          </label>
        </div>
      </td> */}
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center relative">
          <button>
            <svg className={`w-4 h-4 shrink-0 fill-current ${props.fav ? 'text-yellow-500' : 'text-slate-300'}`} viewBox="0 0 16 16">
              <path d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z" />
            </svg>
          </button>
        </div>
      </td> */}
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{props.id}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {/* <div className="flex items-center"> */}
        {/* <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img
              className="rounded-full"
              src={props.image}
              width="40"
              height="40"
              alt={props.name}
            />
          </div> */}
        <div className="text-left font-medium text-light-blue-500">
          <div className="text-left inline-flex">
            {" "}
            {props.name}{" "}
            {props.contributors === true && props.type != "pre" && (
              <Tooltip
                className={"whitespace-normal"}
                size="xl"
                bg="dark"
                position={"right"}
                title={"What is this?"}
                description={
                  "It looks like multiple people contributed to this assessment. That's awesome because cybersecurity is a team sport! You can see who answered each question in your action plan."
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline ml-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                </svg>
              </Tooltip>
            )}
          </div>
        </div>
        {/* </div> */}
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {/* <div className="text-left">{props.type}</div> */}
        <div className="text-left uppercase">{props.type}</div>
      </td>
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">{props.orders}</div>
      </td> */}
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-slate-800">{props.date}</div>
      </td>
      {/* <td className="text-left px-2 first:pl-5 last:pr-10 py-3 whitespace-nowrap ">
        <div
          className={`text-center w-24  rounded-full ${typeColor(
            "risk",
            props.risk
          )}`}
        >
          {props.risk === "null" || props.risk === null ? "" : props.risk}{" "}
        </div>
      </td> */}
      <td className="text-left px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  align-middle">
        <div
          className={`text-left  rounded-full ${typeColor(
            "status",
            props.status
          )}`}
        >
          {/* {props.status} */}
          {/* {props.status === "Finished" ? (
            <div
              className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100  ${typeColor(
                "risk",
                props.risk
              )} `}
            >
              {props.risk === "null" || props.risk === null ? (
                ""
              ) : props.risk == "High" ? (
                <ExclamationIcon className="h-4 w-4 mr-2" />
              ) : (
                <ExclamationCircleIcon className="h-4 w-4 mr-2" />
              )}

              {props.risk}
              {" Risk"}
            </div>
          ) : (
            <div class="pr-5 w-36 bg-gray-200 h-1  align-middle rounded-full">
              <div
                class="bg-green-500 h-1 rounded-full"
                style={{ width: props.progress * 100 + "%" }}
              ></div>
            </div>
          )} */}
        </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex space-x-2 text-left font-medium ">
          {props.status === "Finished" ? (
            props.type != "pre" && (
              <div>
                {props.risk == "High" && (
                  <Tooltip
                    className={"whitespace-normal  inline-flex"}
                    size="sm"
                    bg="dark"
                    position={"top"}
                    // title={"What is this?"}
                    description={"View your action plan"}
                  >
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        goToActionPlan();
                      }}
                      className="inline-flex items-center p-2  border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      <DocumentChartBarIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                )}

                {fromDashboard == false && (
                  <Tooltip
                    className={"whitespace-normal  inline-flex"}
                    size="sm"
                    bg="dark"
                    position={"top"}
                    // title={"What is this?"}
                    description={"View questions and answers"}
                  >
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalItem();
                      }}
                      className="inline-flex items-center p-2 ml-1 border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      <QuestionMarkCircleIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                )}
              </div>
            )
          ) : (
            <button
              type="button"
              onClick={resumeAssessment}
              className="inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-accent-600 hover:bg-accent-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
            >
              <PencilSquareIcon className="h-4 w-4 mr-2" aria-hidden="true" />
              Resume
            </button>
          )}
          <Button iconButton handleClick={deleteAssessment} type="delete">
            <TrashIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </Button>
        </div>
      </td>
    </tr>
  );
}

export default CustomersTableItem;
