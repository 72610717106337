import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function RadioGroupCards(props) {
  const [selectedMailingLists, setSelectedMailingLists] = useState(
    props.defaultValue
  );

  const items = props.options;

  useEffect(() => {
    props.onChange(selectedMailingLists, "single", props.name);
  }, [selectedMailingLists]);

  const handleSelect = (e) => {
    console.log("handleSelect");
    setSelectedMailingLists(e);
  };

  return (
    <RadioGroup
      defaultValue={props.defaultValue}
      value={selectedMailingLists}
      onChange={(e) => handleSelect(e)}
    >
      {/* <RadioGroup.Label className="text-base font-medium text-slate-900">
        Select an action
      </RadioGroup.Label>
      <p className="mt-3 col-span-6 text-sm text-slate-500">
        Select from an option below to choose what screen you want to show at
        the end of this assessment.
      </p> */}
      <div
        className={`${
          props.className
            ? props.className
            : "mt-4 grid  gap-y-6 grid-cols-3  sm:gap-x-4"
        }`}
      >
        {items.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList.value}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "border-secondary-500 ring-2 ring-secondary-500" : "",
                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                "col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-slate-900"
                    >
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-sm text-slate-500"
                    >
                      {mailingList.description}
                    </RadioGroup.Description>
                    {/* <RadioGroup.Description
                      as="span"
                      className="mt-6 text-sm font-medium text-slate-900"
                    >
                      {mailingList.users}
                    </RadioGroup.Description> */}
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-secondary-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-secondary-500" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
