import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';


import ProfileSidebar from '../components/ProfileSidebar';
import Button from '../components/Button';
import ProfileBody from '../components/ProfileBody';
import { _assessment } from '../modules/_assessment';

function EmployeeProfile( ...props ) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);
  const [data, setData] = useState(location.state.data);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [list, setList] = useState(location.state.list);

const fullList = location.state.list;
console.log("fullList", list);

    useEffect(() => {
      (async () => {
      const getHistory = await _assessment.getEmployeeAssessmentHistory(data);
      console.log("EmpAssessmentResponse", getHistory);
      if (getHistory) {
        // console.log("getHistory", getHistory);
        if (Array.isArray(getHistory)) {
          setAssessmentHistory(getHistory);
        } else {
          setAssessmentHistory([getHistory]);
        }
      }
    })();
    
  }, [data]);
  
  const handleSetData = (data) => {
    console.log("Data Received from handleSelectEmployee", data);
    setData(data);
  }

  const handleSetList = (list) => {
    console.log("List Received from handleSelectEmployee", list);
    setList(list);
  }
  
  console.info("handleSelectEmployee Employee Profile", data);
  return (
    
   
        <main >
          <div className="relative flex bg-white">
            
         
            {/* Profile sidebar */}
            <ProfileSidebar 
              profileSidebarOpen={profileSidebarOpen} 
              setProfileSidebarOpen={setProfileSidebarOpen} 
              data={list}
              allData = {fullList}
              // title={data.Name}
              selected={data._id}
              // setSelected={setSelected}
              setData={handleSetData}
              setList={handleSetList}
               />

            {/* Profile body */}
            <ProfileBody 
            className=""
            profileSidebarOpen={profileSidebarOpen} 
            setProfileSidebarOpen={setProfileSidebarOpen} 
            data={data} 
            title={data.Name} 
            manager = {data.manager}
            assessmentHistory={assessmentHistory}
             />
            

          </div>
        </main>

  );
}

export default withRouter(EmployeeProfile);