import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ActionPlanExplanation from "../components/ActionPlanExplanation";
import ContentSplit from "../components/ContentSplit";
import { _assessment } from "../modules/_assessment";
import Button from "../components/Button";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
// import { _assessment } from "../modules/_assessment";

function ActionPlan(props) {
  const printRef = useRef();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [correctTotal, setCorrectTotal] = useState(0);
  const [incorrectTotal, setIncorrectTotal] = useState(0);
  const [percentCorrect, setPercentCorrect] = useState(0);

  // const counts = location.state.data;
  const thisAssessment = location.state.thisAssessment;

  console.info("My State", location.state.test);

  console.info("ASMT My Assessment", location.state.thisAssessment);

  const handleDownloadPdf = async () => {
    setLoading(true);
    // try {
    // debugger;
    const element = printRef.current;
    // debugger;
    const canvas = await html2canvas(element);
    // debugger;
    const imgWidth = 208;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF("p", "mm");
    doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      heightLeft -= pageHeight;
    }
    setLoading(false);
    doc.save("Action Plan Download.pdf");
  };
  //  catch (e) {
  //   throw e;
  // }
  // };
  // const data = _assessment.getAssessmentQuestions1("PCI");
  const data = thisAssessment;
  const answers = props.answers;
  const risk = thisAssessment ? thisAssessment.risk_score : 0;

  useEffect(() => {
    //get total number of answers where question.core != 3
    let correct = 0;
    let incorrect = 0;
    thisAssessment.questions.forEach((question) => {
      if (question.score == 3) {
        correct++;
      } else {
        incorrect++;
      }
    });
    setCorrectTotal(correct);
    setIncorrectTotal(incorrect);
    setPercentCorrect((correct / (correct + incorrect)) * 100);
    setPercentCorrect(percentCorrect.toFixed(2));
  }, []);

  const handleClickBack = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/assessments",
      state: { thisAssessment: thisAssessment },
    });
  };

  return (
    <div ref={printRef} className="px-4 sm:px-6 lg:px-8 py-8 w-full  ">
      <div className="max-w-5xl mx-auto  content-center  ">
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Welcome banner */}
          <div className="mb-0 sm:mb-0 inline-flex ">
            <Button color="accent" rounded handleClick={handleClickBack}>
              <svg
                className="h-6 w-6 text-white-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                ></path>
              </svg>
              Back
            </Button>

            {/* <Link to="assessments">
              <div className="mt-25 space-y-10">
                <span className=" text-md text-primary-500">
                  &larr; Back to Assessment History
                </span>
              </div>
            </Link> */}
          </div>
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <Button
              loader={loading}
              audit={true}
              action="Download Action Plan"
              details={{ data: data }}
              color="secondary"
              handleClick={handleDownloadPdf}
            >
              {!loading && (
                <ArrowDownTrayIcon
                  className="h-5 w-5 mr-2"
                  aria-hidden="true"
                />
              )}
              Download PDF
            </Button>
          </div>
        </div>

        <div className="mb-10 px-10 pt-10 space-y-6 lg:col-start-1 col-span-3 bg-white shadow ">
          <div className="px-4 py-5 sm:px-6 col-span-2">
            <h2
              id="applicant-information-title"
              className="text-2xl leading-6 font-medium text-slate-900"
            >
              Your Scores and Action Plan
            </h2>
            <p className="mt-5  text-slate-500">
              Now that you have taken your self-assessment, it's time to use the
              results to get to Expert. Below, you will find your scores and
              recommendations on focus areas. If you have need help at any point
              along the way, please reach out to us and let us help!
            </p>
          </div>

          <ContentSplit
            thisAssessment={thisAssessment}
            correct={correctTotal}
            incorrect={incorrectTotal}
            percentCorrect={percentCorrect}
          />
          {/* Description list*/}
          {risk != "Normal" && (
            <section aria-labelledby="applicant-information-title">
              <div className="">
                <div className="px-4 pt-5 pb-3 sm:px-6 col-span-2">
                  <h2
                    id="applicant-information-title"
                    className="text-2xl leading-6 font-medium text-slate-900"
                  >
                    Your Action Plan
                  </h2>
                  <p className="mt-5   text-slate-500">
                    {`Now that you know your score, it's time to find out what you
                    missed. Below you will see your ${
                      incorrectTotal ? incorrectTotal : ""
                    } incorrect answers and the
                    correct answers.`}
                  </p>
                </div>
                {/* <div className="px-4 py-5 sm:px-6 col-span-2"> */}
                {/* <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-slate-900"
                  >
                    Get Help with All Areas of Risk Right Now
                  </h2> */}
                {/* <p className="mt-1  text-sm text-primary-500">
                    Need help understanding? View our Cheatsheet
                  </p> */}
                {/* </div> */}
                <div className=" px-4 pb-10 sm:px-6 ">
                  <ul role="list" className="divide-y divide-gray-300 ">
                    {data.questions.map(
                      (question, index) =>
                        // question.isCorrect !== true && // "yes" &&
                        question.score !== 3 && (
                          // question.action_plan &&
                          // question.action_plan !== "" &&
                          <ActionPlanExplanation
                            key={index + 1}
                            index={index + 1}
                            type={data.assessment_type}
                            correct={correctTotal}
                            incorrect={incorrectTotal}
                            percentCorrect={percentCorrect}
                            action_plan={question.action_plan}
                            action_title={question.action_title}
                            action_icon={question.action_icon}
                            marketplace={question.marketplace}
                            question={question.question}
                            area={question.Area}
                            name={question.Name}
                            your_answer={question.your_answer}
                            best_answer={question.best_answer}
                            your_answer_index={
                              //get index of your_answer from answer options array
                              question.answer_options.indexOf(
                                question.your_answer
                              )
                            }
                          />
                        )
                    )}
                  </ul>
                  {/* </div> */}
                </div>
              </div>
            </section>
          )}
          {/* Comments*/}
        </div>
      </div>
    </div>

    //   {/* <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
    //   {data.map((item, index) => (
    //     <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
    //       <div key={index}>{item.Question}</div>
    //       <div>{""}</div>
    //     </div>
    //   ))}
    // </div> */}
    //   {/* Pagination */}
    // </div>
  );
}

export default ActionPlan;
