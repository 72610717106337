import React, { useEffect, useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../components/DropdownFilter";
import Datepicker from "../components/Datepicker";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard08 from "../partials/dashboard/DashboardCard08";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import RiskOverTimeCard from "../partials/dashboard/RiskOverTimeCard";
import AnalyticsCard05 from "../partials/dashboard/AnalyticsCard05";
import RiskAssessmentHistoryCard from "../partials/dashboard/RiskAssessmentHistoryCard";
import { _assessment } from "../modules/_assessment";
import { assessments } from "../modules/assessments";

import { ChartBarSquareIcon } from "@heroicons/react/20/solid";
import { utils } from "../modules/_utils";
import { _auth } from "../modules/_auth";
import { audit } from "../modules/_audit";
import { useStore } from "../store/usestore";
import Home from "./Home";
import Skeleton from "../components/Skeleton";
import { _dashboard } from "../modules/_dashboard";
import ModalBasic from "../components/ModalBasic";

import AssessmentSelect from "./AssessmentSelect";
import { ContactModal } from "../components/ContactModal";
import ContactConfirm from "../components/ContactConfirm";
import Steps from "../components/Steps";
import { _impediments } from "../modules/impediments";
import { _competencies } from "../modules/competencies";
import DashboardMaturityCard from "../partials/dashboard/DashboardMaturityCard";
import Button from "../components/Button";
import Card from "./component/Card";
import Stats from "../components/Stats";
import AnalyticsCard01 from "../partials/analytics/AnalyticsCard01";
import Leaderboard from "../partials/dashboard/Leaderboard";
import Banner from "../components/Banner";
import NotificationBanner from "../components/NotificationBanner";
import GettingStarted from "../components/GettingStarted";

//test change

function Dashboard() {
  const location = useLocation();
  const history = useHistory();
  const { userAssessmentHistory, isSuccess, getUserAssessmentHistory } =
    useStore();
  // const profile = history.location.state.profile;
  const [showHome, setShowHome] = useState(true);
  const { pathname } = location;

  const user = _auth.getUserProfile();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [rotCardData, setRotCardData] = useState([]);
  const [topAssessmentTakers, setTopAssessmentTakers] = useState([]);
  const [topAssessments, setTopAssessments] = useState([]);
  const [todaysHistory, setTodaysHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gettingAssessment, setGettingAssessment] = useState(true);
  const [auth, setAuth] = useState(false);
  const [avgRisk, setAvgRisk] = useState();
  const [riskCounts, setRiskCounts] = useState();
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [competencies, setCompetencies] = useState([]);
  const [impediments, setImpediments] = useState([]);
  const [score, setScore] = useState(0);
  const [effort, setEffort] = useState(0);
  const [maturityScore, setMaturityScore] = useState(0);
  const [maturityLevel, setMaturityLevel] = useState(0);
  const [topImpedimentsByScore, setTopImpedimentsByScore] = useState([]);
  const [topCompetenciesByScore, setTopCompetenciesByScore] = useState([]);
  const [topImpedimentsByEffort, setTopImpedimentsByEffort] = useState([]);
  const [topCompetenciesByEffort, setTopCompetenciesByEffort] = useState([]);
  const [breakEven, setBreakEven] = useState(0);
  const [burdenSummary, setBurdenSummary] = useState({});
  const [allCompetenciesByScore, setAllCompetenciesByScore] = useState([]);
  const [burndown, setBurndown] = useState([]);
  const [resolvedAndRemaining, setResolvedAndRemaining] = useState([]);
  const [lowBurdenCompetencies, setLowBurdenCompetencies] = useState([]);
  const [lowEffortCompetencies, setLowEffortCompetencies] = useState([]);
  const [lowBurdenImpediments, setLowBurdenImpediments] = useState([]);
  const [lowEffortImpediments, setLowEffortImpediments] = useState([]);
  const [sweetSpotCompetencies, setSweetSpotCompetencies] = useState([]);
  const [competencyLeaderBoard, setCompetencyLeaderBoard] = useState([]);
  const [tenantType, setTenantType] = useState("");
  const [showBanner, setShowBanner] = useState(
    localStorage.getItem("Show Dashboard Banner") !== "false" ? true : false
  );

  const playbooks = [
    { name: "Playbook 1", href: "#", status: "complete" },
    { name: "Playbook 2", href: "#", status: "complete" },
    { name: "Playbook 3", href: "#", status: "complete" },
    { name: "Playbook 4", href: "#", status: "complete" },
    { name: "Playbook 5", href: "#", status: "complete" },
    { name: "Playbook 6", href: "#", status: "current" },
    { name: "Playbook 7", href: "#", status: "upcoming" },
    { name: "Playbook 8", href: "#", status: "upcoming" },
    { name: "Playbook 9", href: "#", status: "upcoming" },
    { name: "Playbook 10", href: "#", status: "upcoming" },
  ];
  const data = {};

  useEffect(() => {
    setTenantType(user.tenantInfo.type);
  }, [user]);

  useEffect(() => {
    (async () => {
      let auth = await _auth.getAuth();
      if (auth) {
        <Redirect to="/" />;
        setAuth(true);
      } else {
        setAuth(false);
      }
    })();
    const user = _auth.getUserProfile();
    console.log("user", user);
    if (user) {
      setShowHome(user.showHome);
      console.log("showHome", showHome);
    }
    console.info("DASHBOARD USER -->", user);
  }, []);

  //get all dashboard data

  //get data for the dashboard
  const calcBurdenSummary = (data) => {
    let summary = {};
    let effortDays = data.effort;
    let effortHours = effortDays * 8;
    let effortWeeks = effortDays / 5;
    let effortHoursPerWeek = effortHours / effortWeeks;
    let remainingWeeks = 52 - effortWeeks;
    let score = data.score;

    // console.log("Effort Breakdown", {
    //   effortDays,
    //   effortHours,
    //   effortWeeks,
    //   effortHoursPerWeek,
    // });

    let scoreDaysPerYear = (score * 52) / 8;
    let scoreDaysPerWeek = score / 52;
    let scoreHoursPerDay = score / 5;
    let scoreHoursPerYear = score * 52;

    let personHoursPerWeek = 40;
    let personHoursPerYear = personHoursPerWeek * 52;

    // console.log("Score Breakdown", {
    //   score,
    //   scoreDaysPerYear,
    //   scoreDaysPerWeek,
    //   scoreHoursPerDay,
    //   scoreHoursPerYear,
    // });

    let effortSummary =
      data.Name +
      " The yearly current cost is " +
      scoreHoursPerYear +
      " wasted hours per year, which is " +
      //total wasted days per year
      scoreDaysPerYear +
      " days per year." +
      " If you work 40 hours a week for " +
      effortWeeks +
      " weeks, you will free up " +
      score +
      " hours per week of burden, which is " +
      scoreHoursPerYear +
      " hours per year. That equates to " +
      scoreDaysPerWeek +
      " days per week.";

    console.log("Effort Summary: ", effortSummary);

    let scoreSummary =
      data.name +
      ": Score Breakdown Summary:  " +
      "After clearing the burden, " + // the first X weeks of "no burden" will recoup your effort.  After that, you will have X weeks remaining in the year
      "you will have " +
      52 -
      effortWeeks +
      " weeks remaining in year that started when you begain eliminating the burden. " +
      "The remaining weeks will not have the burden, saving you " +
      score +
      " hours per week for the remaining " +
      remainingWeeks +
      " weeks. That equates to " +
      remainingWeeks / 5 / 8 +
      " days per week.";
    console.log("Burden Summary: ", scoreSummary);

    summary.Name = data.Name;
    summary.Effort = data.effort;
    summary.Score = data.score;

    let hoursWastedDuringElimination = score * effortWeeks;
    let hoursRequiredToBreakEven =
      effortHoursPerWeek * effortWeeks + hoursWastedDuringElimination;

    let breakEvenPointInWeeks = hoursRequiredToBreakEven / 8 / 5;

    let burdenSummaryObject = {
      "Hours per week wasted": score,
      "Effort in days": effortDays,
      // "Hours per week to eliminate waste": effortHoursPerWeek,
      "Weeks of effort to eliminate waste": effortWeeks,
      "Total hours spent to eliminate waste": effortHours,
      "Total wasted hours during elimination": hoursWastedDuringElimination,
      "Hours required to break even": hoursRequiredToBreakEven,
      "Break even point in weeks": breakEvenPointInWeeks,
      "First year savings (days)":
        //savings is score * remaining weeks after break even
        (score * (remainingWeeks - breakEvenPointInWeeks)) / 8,
      "Future year savings (days)": (score * 52) / 8,
    };

    // let burdenSummaryObject = {
    //   "Hours per week wasted": score,
    //   "Hours per week to eliminate waste": effortHoursPerWeek,
    //   "Number of weeks to eliminate waste": effortWeeks,
    //   "Total wasted hours during elimination": score * effortWeeks,
    //   "Total hours spent to eliminate waste": effortHours,
    //   "Break even point": (score * effortWeeks) / score,
    //   "First year savings":
    //     //savings is score * remaining weeks after break even
    //     (score * (remainingWeeks - 1.6)) / 8,
    // };
    const str =
      "{\n" +
      Object.getOwnPropertyNames(burdenSummaryObject)
        .map((key) => `  ${key}: ${burdenSummaryObject[key]}`)
        .join("\n") +
      "\n}";
    // console.log(str);
    console.log("Burden Summary Object", str);

    summary.calculations = burdenSummaryObject;

    // summary.EffortBreakdown = {
    //   effort: effortDays,
    //   effortDaysPerYear: effortDays,
    //   remainingWeeks: 52 - effortWeeks,
    // };
    // summary.ScoreBreakdown = {
    //   score: score,
    //   yearlyHourWaste: scoreHoursPerYear,
    //   yearlyDayWaste: scoreDaysPerYear,
    //   remainingWeeksSavings: remainingWeeks / 5 / 8,
    // };

    console.log("Summary", summary);

    return summary;
  };

  useEffect(() => {
    (async () => {
      console.log("USEFFECT GET IMPEDIMENTS", user.tenantInfo);
      let impedimentList =
        user.tenantInfo.type == "admin"
          ? await _impediments.getImpediments(true)
          : await _impediments.getImpediments();
      if (impedimentList) {
        console.log("ViewDetails impList", impedimentList);
        setImpediments(impedimentList);
        // sum of impediments.score
        let score = impedimentList.reduce((a, b) => a + b.score, 0);
        setScore(score);
        let effort = impedimentList.reduce((a, b) => a + b.effort, 0);
        setEffort(effort);
        // setScore(impedimentList.reduce((a, b) => a + b.score, 0));
        // setEffort(impedimentList.reduce((a, b) => a + b.effort, 0));

        let effortDays = effort;
        let effortHours = effortDays * 8;
        let effortWeeks = effortDays / 5;
        let effortHoursPerWeek = effortHours / effortWeeks;
        let remainingWeeks = 52 - effortWeeks;

        let burdenSummary = {
          "Hours per week wasted": score,
          "Effort in Days": effortDays,
          "Hours per week to eliminate waste": effortHoursPerWeek,
          "Number of weeks to eliminate waste (weeks of effort)": effortWeeks,
          "Total wasted hours during elimination": score * effortWeeks,
          "Total hours spent to eliminate waste": effortHours,
          "Break even point": (score * effortWeeks) / score,
          "First year savings":
            //savings is score * remaining weeks after break even
            (score * (remainingWeeks - 1.6)) / 8,
        };
        setBurdenSummary(burdenSummary);

        console.log("Effort Breakdown", {
          effortDays,
          effortHours,
          effortWeeks,
          effortHoursPerWeek,
        });

        let scoreDaysPerYear = (score * 52) / 8;
        let scoreDaysPerWeek = score / 52;
        let scoreHoursPerDay = score / 5;
        let scoreHoursPerYear = score * 52;

        let personHoursPerWeek = 40;
        let personHoursPerYear = personHoursPerWeek * 52;

        console.log("Score Breakdown", {
          score,
          scoreDaysPerYear,
          scoreDaysPerWeek,
          scoreHoursPerDay,
          scoreHoursPerYear,
        });
        // console.log(
        //   "Effort Breakdown Summary:  ",
        //   "If you work 40 hours a week for " +
        //     effortWeeks +
        //     " weeks, you will free up " +
        //     score +
        //     " hours per week of burden, which is " +
        //     scoreHoursPerYear +
        //     " hours per year. That equates to " +
        //     scoreDaysPerWeek +
        //     " days per week. The yearly current cost is " +
        //     scoreHoursPerYear +
        //     " wasted hours per year, which is " +
        //     //total wasted days per year
        //     scoreDaysPerYear,
        //   " days per year."
        // );

        // console.log(
        //   "Score Breakdown Summary:  ",
        //   "After clearing the burden, you will have",
        //   52 -
        //     effortWeeks +
        //     " weeks remaining in year that started when you begain eliminating the burden.",
        //   "The remaining weeks will not have the burden, saving you " +
        //     score +
        //     " hours per week for the remaining " +
        //     remainingWeeks +
        //     " weeks. That equates to " +
        //     remainingWeeks / 5 / 8 +
        //     "days per week."
        // );

        // setInitialValues(impedimentList);
        // setList(impedimentList);
        setLoading(false);

        // get top 5 impediments by score
        let topImpediments = impedimentList
          .filter((imp) => imp.score && imp.score > 0)
          .sort((a, b) => b.score - a.score)
          .slice(0, 5);

        console.log("topImpediments", topImpediments);
        //turn into 2d array for chart
        let topImpedimentsByScore = [];
        topImpediments.forEach((imp) => {
          topImpedimentsByScore.push([imp.Description, imp.score]);
        });
        console.log("topImpedimentsByScore", topImpedimentsByScore);

        setTopImpedimentsByScore(topImpedimentsByScore);
        // get top 5 impediments by effort
        let topEffort = impedimentList
          .filter((imp) => imp.effort && imp.effort > 0)
          .sort((a, b) => b.effort - a.effort)
          .slice(0, 5);
        console.log("topEffort", topEffort);
        //turn into 2d array for chart
        let topImpedimentsByEffort = [];
        topEffort.forEach((imp) => {
          topImpedimentsByEffort.push([imp.Description, imp.effort]);
        });
        console.log("topImpedimentsByEffort", topImpedimentsByEffort);
        setTopImpedimentsByEffort(topImpedimentsByEffort);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      console.info("useEffect tenantInfo.type", user.tenantInfo);
      if (user.tenantInfo.type == "admin") {
        console.info("Get Competencies Global", user.tenantInfo);
        let globalCompetencies =
          await _competencies.getGlobalCompetenciesFromSurvey();
        if (globalCompetencies) {
          console.log("CompList Global", globalCompetencies);
          setMaturityScore(globalCompetencies.global[0].globalAverage);
          setMaturityLevel(globalCompetencies.global[0].globalAverageLevel);
          setCompetencyLeaderBoard(globalCompetencies.teams);
        }
      } else {
        console.info("Get Competencies Team", user.tenantInfo);
        let teamCompList = await _competencies.getTeamCompetenciesFromSurvey();
        console.log("teamCompList", teamCompList);
        if (teamCompList && teamCompList.data) {
          teamCompList = teamCompList.data;
          console.log("CompList Team", teamCompList);
          let sum = 0;
          let sum1 = 0;
          // teamCompList.forEach((comp) => {
          //   //find idex of best_answer in answer_options array
          //   let index = comp.answer_options.findIndex(
          //     (option) =>
          //       option === comp.your_answer &&
          //       (comp.isCorrect === true || comp.isCorrect === false)
          //   );
          //   // comp.score = index;
          //   if (index === -1) {
          //     return;
          //   }
          //   sum += index + 1;
          // });

          //set array of name/comp score
          // get index of each answer option that matches your_answer and add it to resarray.competency_score
          teamCompList.forEach((element) => {
            let index = element.answer_options.findIndex(
              (x) => x === element.your_answer
            );
            element.competency_score = index + 1;
            sum = sum + index + 1;
          });
          console.log("teamCompList", teamCompList);
          let compArry = get2DArray(
            teamCompList,
            "Name",
            "competency_score",
            "Area"
          );
          setAllCompetenciesByScore(compArry);
          console.log("teamCompList array", compArry);

          //get average
          //get sum of all competencies that have a your_answer
          // divide sum by number of competencies that have a your_answer

          let count = 0;
          teamCompList.forEach((comp) => {
            if (comp.isCorrect === true || comp.isCorrect === false) {
              count++;
            }
          });
          console.log("sum count", count);
          console.log("sum", sum);
          console.log("sum1", sum1);
          console.log("sum/count", sum / count);
          console.log("sum1/count", sum1 / count);
          sum = sum / count;

          // sum = sum / teamCompList.length;
          setMaturityScore(sum);
          setMaturityLevel(getMaturityLevel(sum));
          setCompetencies(teamCompList);

          //get top 5 competencies by impediment score
          let topCompetencies = teamCompList.filter(
            (comp) => comp.score && comp.score > 0
          );

          console.log("topCompetencies", topCompetencies);
          //turn into 2d array for chart
          let topCompetenciesByScore = [];
          topCompetencies.sort((a, b) => b.score - a.score).slice(0, 5);
          topCompetencies.forEach((comp) => {
            if (comp.score > 0) {
              topCompetenciesByScore.push([comp.Name, comp.score]);
            }
          });

          console.log("topCompetenciesByScore", topCompetenciesByScore);
          setTopCompetenciesByScore(topCompetenciesByScore);

          let lowBurdenCompetencies = [];
          topCompetencies.sort((a, b) => a.score - b.score).slice(0, 5);
          topCompetencies.forEach((comp) => {
            if (comp.score > 0) {
              lowBurdenCompetencies.push([comp.Name, comp.score, comp.effort]);
            }
          });

          console.log("lowBurdenCompetencies", lowBurdenCompetencies);
          setLowBurdenCompetencies(lowBurdenCompetencies);
          //get top 5 competencies by effort

          let filteredCompetencies = teamCompList.filter(
            (comp) => comp.effort && comp.effort > 0
          );

          console.log(
            "filteredCompetencies",
            filteredCompetencies.sort((a, b) => b.effort - a.effort).slice(0, 5)
          );

          let topCompetenciesByEffort = [];
          filteredCompetencies.forEach((comp) => {
            topCompetenciesByEffort.push([comp.Name, comp.effort]);
          });

          setTopCompetenciesByEffort(topCompetenciesByEffort);
          filteredCompetencies.sort((a, b) => a.effort - b.effort).slice(0, 5);
          let lowEffortCompetencies = [];
          filteredCompetencies.forEach((comp) => {
            lowEffortCompetencies.push([comp.Name, comp.effort]);
          });
          setLowEffortCompetencies(lowEffortCompetencies);

          console.log("lowEffortCompetencies", lowEffortCompetencies);

          let teamCompListByEffortAsc = teamCompList
            .filter((comp) => comp.effort && comp.effort > 0)
            .sort((a, b) => a.effort - b.effort);
          let teamCompListByEffortDesc = teamCompList
            .filter((comp) => comp.effort && comp.effort > 0)
            .sort((a, b) => b.effort - a.effort);
          let teamCompListByBurdenAsc = teamCompList
            .filter((comp) => comp.score && comp.score > 0)
            .sort((a, b) => a.score - b.score);
          let teamCompListByBurdenDesc = teamCompList
            .filter((comp) => comp.score && comp.score > 0)
            .sort((a, b) => b.score - a.score);

          teamCompListByBurdenDesc.forEach((comp, index) => {
            let name = comp.Name;
            let burden = index;
            let effort;

            let index2 = teamCompListByEffortDesc.findIndex(
              (x) => x.Name === name
            );

            effort = index2;

            console.log("effort burden index", name, burden, effort);
            calcBurdenSummary(comp);
          });

          let sweetSpotCompetencies = [];
          teamCompList.forEach((comp) => {
            if (comp.score > 0 || comp.effort > 0) {
              let obj = calcBurdenSummary(comp);
              sweetSpotCompetencies.push(obj);
            }
            // let name = comp.Name;
            // let area = comp.Area;
            // let burden = comp.score;
            // let effort = comp.effort;

            // if (burden > 0 && effort > 0) {
            //   burden = burden / 5;
            //   effort = effort / 8;

            //   let score = burden / effort;
            //   console.log("effort burden ---> ", {
            //     name: name,
            //     burden: burden,
            //     effort: effort,
            //     score: score,
            //   });

            //   if (effort < burden) {
            //     console.log("effort burden", name, area, burden, effort);
            //   }
            // }
          });

          //check if cmp is in topCompetenciesByScore
          // let index = topCompetenciesByScore.findIndex((x) => x[0] === comp[0]);
          // if (index > -1) {
          //   sweetSpotCompetencies.push(comp);
          // }
          //sort sweetSpotCompetencies by First year savings (days)
          sweetSpotCompetencies
            .sort(
              (a, b) =>
                b["calculations"]["First year savings (days)"] -
                a["calculations"]["First year savings (days)"]
            )
            .slice(0, 5);
          console.log("sweetSpotCompetencies", sweetSpotCompetencies);
          setSweetSpotCompetencies(sweetSpotCompetencies);

          // let topEffort = teamCompList
          //   .filter((comp) => comp.effort && comp.effort > 0)
          //   .sort((a, b) => b.effort - a.effort)
          //   .slice(0, 5);
          // console.log("topEffort", topEffort);
          // //turn into 2d array for chart
          // let topCompetenciesByEffort = [];
          // topEffort.forEach((comp) => {
          //   topCompetenciesByEffort.push([comp.Name, comp.effort]);
          // });
          // setTopCompetenciesByEffort(topCompetenciesByEffort);
        } else {
          console.log("no team comp list");
          let compList = await _competencies.getCompetencies();
          if (compList) {
            console.log("Complist Global", compList);
            setCompetencies(compList);
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let resolvedAndRemaining =
        user.tenantInfo.type == "admin"
          ? await _impediments.getResolvedAndRemainingBurden(true)
          : await _impediments.getResolvedAndRemainingBurden();
      // await _impediments.getResolvedAndRemainingBurden();
      if (resolvedAndRemaining) {
        console.log("resvolvedAndRemaining", resolvedAndRemaining);
        setResolvedAndRemaining(resolvedAndRemaining);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let burndown =
        user.tenantInfo.type == "admin"
          ? await _dashboard.getBurndownData(true)
          : await _dashboard.getBurndownData();
      if (burndown) {
        console.log("burndown", burndown);
        setBurndown(burndown);
      }
    })();
  }, []);

  const calcScoreByArea = (area) => {
    let sum = 0;
    let count = 0;
    competencies.forEach((comp) => {
      if (comp.Area === area) {
        if (comp.isCorrect === true || comp.isCorrect === false) {
          sum += comp.competency_score;
          count++;
        }
      }
    });
    //return sum and count
    return [sum, count];
  };

  const get2DArray = (list, d1, d2, d3) => {
    let array = [];
    list.forEach((item) => {
      array.push([item[d1], item[d2], item[d3]]);
    });
    return array;
  };

  const getMaturityLevel = (score) => {
    if (score <= 2) {
      return "Beginner";
    } else if (score <= 3) {
      return "Intermediate";
    } else if (score <= 4) {
      return "Advanced";
    } else if (score <= 5) {
      return "Expert";
    }
    return "No level";
  };

  // useEffect(() => {
  //   (async () => {
  //     let response = await _assessment.getAssessmentHistory();
  //     setAssessmentHistory(response);
  //     setGettingAssessment(false);
  //     setRotCardData(_dashboard.convertDates(response));

  //     setTopAssessmentTakers(assessments.getTotals(response, "taken_by", true));
  //     setTopAssessments(
  //       assessments.getTotals(response, "assessment_type", true)
  //     );
  //     setTodaysHistory(_dashboard.todaysHistory(response));
  //     setRiskCounts(_dashboard.getCountOfEach(response));

  //     setAvgRisk(_dashboard.calculateAverageRisk(response));
  //     setLoading(false);
  //   })();
  // }, []);

  const handleOpenAssessmentModal = (e) => {
    console.log("onclick handleOpenAssessmentModal", e);
    e.preventDefault();

    setAssessmentModalOpen(!assessmentModalOpen);
  };

  const handleOpenAssessmentModalFromHome = (e) => {
    console.log("onclick handleOpenAssessmentModalFromHome", e);
    e.preventDefault();

    setAssessmentModalOpen(true);
  };
  const handleOpenContactModal = (e) => {
    console.log("onclick handleOpenContactModal", e);
    // e.preventDefault();

    setShowContactModal(e);
  };

  const todaysHistoryCount = todaysHistory.length;
  const unfinishedToday = _dashboard.getUnfinishedToday(todaysHistory);
  const unFinishedAssessments =
    _dashboard.getAllUnfinishedAssessments(assessmentHistory);
  //finished getting all dashboard data

  // const charts = [
  //   {
  //     title: "Assessments",
  //     icon: <ChartBarSquareIcon />,
  //     component: (
  //       <DashboardCard01
  //         loading={loading}
  //         data={loading == true ? "loading" : assessmentHistory.length}
  //         title="Assessments taken"
  //         colSpan={3}
  //         rowSpan={1}
  //         unFinishedAssessments={
  //           unFinishedAssessments.length !== 0
  //             ? unFinishedAssessments.length
  //             : null
  //         }
  //       />
  //     ),
  //   },
  //   {
  //     title: "Today's Assessments",
  //     icon: <ChartBarSquareIcon />,
  //     component: (
  //       <DashboardCard01
  //         loading={loading}
  //         data={loading == true ? "loading" : todaysHistory.length}
  //         title={"Taken today"}
  //         colSpan={3}
  //         rowSpan={1}
  //         unFinishedAssessments={
  //           unfinishedToday.length != 0 ? unfinishedToday.length : null
  //         }
  //       />
  //     ),
  //   },
  //   {
  //     title: "Average Risk Score",
  //     icon: <ChartBarSquareIcon />,
  //     component: (
  //       <DashboardCard01
  //         loading={loading}
  //         data={loading == true ? "loading" : avgRisk}
  //         title="Average Risk Score"
  //         colSpan={3}
  //         rowSpan={1}
  //         scores={riskCounts}
  //       />
  //     ),
  //   },
  //   {
  //     title: "Top Assessment",
  //     icon: <ChartBarSquareIcon />,
  //     component: (
  //       <DashboardCard01
  //         loading={loading}
  //         data={
  //           loading == true
  //             ? "loading"
  //             : topAssessments.length > 0
  //             ? topAssessments.slice(0, 1)[0][0].toUpperCase()
  //             : []
  //         }
  //         title="Top Assessment"
  //         colSpan={3}
  //         rowSpan={1}
  //         total={topAssessments.length > 0 ? topAssessments[0][1] : "loading"}
  //       />
  //     ),
  //   },
  // ];

  const competencyCharts = [
    {
      title: "Impediments",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={loading}
          data={loading == true ? "loading" : impediments.length}
          title={"Impediments"}
          colSpan={3}
          rowSpan={1}
          unFinishedAssessments={
            unFinishedAssessments.length !== 0
              ? unFinishedAssessments.length
              : null
          }
        />
      ),
    },

    {
      title: "Burden",
      icon: <ChartBarSquareIcon />,
      component: (
        //total score of all unresolved impediments for the team
        <DashboardCard01
          loading={loading}
          data={loading == true ? "loading" : score}
          title="Burden"
          subTitle="hours per week"
          colSpan={3}
          rowSpan={1}
          effort={effort}
          breakEven={breakEven}
          burdenSummary={burdenSummary}
        />
      ),
    },
    {
      title: "Maturity Score",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={loading}
          data={
            loading == true
              ? "loading"
              : maturityScore && maturityScore.toFixed(2)
          }
          title={"Comptency Score"}
          colSpan={3}
          rowSpan={1}
          unFinishedAssessments={
            null
            // unfinishedToday.length != 0 ? unfinishedToday.length : null
          }
        />
      ),
    },
    {
      title: "Maturity Level",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardMaturityCard
          loading={loading}
          data={loading == true ? "" : maturityLevel}
          title="Average Competency Level"
          colSpan={3}
          rowSpan={1}
          total={topAssessments.length > 0 ? topAssessments[0][1] : "loading"}
        />
      ),
    },
  ];

  const handleHideBanner = () => {
    setShowBanner(false);
    let newVal = localStorage.setItem("View all impediments") == "false";
  };

  return (
    <div>
      {user.tenantInfo.type == "admin123" && showBanner == true && (
        <NotificationBanner
          // className={"sticky top-64px"}
          setOpen={handleHideBanner}
          showClose={true}
          text={
            "You are currently logged in as an admin. To view a team's dashboard, you can switch teams on the nav bar."
          }
        />
      )}
      {(showHome == true || showHome == undefined) &&
      !pathname.includes("/dashboard") ? (
        // <div className="flex flex-col items-center justify-center m-auto">
        <Home
          handleOpenAssessmentModal={handleOpenAssessmentModalFromHome}
          handleOpenContactModal={handleOpenContactModal}
        />
      ) : (
        // </div>
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <div className="sm:flex sm:justify-between sm:items-center mb-8">
            {/* Welcome banner */}
            <div className="mb-0 sm:mb-0 inline-flex ">
              <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
                {tenantType == "admin" && "Company"} Dashboard
              </h1>
            </div>
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              {/* Filter button */}
              {/* <FilterButton align="right" /> */}
              {/* Datepicker built with flatpickr */}
              {/* <Datepicker align="right" /> */}

              {/* <Link to="../assessmentselect"> */}
              {tenantType !== "admin" && (
                <Button
                  color="secondary"
                  handleClick={handleOpenAssessmentModal}
                  shadow
                >
                  <svg
                    className="w-4 h-4 fill-current opacity-50 shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Take Assessment</span>
                </Button>
              )}
              {/* </Link> */}
              {/* Add view button */}

              {/* <button className="btn bg-primary-500 hover:bg-primary-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add view</span>
          </button> */}
            </div>
          </div>

          {/* <Steps className="w-full" steps={playbooks} /> */}
          {/* <WelcomeBanner userName={"John"} /> */}
          {/* Dashboard actions */}
          <div className="sm:flex sm:justify-between sm:items-center mb-8">
            {/* Left: Avatars */}
            {/* The below is only needed to keep the assmt button on right when Avatars are not present */}
            <div className="flex flex-wrap justify-center sm:justify-start mb-8 sm:mb-0 -space-x-3 -ml-px">
              {" "}
            </div>
            {/* {/* <DashboardAvatars />{" "}  */}
            {/* // Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              {/* Filter button */}
              {/* <FilterButton align="right" /> */}
              {/* Datepicker built with flatpickr */}
              {/* <Datepicker align="right" /> */}

              {/*Old Assessment Button */}
              {/* Add view button */}

              {/* <button className="btn bg-primary-500 hover:bg-primary-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add view</span>
          </button> */}
            </div>
          </div>
          {/* Cards */}

          {/* <Stats /> */}

          <div className="grid grid-cols-12 gap-6">
            {competencyCharts.map((chart, index) => {
              {
                return chart.component;
              }
            })}
            {/* Line chart (Acme Plus) */}
            {/* <DashboardCard01 /> */}
            {/* Line chart (Acme Advanced) */}
            {/* <DashboardCard02 /> */}
            {/* Line chart (Acme Professional) */}
            {/* <DashboardCard03 /> */}
            {/* Doughnut chart (Top Countries) */}
            {/* Table (Top Channels) */}
            {/* <RiskAssessmentHistoryCard /> */}
            {/* Line chart (Sales Over Time) */}
            {/* Stacked bar chart (Sales VS Refunds) */}
            {/* Card (Recent Activity) */}
            {tenantType !== "admin" && (
              <div className="col-span-12 bg-white rounded-sm shadow-lg ">
                <Stats
                  data={competencies}
                  loading={loading}
                  title="Score by DevOps Model Area"
                  showModelCoverage={true}
                />
              </div>
            )}
            <AnalyticsCard01
              // Burndown Chart
              loading={loading}
              data={burndown && burndown.length > 0 ? burndown : []}
              // burndown.length > 0 ? burndown.sort(([a], [b]) => a - b) : []

              title={"Burden Over Time"}
              sorted={true}
              colSpan={8}
              rowSpan={2}
              labels={burndown.labels}
              values={burndown.values}
              score={score}
              effort={effort}
              breakEven={breakEven}
              burdenSummary={burdenSummary}
              resolved={resolvedAndRemaining.resolvedBurden}
              remaining={resolvedAndRemaining.remainingBurden}
              impediments={impediments.length}
            />
            {/* <AnalyticsCard05
              title={"Top ROI Competencies"}
              loading={loading}
              data={
                sweetSpotCompetencies.length > 0
                  ? sweetSpotCompetencies
                  : "loading"
              }
              colSpan={4}
              rowSpan={1}
              uppercase={false}
            /> */}
            {user.tenantInfo.type == "admin" ? (
              <Leaderboard
                title="Leaderboard"
                colSpan={4}
                rowSpan={3}
                bordered={false}
                tabs={{}}
                type="competencies"
                data={competencyLeaderBoard}
              />
            ) : (
              <DashboardCard10
                loading={loading}
                title={"Greatest Impact"}
                data={
                  sweetSpotCompetencies.length > 0 ? sweetSpotCompetencies : []
                }
                colSpan={4}
                rowSpan={3}
              />
            )}
            {user.tenantInfo.type == "admin" ? (
              ""
            ) : (
              <>
                <AnalyticsCard05
                  title={"High burden competencies"}
                  loading={loading}
                  data={
                    topCompetenciesByScore.length > 0
                      ? topCompetenciesByScore
                      : "loading"
                  }
                  colSpan={4}
                  rowSpan={2}
                  uppercase={false}
                />
                <AnalyticsCard05
                  title={"High effort competencies"}
                  loading={loading}
                  data={
                    topCompetenciesByEffort.length > 0
                      ? topCompetenciesByEffort
                      : "loading"
                  }
                  //how to show this with large description
                  colSpan={4}
                  rowSpan={2}
                  uppercase={false}
                />
                <AnalyticsCard05
                  title={"High burden impediments"}
                  loading={loading}
                  data={
                    topImpedimentsByScore.length > 0
                      ? topImpedimentsByScore
                      : "loading"
                  }
                  colSpan={4}
                  rowSpan={2}
                  uppercase={false}
                />
                <AnalyticsCard05
                  title={"High effort impediments"}
                  loading={loading}
                  data={
                    topImpedimentsByEffort.length > 0
                      ? topImpedimentsByEffort
                      : "loading"
                  }
                  //how to show this with large description
                  colSpan={4}
                  rowSpan={2}
                  uppercase={false}
                />
                <DashboardCard06
                  loading={loading}
                  data={competencies.length > 0 ? competencies : []}
                  title={"Impediments by Competency"}
                  sorted={true}
                  colSpan={4}
                  rowSpan={2}
                  grouping="Name"
                />
                <DashboardCard06
                  loading={loading}
                  data={competencies.length > 0 ? competencies : []}
                  title={"Competencies by impediment count"}
                  sorted={true}
                  colSpan={4}
                  rowSpan={2}
                  grouping="Area"
                />
                <DashboardCard04
                  data={allCompetenciesByScore}
                  title={"Competency Scores"}
                  span={12}
                  aspect={true}
                />
              </>
            )}
          </div>
        </div>
      )}
      <ModalBasic
        id="assessment-modal"
        modalOpen={assessmentModalOpen}
        setModalOpen={setAssessmentModalOpen}
        title="Take Assessment"
        showClose={true}
        showFooter
        closeButtonText="Cancel"
      >
        <AssessmentSelect />
        {/* closeModal={setAssessmentModalOpen(false)} */}
      </ModalBasic>

      {/* <ContactModal
        id="contact-modal"
        showModal={showContactModal}
        setShowContactModal={() => setShowContactModal(false)}
      /> */}
      <ModalBasic
        id="support_modal"
        modalOpen={showContactModal}
        setModalOpen={() => handleOpenContactModal(false)}
        title={"Contact JDO"}
        showClose={false}
      >
        <ContactConfirm
          setModalOpen={() => handleOpenContactModal(false)}
          company={"JDO"}
          email={"jdoteam@relativity.com"}
          vendor_email={"vendor_email"}
          type="support"
        />
      </ModalBasic>
    </div>
  );
}

export default Dashboard;
