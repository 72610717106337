import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Logo from "../images/logo.png";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import Button from "../components/Button";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { Audit } from "../components/AuditFeature";
import { usePersistStore } from "../store/usestore";
import { _stripe } from "../modules/_stripe";
import Skeleton from "../components/Skeleton";

function SelectTenant() {
  const mvp = false;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [passwordMatched, isPasswordMatched] = useState(true);
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(true);
  const [companyTenants, setCompanyTenants] = useState([]);
  const [tenants1, setTenants] = useState([]);
  //const {tenants, tenantId} = usePersistStore()

  const multiFactorAuth = async (event) => {
    setLoader(true);
    event.preventDefault();
    // console.info(event);
    let userProfile = history.location.state.profile;
    // let path = "/home";
    // console.info("PROFILE BEFORE MFA", userProfile);
    let profile = await _auth.MFA(otp, history);
    // console.info("PROFILE AFTER MFA", profile);

    //FROM ORAN:  I copied this from the login page
    //but removed it because I didn't want to cause
    //any errors.

    if (profile != false) {
      // let userProfile = _auth.getUserProfile(u);
      // let role = _auth.getUserRole(u);
      // console.info("DND", userProfile.dnd);
      // console.info("ONBOARDING", userProfile.onBoardCompleted);
      if (userProfile.dnd || userProfile.onBoardCompleted) {
        // path = "/home";
        return history.push({
          state: { profile: userProfile },
          pathname: "/home",
        });
      } else {
        setLoader(false);
        return history.push({
          state: { profile: userProfile },
          pathname: "/onboarding",
        });
      }
    } else {
      setLoader(false);
      setIsAuthenticated(false);
    }

    // if (profile === false) {
    //   setLoader(false);
    //   setIsAuthenticated(false);
    // } else {
    //   setLoader(false);
    //   history.push("/home");
    // }
  };
  const { setTenantsId } = usePersistStore();

  const profile = history.location.state.profile;

  useEffect(() => {
    (async () => {
      setLoader(true);
      let path = "all";
      let data = {
        companyId: profile.companyId,
      };
      if (profile.role == "Administrator") {
        let tenants = await _auth.getTentants(path, data);
        console.info("GET TENANTS", tenants);
        if (tenants) {
          tenants.sort((a, b) => (a.tenantTitle > b.tenantTitle ? 1 : -1));
          setCompanyTenants(tenants);
          let showTenants =
            profile.role == "Administrator" ? tenants : profile.tenants;
          setTenants(showTenants);
          setLoader(false);
        } else {
          let showTenants = profile.tenants;
          setTenants(showTenants);
          setLoader(false);
        }
      } else {
        let showTenants = profile.tenants;
        setTenants(showTenants);
        setLoader(false);
      }
    })();
  }, []);

  console.log("Profile Get Tenants", profile.tenants);

  const tenants =
    tenants1.length > 0 ? tenants1 : history.location.state.profile.tenants;

  const allowedRoles = [
    "Admin",
    "Manager",
    "Administrator",
    "SuperAdmin",
    "Super Admin",
  ];

  const goToTenant = async (tenant) => {
    setLoader(true);
    console.info("goToTenant", tenant);
    console.info("gotoTenant profile", tenant);
    let id = tenant.tenantId;
    profile.tenantId = tenant.tenantId; //add selected tenant id to profile
    profile.tenantName = tenant.tenantTitle; //add selected tenant id to profile

    //roles
    profile.tenantRole = profile.tenants[0].role;
    profile.role =
      profile.companyRole &&
      (profile.companyRole == "Administrator" ||
        profile.companyRole == "Observer")
        ? profile.companyRole
        : profile.tenants[0].role;
    //end roles

    let isRenamed = tenant.isRenamed;
    let tmpArr = [];

    if (profile != false) {
      _auth.setUserProfile(profile);
      let userProfile = profile;

      // await _stripe.getStripeData(); //get stripe data now that you have the selected tenantId

      if (
        isRenamed ||
        userProfile.role == "Collaborator" ||
        userProfile.role == "Observer"
        // userProfile.dnd ||
        // userProfile.onBoardCompleted
      ) {
        setLoader(false);
        return history.push({
          state: { profile: userProfile, showHome: userProfile.showHome },
          pathname: "/home",
        });
      } else {
        setLoader(false);
        return history.push({
          state: { profile: userProfile },
          pathname: "/onboarding",
        });
      }
    } else {
      setLoader(false);
      setIsAuthenticated(false);
    }
  };

  return (
    <main className="bg-gray-100">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            {/* <div className="max-w-sm mx-auto px-4">
              <img className="max-w-18 mx-auto px-4 py-8" src={Logo} />
            </div> */}
            <div className="mx-auto max-w-lg p-10 bg-white rounded shadow-lg overflow-auto max-w-1/2  max-h-full scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
              <div>
                <div className="text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-slate-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  <h2 className="mt-2 text-lg font-medium text-slate-900">
                    Select an account
                  </h2>
                  <p className="mt-3 text-sm text-slate-500 mb-10 text-left">
                    Looks like you have multiple teams. That's cool. Just select
                    the one you want to collaborate on and we'll get you on your
                    way.
                  </p>
                </div>
                <div className="p-6 max-h-[calc(100vh_-_30rem)]  overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
                  <div className="text-sm">
                    {/* Options */}
                    <ul className="space-y-2 mb-4 w-full">
                      {tenants.map((a) => {
                        let id = a.tenantId ? a.tenantId._id : a._id;
                        let tenantTitle = a.tenantId
                          ? a.tenantId.tenantTitle
                          : a.tenantTitle;
                        let role = a.role ? a.role : "Administrator";
                        let status = a.status ? a.status : "Active";
                        let company = profile.company;
                        let isRenamed = a.tenantId
                          ? a.tenantId.isRenamed
                          : a.isRenamed;
                        const tenant = {
                          tenantId: id,
                          tenantTitle: tenantTitle,
                          role: role,
                          status: status,
                          company: company,
                          isRenamed: isRenamed,
                        };

                        return (
                          a.status != "Disabled" &&
                          a.status !== "Deleted" && (
                            <li key={id}>
                              <button
                                // color="white"
                                disabled={a.status === "Invited"}
                                onClick={() => goToTenant(tenant, id)}
                                className={`w-full h-full text-left py-3 px-4 rounded bg-white 
                                border border-gray-300
                                shadow-sm duration-150 ease-in-out
                               
                                focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500
                                ${
                                  a.status === "Invited"
                                    ? "opacity-80 cursor-not-allowed hover-shadow-none hover-border-none"
                                    : " hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-orange-500 hover:border-gray-300 hover:border-secondary-500"
                                }
                                `}
                              >
                                <div className="flex items-center">
                                  {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                                  <div className="grow">
                                    <div className="flex flex-wrap items-center justify-between mb-0.5">
                                      <span className="flex font-semibold text-slate-800">
                                        {tenantTitle
                                          ? tenantTitle
                                          : profile.company
                                          ? profile.company
                                          : profile.email}
                                        {status != "Active" && (
                                          <span className="normal-case text-xs italic text-accent-800 align-top ml-2">
                                            {a.status == "Invited"
                                              ? "    Accept your invitation to continue"
                                              : null}
                                          </span>
                                        )}
                                      </span>
                                      <span>
                                        <span className="font-medium text-accent-600">
                                          {role}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="text-xs">
                                      {profile.company}
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </li>
                          )
                        );
                      })}
                      {/* Create a new tenant */}
                      {/* We need to check if this user owns a tenant of their own or if they are a guest only */}
                      {/* If they are only a guest, we need to give them the ability to create an account of their own */}
                      {/* <li key={"new_tenant"}>
                        <button
                          // color="white"
                          // disabled={a.status === "Invited"}
                          onClick={() => goToTenant("new", "new")}
                          className="w-full h-full text-left py-3 px-4 rounded bg-white 
                                border border-gray-200
                                shadow-sm duration-150 ease-in-out
                                hover:border-gray-300
                                focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                                
                               "
                        >
                          Create New Tenant
                        </button>
                      </li> */}
                    </ul>
                    {/* <div className="text-xs text-slate-500">
                  After selecting your assessment, click Next to begin.
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SelectTenant;
