import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabs, secure, ...props }) {
  // const [selectedTab, setSelectedTab] = useState("View All");
  const setSelectedTab = props.setSelectedTab;
  const selectedTab = props.selectedTab;
  const isVisible = (tab) => {
    if (secure && secure == true) {
      return tab.view.includes(props.profile.role);
    } else {
      return true;
    }
  };
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md 	cursor-pointer;"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => {
            // e.preventDefault();
            setSelectedTab(e.target.value);
          }}
        >
          {tabs.map((tab) => {
            if (isVisible(tab)) {
              return <option key={tab.name}>{tab.name}</option>;
            }
          })}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => {
            if (isVisible(tab)) {
              return (
                <a
                  key={tab.name}
                  onClick={(e) => {
                    setSelectedTab(tab.name, index);
                  }}
                  // href={tab.href}
                  className={classNames(
                    tab.name === selectedTab
                      ? "bg-primary-100 text-primary-700"
                      : "text-slate-500 hover:text-slate-700",
                    "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              );
            }
          })}
        </nav>
      </div>
    </div>
  );
}
