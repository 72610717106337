/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Switch } from "@headlessui/react";
// import { ProgressPlugin } from "webpack";
import { contactus } from "../modules/_contact_us";
import Skeleton from "./Skeleton";
import Button from "./Button";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ContactConfirm(props) {
  const [enabled, setEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useHistory();
  // const [company, setCompany] = useState("");

  const notify = async (e) => {
    e.stopPropagation();
    setLoading(true);
    let textarea = document.getElementById("message");
    const message = textarea.value;
    console.log("This is my message", message);
    const company = props.company;
    console.info("THIS IS MY TYPE", props.type);
    setMsg(message);
    const vendor_email =
      props.vendor_email == "" ? undefined : props.vendor_email;
    // setCompany(company);
    // console.info("MESSAGE", message, company);
    if (props.type === "marketplace") {
      console.info("MARKETPLACE");
      const result = await contactus.marketplace_email({
        message: msg,
        company: company,
        vendor_email: vendor_email,
        type: "marketplace",
      });
      textarea.value = "";
      result && setMsg("");
      result && setLoading(false);
      result && setSuccess(true);
    } else {
      const result = await contactus.contact_email({
        message: msg,
        company: company,
        type: "support",
      });
      textarea.value = "";
      result && setMsg("");
      result && setLoading(false);
      result && setSuccess(true);
    }
    if (props.type === "pre-assessment") {
      props.setConfirmed(true);
    }
  };

  const closeWindow = (e) => {
    // e.stopPropagation();
    if (props.type === "pre-assessment") {
      props.setConfirmed(true);
    }
    props.setModalOpen(false);
    setTimeout(() => {
      setMsg("");
      setSuccess(false);
      setEnabled(false);
    }, 300);

    // setSuccess(false);
  };

  return (
    <div className="bg-white  sm:rounded-lg">
      {success ? (
        <div className="">
          <div className="sm:col-span-2 px-5 py-2 mt-2 ">
            Thank you for requesting to connect. An email has been sent and you
            will receive a follow up email from {props.company} within 48 hours.{" "}
          </div>
          <div className="p-5 flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm border-gray-200 hover:border-gray-300 text-slate-600"
              disabled={loading}
              onClick={(e) => {
                // e.stopPropagation();
                closeWindow(e);
              }}
            >
              Close
            </button>
            {/* <Button disabled={!enabled} loader={loading} handleClick={notify}>
              Submit
            </Button> */}
          </div>
        </div>
      ) : (
        <div>
          <div className="sm:col-span-2 px-5 py-2 mt-2">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-slate-700"
            >
              {/* How can {props.company} help you? */}
              How can we help you?
            </label>
            <div className="mt-2 ">
              <textarea
                id="message"
                name="message"
                rows={4}
                placeholder={"Tell us a little about your request."}
                className=" block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 border border-gray-300 rounded-md"
                defaultValue={""}
                onChange={(e) => {
                  setMsg(e.target.value);
                }}
              />
            </div>
          </div>

          {/* <Switch.Group as="div" className="px-4  sm:px-6 py-2">
            <div className="mt-1 sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm text-slate-600">
                <Switch.Description>
                  <span>I agree to allow </span>
                  <span className="text-purple-600">{props.company}</span>{" "}
                  <span>to contact me at</span>{" "}
                  <span className="text-purple-600">{props.email} </span>
                  <span>for the purposes of following up with my request.</span>
                </Switch.Description>
              </div>
              <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? "bg-primary-600" : "bg-gray-200",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
              </div>
            </div>
          </Switch.Group> */}
          <div className="p-5 flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm border-gray-200 hover:border-gray-300 text-slate-600"
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation();
                props.type == "pre-assessment"
                  ? history.push({
                      pathname: "/manuals",
                    })
                  : props.setModalOpen(false);
                setEnabled(false);
              }}
            >
              Cancel
            </button>
            <Button
              // disabled={!enabled}
              loader={loading}
              handleClick={notify}
              audit={false}
              action="Marketplace Connect"
              details={{
                message: msg,
                company: props.company,
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
