import React, { useState } from "react";
import Skeleton from "../components/Skeleton";
import { audit } from "../modules/_audit";

// SAMPLE BUTTON USAGE
// NOTE: Submit, Save, etc is always purple, cancel, close, exit is always white. Use green for action buttons at top of page
// and the middle of the page for <Empty> buttons

{
  /* 
    <Button
      enabled={!loader} //I don't think this is actually used
      loader={loader}
      type="submit"
      iconButton={true} //is only for the small circle button for clikcing on icons
      rounded //this means fully rounded like oval. Leave off for default rounded and iconButton
      disabled={disabled} //this is for disabling the button
      color="purple" // purple, green, white, text, link
      size="sm" //sm, md, lg  --> this creates the built in padding on top and bottom of the button
      fullWidth //this means takes up full width of parent container. leave off for default width    
      handleClick={onRegister}
      audit={true} //use this to audit the action being performed. Can be left off if not needed
      action="Invite User" //name of the action being performed when auditing is enabled. Only needed if audit is true
      details={{ user: fullName, email: email, role: role }} //additional details to be logged when auditing is enabled
      >
        <LockIcon className="h-5 w-5 mr-2" aria-hidden="true" />  Sign in
    </Button> 
  */
}

// Still to-do:
// Icon Button: className="inline-flex items-center p-2  border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
// Pill button: className="ml-2 inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none "

function Button(props) {
  const loader = props.loader;
  const loading = props.loader;
  const disabled = props.disabled;
  const full = props.fullWidth;
  const isAudit = props.audit;
  const action = props.action;
  const iconButton = props.iconButton;
  const forButton = props.iconButton ? false : true;
  const color = props.color ? props.color : "default";
  const auditDetails = props.details ? props.details : {};
  const rounded = props.rounded;
  const ref = props.ref ? props.ref : null;
  const className = props.className ? props.className : "";
  const shadow = props.shadow ? props.shadow : false;

  // console.info("Button props", auditDetails, props.details);

  const handleAuditClick = (e) => {
    audit.logAction(action, auditDetails);
    props.handleClick(e);
  };

  const buttonStyle = (color) => {
    if (disabled == true) {
      return "btn bg-gray-200 text-slate-400 cursor-not-allowed";
    } else {
      switch (color) {
        case "primary":
          return "btn bg-primary-600 hover:bg-primary-700 text-white";
        case "secondary":
          return "btn bg-secondary-600 hover:bg-secondary-600  text-white";
        case "tertiary":
          return "btn bg-tertiary-600 hover:bg-tertiary-700 text-white";
        case "accent":
          return "btn bg-accent-600 hover:bg-accent-700 text-white";
        case "indigo":
          return "btn bg-indigo-600 hover:bg-indigo-700 text-white";

        case "purple":
          return "btn bg-primary-600 hover:bg-primary-700 text-white";
        case "orange":
          return "btn bg-orange-600 hover:bg-orange-700 text-white";
        case "red":
          return "btn bg-red-600 hover:bg-red-700 text-white";
        case "light-purple":
          return "btn bg-primary-200 hover:bg-purple-250 text-purple-500";
        case "lime":
          return "btn bg-lime-500 hover:bg-lime-600 text-white ";
        case "white":
          return "btn bg-white border-gray-200 hover:border-gray-300 text-slate-600 ";
        // className="btn-sm border-gray-200 hover:border-gray-300 text-slate-600"
        case "outline":
          return "btn border-indigo-500 hover:border-indigo-500 hover:bg-indigo-100 text-indigo-500";
        case "text":
          return "text-slate-500 hover:text-primary-500 mx-3 p-1";
        case "link":
          return "btn shadow-none text-secondary-600 hover:text-secondary-700";
        default:
          return (
            "btn bg-primary-600 hover:bg-primary-700 text-white " +
            props.className
          );
      }
    }
  };

  const buttonSize = (buttonSize) => {
    switch (buttonSize) {
      case "xs":
        return "h-fit px-2.5 py-1.5 text-xs font-medium";
      case "sm":
        return "btn-sm text-xs py-1.5 font-normal ";
      case "md":
        return "btn-md ";
      case "lg":
        return "btn-lg ";
      default:
        return "btn-md ";
    }
  };

  return (
    <button
      className={
        iconButton
          ? `rounded-full p-2 ${!disabled && "hover:bg-slate-100"} ${
              disabled && "cursor-not-allowed"
            }`
          : `${buttonStyle(color)} ${buttonSize(props.size)}  ${
              full ? " w-full " : ""
            } ${rounded ? " rounded-full px-4" : "rounded-sm px-4"} ${
              shadow && "shadow-sm shadow-slate-400"
            } ${className} `
      }
      onClick={(e) =>
        props.handleClick
          ? isAudit
            ? handleAuditClick(e)
            : props.handleClick(e)
          : isAudit
          ? audit.logAction(action, auditDetails)
          : null
      }
      disabled={disabled}
    >
      {loading && (
        <Skeleton
          type="spinner"
          color="gray"
          fill="purple"
          forButton={iconButton ? false : forButton}
          forIcon={iconButton ? true : false}
        />
      )}
      {props.children}
    </button>
  );
}

export default Button;
