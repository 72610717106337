import { React, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import DynamicImage from "./DynamicImage";

import { _auth } from "../modules/_auth";
import Button from "./Button";
import ContactConfirm from "./ContactConfirm";
import ModalBasic from "./ModalBasic";
import { FeatureFlag } from "./FeatureFlag";

export default function ActionPlanExplanation(props) {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const profile = _auth.getUserProfile();
  console.info("ASMT-EXPLANATION", props);
  const index = props.index;
  const title = props.action_title;
  const action_plan = props.action_plan;
  const answered_by = props.answered_by;
  const marketplace = props.marketplace
    ? props.marketplace
    : ["View Marketplace"];
  const baseURL = "../images/pci_action_icons/";
  const handleHelpModalClick = () => {
    setShowModal(true);
  };
  const handleHelpModalClose = () => {
    setShowModal(false);
  };
  const viewMarketplace = (e, item) => {
    e.preventDefault();
    if (item == "View Marketplace") {
      item = "View All";
    }

    // console.info("VIEW MARKETPLACE", item);
    history.push({
      pathname: "/marketplace",
      state: { filter: item },
    });
  };

  const getLevelName = (level) => {
    switch (level) {
      case 0:
        return "Wrong";
      case 1:
        return "Wrong";
      case 2:
        return "Wrong";
      case 3:
        return "Expert";
      default:
        return "Wrong";
    }
  };

  const getLevelColor = (score) => {
    if (score < 2) {
      //beginner
      return "bg-red-500 text-red-700";
    } else if (score < 3) {
      //intermediate
      return "text-orange-500";
    } else if (score < 4) {
      //advanced
      return "text-amber-500 ";
    } else if (score <= 5) {
      //expert
      return "text-emerald-500";
    } else {
      //default
      return "text-slate-600";
    }
  };
  const getLevelBGColor = (score) => {
    // alert(score);
    if (score == 0) {
      //beginner
      return "bg-red-700 text-red-50";
    } else if (score == 1) {
      //intermediate
      return "bg-orange-500 text-orange-100";
    } else if (score == 2) {
      //advanced
      return "bg-amber-500 text-amber-100 ";
    } else if (score == 3) {
      //expert
      return "bg-emerald-500 text-emerald-300";
    } else {
      //default
      return "bg-slate-200 text-slate-600";
    }
  };

  return (
    <li key={index} className="py-10">
      <div className="flex space-x-3">
        <div className="flex-1 space-y-1">
          <div className=" mx-auto grid grid-cols-4 gap-6 sm:px-6">
            <div className=" col-span-3">
              <div className="flex items-center mb-3"></div>
              <div className="flex items-center mb-3">
                <p className="text-xl w-full grid-cols full font-medium text-slate-900">
                  {props.index}
                  {". "} {props.question}
                </p>
              </div>
              <div className="flex flex-col  mb-3 space-y-10">
                <div className=" whitespace-wrap  text-slate-500 ">
                  <h2 className=" flex leading-6 text-slate-600 font-semibold tracking-wide uppercase">
                    Your Answer
                  </h2>
                  {props.your_answer}{" "}
                  <span
                    className={`ml-2 bg-red-500 text-white rounded-full py-0.5 px-2 text-xs`}
                  >
                    {getLevelName(props.your_answer_index)}
                  </span>
                </div>
                <div className=" whitespace-wrap  text-slate-500">
                  <h2 className=" flex leading-6 text-slate-600 font-semibold tracking-wide uppercase">
                    Correct Answer
                  </h2>{" "}
                  {props.best_answer}
                </div>

                <div className=" whitespace-wrap  text-slate-500 ">
                  <h2 className=" flex leading-6 text-slate-600 font-semibold tracking-wide uppercase">
                    {/* {title}{" "} */}
                    {answered_by &&
                      answered_by != _auth.getUserProfile().name && (
                        <div className="ml-2 text-primary-500 text-sm font-thin">
                          (Answered by {answered_by})
                        </div>
                      )}
                  </h2>

                  {/* {action_plan} */}
                </div>
              </div>

              <div>
                <div className="mt-6  space-x-2 space-y-2">
                  {/* <Button
                    className="w-48"
                    size="sm"
                    color="accent"
                    handleClick={handleHelpModalClick}
                  >
                    Get help
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalBasic
        id="support_modal"
        modalOpen={showModal}
        setModalOpen={setShowModal}
        title={"Contact JDO"}
        showClose={false}
      >
        <ContactConfirm
          setModalOpen={setShowModal}
          company={"JDO"}
          email={profile !== null && _auth.getUserProfile().email}
          vendor_email={"vendor_email"}
          type="support"
        />
      </ModalBasic>
    </li>
  );
}
