/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
*/
import { Fragment } from "react";
import {
  ChatAltIcon,
  TagIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { _auth } from "../modules/_auth";
const activity = [
  {
    id: 1,
    type: "comment",
    person: { name: "Eduardo Benz", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ",
    date: "6d ago",
  },
  {
    id: 2,
    type: "assignment",
    person: { name: "Hilary Mahy", href: "#" },
    assigned: { name: "Kristin Watson", href: "#" },
    date: "2d ago",
  },
  {
    id: 3,
    type: "tags",
    person: { name: "Hilary Mahy", href: "#" },
    tags: [
      { name: "Bug", href: "#", color: "bg-rose-500" },
      { name: "Accessibility", href: "#", color: "bg-primary-500" },
    ],
    date: "6h ago",
  },
  {
    id: 4,
    type: "comment",
    person: { name: "Jason Meyers", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.",
    date: "2h ago",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const typeIcon = (type) => {
  switch (type) {
    case false:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-1 h-5 w-5"
          viewBox="0 0 20 20"
          fill="red"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      );
    case "unsure":
      return (
        <svg className="mr-2 h-5 w-5" viewBox="0 0 20 20" fill="red">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      );
    case true:
      return (
        <svg
          className="fill-green-500 w-4 h-4 shrink-0   mt-[3px] mr-3"
          viewBox="0 0 16 16"
          fill="#22c55e"
        >
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          />
        </svg>
      );
  }
};

const typeColor = (type) => {
  switch (type) {
    case "warning":
      return "bg-yellow-500";
    case "no":
      return "red";
    case "unsure":
      return "bg-red-500";
    case "yes":
      return "green";
    default:
      return "bg-primary-500";
  }
};

export default function AnswerList(props) {
  const questions = props.questions.questions;
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {questions.map((item, itemIdx) => (
          <li className="border-b border-gray-200 mb-5" key={item.id}>
            <div className="relative pb-8">
              <div className="relative flex items-start space-x-3">
                <div className=" col-span-3">
                  <div className="">
                    <div className={`inline-flex  `}></div>
                    <h2 className=" inline-flex leading-6 text-slate-600 font-semibold tracking-wide uppercase mb-2">
                      {/* <div className={`flex ${typeColor(item.your_answer)}`}> */}
                      {typeIcon(item.isCorrect)} Question {itemIdx + 1}
                      {": "}
                      {item.question}
                    </h2>
                    {/* <h3 className="text-sm font-medium mb-5"></h3> */}
                  </div>
                  <div className="mb-2">
                    <p className="text-sm text-slate-500">
                      {" "}
                      {item.answered_by && (
                        <div className="ml-2 text-primary-500 text-sm font-thin">
                          (Answered by{" "}
                          {item.answered_by === _auth.getUserProfile().name
                            ? "You"
                            : item.answered_by}
                          )
                        </div>
                      )}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="text-sm text-slate-500 uppercase">
                      Your Answer: {item.your_answer}
                    </p>
                    {item.isCorrect === false && (
                      <p className="text-sm text-slate-500 uppercase">
                        Correct Answer: {item.best_answer}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
