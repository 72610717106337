import { postRequest, getRequest, signupPostRequest } from "./ApiRequest";
import setAuthToken from "../config/setAuthToken";
export const _marketplace = {
  getMarketplaceTabs() {
    const marketplace = [
      { name: "View All", href: "#", current: true },
      { name: "Cyber Insurance", href: "#", current: false },
      { name: "Preparedness", href: "#", current: false },
      { name: "Incident Response", href: "#", current: false },

      // { name: "Breach Notification", href: "#", current: false },
      { name: "Legal Advice", href: "#", current: false },
      { name: "Coaching", href: "#", current: false },
      // { name: "Scurity Assessors", href: "#", current: false },
      // { name: "Managed Service Providers", href: "#", current: false },
      // { name: "Phishing Simulation", href: "#", current: false },
      // { name: "Education", href: "#", current: false },
    ];
    return marketplace;
  },
  getMarketPlace() {
    const marketplace = [
      {
        title: "Arete",
        category: ["Incident Response"],
        services: ["Forensics", "Breach Review"],
        content: "Take back control. We're with you. Every step of the way.",
        website: "",
        email: "oransears@gmail.com",
        image:
          "https://mma.prnewswire.com/media/1091358/Arete_Logo.jpg?p=facebook",
        to: "#",
        rating: "4.5",
        connections: "1.2M",
        trending: true,
        button: "Connect",
        to: "/home",
      },
      {
        title: "BreachQuest",
        category: ["Preparedness", "Incident Response"],
        services: ["Coaching", "Pen Testing", "Threat Hunting"],
        content:
          "BreachQuest's quick data collection and unparalleled analysis enables fast scoping, containment, and recovery.",
        website: "",
        email: "",
        image: "https://via.placeholder.com/150",
        to: "#",
        rating: "4.5",
        connections: "1.2M",
        trending: true,
        button: "Connect",
        to: "/home",
      },
      {
        title: "CyberClan",
        category: ["Preparedness", "Coaching", "Incident Response"],
        services: ["Coaching", "Manged Detection & Reponse", "Remediation"],
        content:
          "CyberClan provides enterprise security, and a human response to small and midsize enterprises and channel partners through comprehensive risk assessment ...",
        website: "",
        email: "",
        image: "https://via.placeholder.com/150",
        to: "#",
        rating: "4.5",
        connections: "1.2M",
        trending: true,
        button: "Connect",
        to: "/home",
      },
      {
        title: "DLA Piper",
        category: ["Preparedness", "Incident Reponse", "Legal Advice"],
        services: ["Incident Reponse", "Breach Notification", "Legal Advice"],
        content: "Protect your business from cyber threats",
        website: "",
        email: "",
        image: "https://via.placeholder.com/150",
        to: "#",
        rating: "4.5",
        connections: "1.2M",
        trending: true,
        button: "Connect",
        to: "/home",
      },
      {
        title: "Equifax",
        category: ["Incident Response"],
        services: ["Identity Protection", "Breach Notification"],
        content: "Protect your business from cyber threats",
        website: "",
        email: "",
        image: "https://via.placeholder.com/150",
        to: "#",
        rating: "4.5",
        connections: "1.2M",
        trending: true,
        button: "Connect",
        to: "/home",
      },
      {
        title: "Joseon Group",
        category: ["Coaching"],
        services: ["Coaching"],
        content: "Protect your business from cyber threats",
        website: "",
        email: "",
        image: "https://via.placeholder.com/150",
        to: "#",
        rating: "4.5",
        connections: "1.2M",
        trending: true,
        button: "Connect",
        to: "/home",
      },
      {
        title: "TOMA",
        category: ["Cyber Insurance"],
        services: ["Cyber Insurance"],
        content: "Protect your business from cyber threats",
        website: "",
        email: "",
        image: "https://via.placeholder.com/150",
        to: "#",
        rating: "4.5",
        connections: "1.2M",
        trending: true,
        button: "Connect",
        to: "/home",
      },
    ];
    return marketplace;
  },
  async getMarketPlaceVendors() {
    let response = await getRequest("get-all-marketplace");
    if (response) {
      // return response.data;
      return response.data.data;
    } else {
      console.info("No data found");
      return [];
    }
    // } catch(e) {
    //   return []

    // // try {
    // // console.log("running");
    // // let token = localStorage.getItem("jwtToken");
    // // setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // let response = await getRequest("get-all-marketplace");
    // // console.info("TESTM", response);
    // if (response && response.data && response.status == 200) {
    //   // console.info("RETURNING TESTM MARKETPLACE", response.data.marketplace);
    //   return response.data.marketplace;
    // } else {
    //   return [];
    // }
    // // } catch (e) {
    // //   return [];
    // // }
  },
  async getMarketPlaceVendors1() {
    // try {
    let response = await postRequest("get-all-marketplace")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        return "";
      });
    // if (response && response.data && response.status == 200) {
    //   console.info("RETURNING TESTM", response.data);
    //   return response.data.marketplace;
    // } else {
    //   return [];
    // }
    // console.info("TESTM", response);

    // if (response.data.status === 200) {
    //   // console.log("testm success", response.data.data);
    //   // console.log("testm data is array", Array.isArray(response.data.data));
    //   return response.data.data;
    // } else {
    //   console.log("testm ERROR", response);
    // }

    // console.info("testm1 from Module", response.data.data.marketplace);
    // return response.data.data.marketplace;

    // if (response.status === 200) {
    //   console.info("testm 2 from Module", response.status);
    //   return response;
    // } else {
    //   return [];
    // }
    // } catch (e) {
    //   return false;
    // }
  },
};
