import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import { useHistory } from "react-router-dom";
// import { NavLink, useNavigate } from "react-router-dom";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Button from "../components/Button";
import Input from "../components/Input";

function Signup() {
  // const navigate = useNavigate();
  // const { register } = useAuthStore();
  const history = useHistory();
  const [isValidForSignup, setIsValidForSignup] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeInvalid, SetAccessCodeInvalid] = useState(false);
  const [accessCodeErrorMessage, setAccessCodeErrorMessage] = useState("");
  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageCompanyName, setErrorMessageCompanyName] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeText = (value, variant) => {
    // console.info("onChangeText", variant, value);

    if (variant === "email") {
      setEmail(value);
      setErrorMessageEmail("");
    } else if (variant === "username") {
      setFullName(value);
      setErrorMessageName("");
    } else if (variant === "password") {
      setPassword(value);
      setErrorMessagePassword("");
    } else if (variant === "confirmpassword") {
      setConfirmPassword(value);
      setErrorMessagePassword("");
    } else if (variant === "companyName") {
      setCompanyName(value);
      setErrorMessageCompanyName("");
    } else if (variant === "accessCode") {
      setAccessCode(value);
      setAccessCodeErrorMessage(false);
    }
  };

  const sgn = async () => {
    let resp = await _auth.signup2(
      email,
      password,
      fullName,
      companyName,
      accessCode

      // (success) => {
      //   if (success) {
      //     console.log("success", success);
      //     setLoading(false);
      //     history.push("/signupconfirmation");
      //   }
      // },
      // (error) => {
      //   setLoading(false);
      //   console.info("error", error);
      //   setSignUpErrorMessage(error.errorMessage);
      // }
    );

    return resp;
  };

  const onRegister = async (event) => {
    let amIvalid = true;
    setLoading(true);
    event.preventDefault();
    setIsValidForSignup(true);
    const isValid = new Promise((resolve, reject) => {
      console.info("onRegister Clicked");
      let emailRegex = new RegExp(/\S+@\S+\.\S+/);
      // let accessCode = _auth.getAccessCode().toString();
      if (!accessCode) {
        amIvalid = false;
        setIsValidForSignup(false);
        SetAccessCodeInvalid(true);
        setAccessCodeErrorMessage("Please enter an Invitation Code");
      } else {
        // accessCode.toString();
        if (accessCode.toString() !== _auth.getAccessCode().toString()) {
          amIvalid = false;
          setIsValidForSignup(false);
          SetAccessCodeInvalid(true);
          setAccessCodeErrorMessage("Please enter a valid Inviation Code");
        }
      }
      if (!fullName) {
        console.log("please enter full name");
        setErrorMessageName("Please enter your full name");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      if (!email || !emailRegex.test(email)) {
        console.log("please enter email");
        setErrorMessageEmail("Please enter a valid email address");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      if (!email || !emailRegex.test(email)) {
        console.log("Please use a company email address");
        setErrorMessageEmail("Please use a company email address");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      if (!companyName) {
        console.log("please enter company name");
        setErrorMessageCompanyName("Please enter your company name");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      // if (!password) {
      //   console.log("please enter password");
      //   setErrorMessagePassword("Please enter a password");
      //   setIsValidForSignup(false);
      // }

      // if (password.toString() !== confirmPassword.toString()) {
      //   console.log("passwords do not match");
      //   setErrorMessagePassword("The passwords you entered do not match");
      //   setIsValidForSignup(false);
      // }

      console.info("Is it valid for signup?", isValidForSignup);
      resolve("Success!");
    })
      .then(() => {
        const isNaughtyList = new Promise((resolve, reject) => {
          let isNaughty = _auth.checkNaughtyList(email);
          resolve(isNaughty);
        })
          .then((naughty) => {
            console.log("isNaughtyListResp", naughty);
            if (naughty === true) {
              amIvalid = false;
              setErrorMessageEmail("Personal emails are not allowed");
              console.info(
                "Found in naughty list, setting IsValidForSignup to false"
              );
              setIsValidForSignup(false);
            } else {
              console.info(
                "Not found in naughty list, setting IsValidForSignup to true"
              );
              setIsValidForSignup(true);
            }
          })
          .then(() => {
            console.info("AM I VALID?", amIvalid);
            console.info("NOW CHECKING IF WE CAN CREATE THE ACCOUNT");
            if (amIvalid === false) {
              console.info(
                "Cannot start creation, not valid",
                isValidForSignup
              );
              setLoading(false);
            } else {
              console.info("Are we valid for signup?", isValidForSignup);
              setLoading(true);
              console.info(
                "Everything passed, signing up",
                email,
                password,
                fullName,
                companyName,
                accessCode
              );
              sgn().then((resp) => {
                console.log("My response", resp);
                if (resp.status) {
                  if (resp.status === 200) {
                    console.log("Signup success", resp);
                    setLoading(false);
                    // history.push({
                    //   path: "/signupconfirmation",
                    //   state: { name: fullName },
                    // });
                    history.push("/signupconfirmation");
                  }
                } else {
                  console.log("You failed", resp);
                  setLoading(false);
                  setSignUpErrorMessage(resp.errorMessage);
                }
              });
              // .catch((error) => {
              //   setLoading(false);
              //   console.info("error", error);
              //   setSignUpErrorMessage(error.errorMessage);
              // });
            }
          })
          .catch((error) => {
            console.info("error", error);
          });
      })
      .catch((error) => {
        console.info("error", error);
      });
  };

  //   let resp = new Promise (resolve, reject) => {
  //     const res = sgn();
  //   })
  //   resp
  //     .then((resp) => {
  //   console.info("resp", resp);
  //   if (resp.success) {
  //     console.log("success", resp);
  //     setLoading(false);
  //     history.push("/signupconfirmation");
  //   } else {
  //     setLoading(false);
  //     setSignUpErrorMessage(resp.errorMessage);
  //   }
  // }
  //   )}
  //       });
  //   });
  // };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* <Link className="block" to="/">
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <defs>
                      <linearGradient
                        x1="28.538%"
                        y1="20.229%"
                        x2="100%"
                        y2="108.156%"
                        id="logo-a"
                      >
                        <stop stopColor="#A5B4FC" stopOpacity="0" offset="0%" />
                        <stop stopColor="#A5B4FC" offset="100%" />
                      </linearGradient>
                      <linearGradient
                        x1="88.638%"
                        y1="29.267%"
                        x2="22.42%"
                        y2="100%"
                        id="logo-b"
                      >
                        <stop stopColor="#38BDF8" stopOpacity="0" offset="0%" />
                        <stop stopColor="#38BDF8" offset="100%" />
                      </linearGradient>
                    </defs>
                    <rect fill="#6366F1" width="32" height="32" rx="16" />
                    <path
                      d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z"
                      fill="#4F46E5"
                    />
                    <path
                      d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z"
                      fill="url(#logo-a)"
                    />
                    <path
                      d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z"
                      fill="url(#logo-b)"
                    />
                  </svg>
                </Link> */}
              </div>
            </div>

            <div className="max-w-md mx-auto px-4 py-8">
              <h1 className="text-3xl text-slate-800 font-bold mb-6">
                Sign up to get started
              </h1>
              {/* Form */}
              {/* <form>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="name"
                      className="form-input w-full"
                      type="text"
                    />
                  </div>

                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <label className="flex items-center">
                      <input type="checkbox" className="form-checkbox" />
                      <span className="text-sm ml-2">
                        Email me about product news.
                      </span>
                    </label>
                  </div>
                  <Link
                    className="btn bg-primary-500 hover:bg-primary-600 text-white ml-3 whitespace-nowrap"
                    to="/"
                  >
                    Sign Up
                  </Link>
                </div>
              </form> */}
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                {/* <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}
                <form className="space-y-6" method="post">
                  <Input
                    type="text"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    label="Full Name"
                    placeholder="Type your full name"
                    id="input"
                    value={fullName}
                    onChange={(e) => onChangeText(e.target.value, "username")}
                    errorText={errorMessageName ? errorMessageName : undefined}
                  />
                  <Input
                    type="text"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    label="Work Email address"
                    placeholder="Work email address"
                    id="input"
                    value={email}
                    onChange={(e) => onChangeText(e.target.value, "email")}
                    errorText={
                      errorMessageEmail ? errorMessageEmail : undefined
                    }
                  />
                  {/* <Input
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    label="Password"
                    placeholder="Type your password"
                    id="input"
                    type="password"
                    value={password}
                    onChange={(e) => onChangeText(e.target.value, "password")}
                    errorText={
                      errorMessagePassword ? errorMessagePassword : undefined
                    }
                  />
                  <Input
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    label="Confirm Password"
                    placeholder="Re-type your password"
                    id="input"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) =>
                      onChangeText(e.target.value, "confirmpassword")
                    }
                    errorText={
                      errorMessagePassword ? errorMessagePassword : undefined
                    }
                  /> */}
                  <Input
                    type="text"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    label="Company Name"
                    placeholder="Enter your company name"
                    id="input"
                    value={companyName}
                    onChange={(e) =>
                      onChangeText(e.target.value, "companyName")
                    }
                    errorText={
                      errorMessageCompanyName
                        ? errorMessageCompanyName
                        : undefined
                    }
                  />
                  <Input
                    type="text"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    label="Invitation Code"
                    placeholder="Enter your invitation code"
                    id="input"
                    value={accessCode}
                    onChange={(e) => onChangeText(e.target.value, "accessCode")}
                    errorText={
                      accessCodeErrorMessage
                        ? accessCodeErrorMessage
                        : undefined
                    }
                  />
                </form>
                {/* </div> */}
                <div className="flex items-center justify-between mt-5">
                  {signUpErrorMessage !== "" && (
                    <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                      {signUpErrorMessage}
                    </div>
                  )}
                  {/* <div className="text-sm">
                      <Link
                        to="#"
                        className="font-extra-small text-primary-600 hover:text-primary-500"
                      >
                        {" "}
                        By signing in you accept the t&c and privacy policy
                      </Link>
                    </div> */}
                </div>
                <div className="mt-5">
                  {/* <button
                    // size={ButtonSize.LARGE}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    title="sign in"
                    // variant={ButtonVariant.PRIMARY}
                    onClick={onRegister}
                  /> */}
                  <Button
                    // enabled={!loader}
                    loader={loading}
                    type="submit"
                    onClick={onRegister}
                    handleClick={onRegister}
                    fullWidth
                    size="md"
                    color="secondary"
                  >
                    Sign up
                  </Button>
                  {/* <Button
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    // variant={ButtonVariant.PRIMARY}
                  >
                    Sign up
                  </Button> */}
                </div>
              </div>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Have an account?{" "}
                  <Link
                    className="font-medium text-primary-500 hover:text-primary-600"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
                <div className="text-sm">
                  <a
                    className="font-medium text-primary-500 hover:text-primary-600"
                    href="#"
                  >
                    Need an invitation code?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        {/* <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div> */}
      </div>
    </main>
  );
}

export default Signup;
