import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import { utils } from "../../modules/_utils";
import Button from "../../components/Button";
import {
  DocumentChartBarIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

export default function SolicitationList(props) {
  const history = useHistory();
  const { project, items } = props;
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const handleItemClick = (e, item) => {
    e.preventDefault();
    props.onItemClick(project, item);
  };
  useEffect(() => {
    console.log("Solicitation List", items);
    setData(items);
  }, [items]);

  const progress = (item) => {
    const res = item.questions
      ? item.questions.filter((item) => "your_answer" in item).length
      : null;

    return res != null ? res / item.questions.length : null;
  };

  const goToActionPlan = (customer) => {
    // e.preventDefault();

    return history.push({
      pathname: "/actionPlan",

      state: {
        test: props.counts,
        data: props.counts,
        thisAssessment: customer,
      },
    });
  };
  const deleteAssessment = (thisAssessment) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this assessment?"
    );
    if (!confirm) {
      return;
    }

    props.handleDeleteAssessment(thisAssessment._id);
  };

  const resumeAssessment = (thisAssessment, type) => {
    let isPreassessment = false;
    if (type === "pre") {
      isPreassessment = true;
    }
    debugger;
    let state = {
      isPreassessment: false,

      thisAssessment: thisAssessment,
      assessment_type: type,
      resume: true,
    };

    return history.push({
      pathname: "/assessment",
      state: state,
    });
  };
  const setModalItem = (thisAssessment) => {
    props.setModalItem(thisAssessment);
  };

  return (
    <div className="overflow-hidden bg-white  sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data &&
          data.length > 0 &&
          data
            .slice(0)
            .reverse()
            .map((customer) => {
              console.info("DB11 Assessment History Table DATA", customer);

              const assessment_id = customer.assessment_id;
              console.info("ASSESMENT", assessment_id);
              const assessment_status = customer.assessment_status;
              const assessment_type = customer.assessment_type;
              const assessment_score = customer.risk_score;
              const assessment_taken_by = customer.taken_by;
              const contributorArray = [];
              customer.length > 0 &&
                customer.questions.map((item) => {
                  item.answered_by &&
                    item.answered_by != null &&
                    item.risk_score != "null" &&
                    contributorArray.push(item.answered_by);
                });
              console.info("Cont Arry", contributorArray);
              const countOfEach = {};
              for (const num of contributorArray) {
                countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
              }
              // console.info(" Cont Arry CountofEach", countOfEach);
              // console.info(
              //   "COUNTOFECH > 0",
              //   Object.keys(countOfEach).length > 0
              // );
              const contributors = Object.keys(countOfEach).length > 1;
              // const [avgRisk, setAvgRisk] = useState(
              //   Object.keys(countOfEach).length > 0
              //     ? Object.keys(countOfEach).reduce((a, b) =>
              //         countOfEach[a] > countOfEach[b] ? a : b
              //       )
              //     : null
              // );
              // setAvgRisk(

              // );
              // console.info(" Cont Arry test", avgRisk);

              const res = customer.questions
                ? customer.questions.filter((item) => "your_answer" in item)
                    .length
                : null;
              let progress =
                res != null ? res / customer.questions.length : null;
              // console.info(
              //   "PROGRESS",
              //   res,
              //   "answered out of",

              //   // customer.questions.length,
              //   progress
              // );

              // const assessment_taken_on = customer.assessment_date;
              const assessment_taken_on = new Date(
                customer.assessment_date
              ).toLocaleDateString("en-us", {
                // weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const assessment_taken_by_email = customer.taken_by_email;
              console.info("LAST ORDER", customer.status);
              return (
                <li
                  key={assessment_id}
                  // onClick={(e) => handleItemClick(e, customer)}
                >
                  {/* <a
                    href="#"
                    className="block hover:bg-gray-50 hover:no-underline"
                  > */}
                  <div className="justify-between px-4 py-4 sm:px-6 block hover:bg-gray-50 hover:no-underline ">
                    <div className=" flex items-center justify-between">
                      <div className="flex items-center">
                        <div>
                          <p className="capitalize truncate text-sm font-medium text-indigo-600">
                            {assessment_type}
                          </p>
                        </div>

                        <div class="ml-6  w-36 bg-gray-200 h-1   rounded-full">
                          <div
                            class="bg-green-500 h-1 rounded-full"
                            style={{ width: progress * 100 + "%" }}
                          ></div>
                        </div>
                      </div>

                      <div className="sm:flex">
                        {/* <Button className="sm:ml-3">Resume</Button>
                          <Button className="sm:ml-3">Results</Button>
                          <Button className="sm:ml-3">Delete</Button> */}
                        <div className="flex space-x-2 text-left font-medium ">
                          {assessment_status === "Finished" ? (
                            assessment_type != "pre" && (
                              <div>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    goToActionPlan(customer);
                                  }}
                                  className="inline-flex items-center p-2  border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                >
                                  <DocumentChartBarIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>

                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModalItem(customer);
                                  }}
                                  className="inline-flex items-center p-2 ml-1 border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                >
                                  <QuestionMarkCircleIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            )
                          ) : (
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                resumeAssessment(customer, assessment_type);
                              }}
                              className="inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-accent-600 hover:bg-accent-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
                            >
                              <PencilSquareIcon
                                className="h-4 w-4 mr-2"
                                aria-hidden="true"
                              />
                              <span className="hidden sm:inline">Resume</span>
                            </button>
                          )}
                          <Button
                            iconButton
                            handleClick={(e) => {
                              e.stopPropagation();
                              deleteAssessment(customer);
                            }}
                            type="delete"
                          >
                            <TrashIcon
                              className="h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          </Button>
                        </div>
                        {/* {item &&
                                  item.services &&
                                  item.services.map((service) => {
                                    return (
                                      <div className="ml-2 flex flex-shrink-0">
                                        <p className="inline-flex capitalize rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                          {utils.cleanUpKeys(service.service)}
                                        </p>
                                      </div>
                                    );
                                  })} */}
                      </div>
                    </div>

                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          {/* Taken by {assessment_taken_by} on{" "} */}
                          <time dateTime={assessment_taken_on}>
                            {/* {utils.formatDate(assessment_taken_on)} */}
                            {assessment_taken_on}
                          </time>
                        </p>
                      </div>
                      <div className="sm:flex">
                        {/* Bottom Right Side */}
                        {/* <p className="flex items-center text-sm text-gray-500">
                          <UsersIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {item.commitments ? item.commitments.length : 0}
                          {" committed "}
                        </p>
                        <p className="flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <UsersIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {item.declines ? item.declines.length : 0}
                          {" declined "}
                        </p>
    
                        <p className="flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {item.responses ? item.responses.length : 0}
                          {" responded "}
                        </p> */}
                      </div>
                    </div>
                  </div>
                  {/* </a> */}
                </li>
              );
            })}
      </ul>
    </div>
  );
}
