import React from "react";
import { useState, useEffect } from "react";
import Tabs from "../pages/component/Tabs";
import Table, { SelectColumnFilter, GlobalFilter } from "./ReactTable";
import { _assessment } from "../modules/_assessment";
import Button from "./Button";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import Tooltip from "./Tooltip";
import ModalBasic from "./ModalBasic";
import AssessmentTemplateCreator from "./AssessmentTemplatesCreator";
import { FeatureFlag } from "./FeatureFlag";
import DialogBox from "./Dialog";

export default function AssessmentTemplatesPanel(props) {
  // const tabs = _user.getUserPanelTabs();

  const [selectedTab, setSelectedTab] = useState("All Users");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalItem, setModalItems] = useState({});
  const [createNew, setCreateNew] = useState(false);

  useEffect(() => {
    //async
    (async () => {
      setLoading(true);
      const tmpl = await _assessment.getTemplates();
      if (tmpl) {
        console.log("tmpl", tmpl);
        setData(tmpl);
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const tmpl = await _assessment.getTemplates();
      if (tmpl) {
        console.log("tmpl", tmpl);
        setData(tmpl);
        setLoading(false);
      }
    })();
  }, [modalItem]);

  const updateModalItem = (item) => {
    setModalItems(item);
  };

  const RenderActions = (props) => {
    // console.log("RenderActions", props.data);
    return (
      <div className="flex items-center space-x-3">
        {/* <Tooltip
          className={"whitespace-normal  inline-flex"}
          size="sm"
          bg="dark"
          position={"top"}
          description={"Delete this user"}
        >
          <Button
            iconButton
            handleClick={(e) => {
              // e.stopPropagation();
              // onDeleteUser();
              // handleUpdateUserStatus("Deleted");
            }}
            className="inline-flex items-center p-2  border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none "
          >
            <TrashIcon
              className="h-5 w-5 border-red-500 text-red-500"
              aria-hidden="true"
            />
          </Button>
        </Tooltip> */}
        <Button
          className="ml-2"
          iconButton
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={() => viewDetails(props.row.original, props.data)}
        >
          {" "}
          <PencilSquareIcon className="h-5 w-5 " aria-hidden="true" />
          {/* <svg
            className="w-4 h-4 mr-2 ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg> */}
        </Button>
      </div>
    );
  };

  const viewDetails = (row, list) => {
    console.log("viewDetails", row, list);
    setCreateNew(false);
    setModalItems(row);
    setShowModal(true);
  };

  const setModalItem = (item) => {
    setModalItems(item);
    showHideModal(true);
  };

  const createNewItem = async () => {
    console.log("createNewItem Button clicked");
    let item = await _assessment.createTemplate();
    console.log("createNewItem", item);
    setCreateNew(true);
    setModalItems(item);
    showHideModal(true);
  };

  const showHideModal = () => {
    setShowModal(!showModal);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Type",
        accessor: "type",
        Filter: SelectColumnFilter, // new
        filter: "includes", // new
      },
      {
        Header: "Category",
        accessor: "category",
        Filter: SelectColumnFilter, // new
        filter: "includes", // new
      },
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: RenderActions,
      },
    ],
    []
  );

  const handleSelectedItems = (selectedItems) => {
    // setSelectedItems([...selectedItems]);
  };

  return (
    <>
      <div className="px-10 sm:px-6 lg:px-8 py-10 w-full max-w-9xl mx-auto">
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Welcome banner */}
          <div className="mb-0 sm:mb-0 inline-flex ">
            <h2 className="text-xl md:text-2xl text-slate-800 font-bold">
              Assessment Templates
            </h2>
          </div>
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Filter button */}
            {/* <FilterButton align="right" /> */}
            {/* Datepicker built with flatpickr */}
            {/* <Datepicker align="right" /> */}
            {/* Take Assessment */}
            {/* <Link to="../assessmentselect"> */}
            {/* <button className="btn bg-lime-500 hover:bg-lime-600 text-white">
              <svg
                className="w-4 h-4 fill-current opacity-50 shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="hidden xs:block ml-2">Add Users</span>
            </button> */}
            {/* </Link> */}
            {/* Add view button */}
            <FeatureFlag name="Create Assessment Template">
              <Button color={"lime"} handleClick={() => createNewItem()}>
                <svg
                  className="w-4 h-4 fill-current opacity-50 shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">Add template</span>
              </Button>{" "}
            </FeatureFlag>
          </div>
        </div>
        <div className="">
          <section className="">
            {selectedTab === "All Users" && (
              <Table
                columns={columns}
                data={data}
                loading={loading}
                fromDashboard={true}
                showFooter={false}
              />
            )}
          </section>
        </div>

        <ModalBasic
          id="assessment-template-create-modal"
          modalOpen={showModal}
          setModalOpen={showHideModal}
          title={"Assessment Template Details"}
          size="lg"
          showFooter
          // formAction={"update"}
          // createNew={createNew}
          // scrollable
        >
          {showModal == true && (
            <AssessmentTemplateCreator
              modalItem={modalItem}
              createNew={createNew}
              setModalItem={setModalItems}
              updateModalItem={updateModalItem}
              setCreateNew={setCreateNew}
              showHideModal={showHideModal}
            />
          )}
          {/* Modal footer */}
          {/* <div className="sticky w-full bottom-0 px-5 py-4 bg-white border-t border-gray-200">
            <div className="flex flex-wrap justify-end space-x-2">
              <button
                className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showHideModal();
                }}
              >
                Done
              </button>
            </div>
          </div> */}
        </ModalBasic>
      </div>
    </>
  );
}
