import React from "react";

export default function Badge(props) {
  const { children, color, className, ...rest } = props;
  //   const classes = useStyles();
  const getColor = (color) => {
    switch (color) {
      case "primary":
        return " bg-primary-200 text-primary-600";
      case "secondary":
        return " bg-secondary-200  text-primary-600";
      case "tertiary":
        return " bg-tertiary-200  text-primary-600";
      case "accent":
        return " bg-accent-500  text-white-600";
      case "purple":
        return " bg-primary-200 text-primary-600";
      case "orange":
        return " bg-orange-200  text-primary-600";
      case "red":
        return " bg-red-200  text-primary-600";
      case "light-purple":
        return " bg-primary-200  text-purple-500";
      case "lime":
        return " bg-lime-500  text-primary-600 ";
      case "white":
        return " bg-white border-gray-200  text-slate-200 ";
      case "text":
        return "text-slate-500  mx-3 p-1";
      case "link":
        return "text-blue-500 ";
      default:
        return " bg-primary-500 text-primary-600" + props.className;
    }
  };
  return (
    <div className="m-1.5">
      <div
        className={`${getColor(color)}
        
        text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1`}
      >
        {children}
      </div>
    </div>
  );
}
