import React, { useState, useEffect } from "react";
import TableItem from "./TableItem";
import Skeleton from "./Skeleton";

function Table({ selectedItems, data, fromDashboard, ...props }) {
  //   const { selectedItems, data, fromDashboard } = props;
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      id: 1,
      title: "Name",
    },
    {
      id: 2,
      title: "Email",
    },
    {
      id: 3,
      title: "Role",
    },
    {
      id: 4,
      title: "Status",
    },
    {
      id: 5,
      title: "Actions",
    },
  ]);
  const setModalItem = (d) => {
    props.setModalItem(d);
  };

  //   let data = "loading";

  useEffect(() => {
    if (data.length > 0) {
      setList(data);
    } else {
      setList(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  //   data = data;

  return (
    <div>
      {/* Table */}

      <div
        style={{ maxHeight: "50vh", minHeight: "50vh" }}
        className="overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full "
      >
        {/* overflow-hidden lg:overflow-auto scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded dark:scrollbar-track:!bg-slate-500/[0.16] dark:scrollbar-thumb:!bg-slate-500/50 max-h-96 supports-scrollbars:pr-2 lg:max-h-96 */}
        <table className="table-fixed w-full">
          {/* Table header */}
          {/* sticky z-10 top-0 text\sm leading-6 font-semibold text-slate-700 bg-white p-0 dark:bg-slate-900 dark:text-slate-300 border-t border-b*/}
          <thead className="sticky z-10 top-0 text-xs font-semibold uppercase text-slate-500 bg-gray-50 border-gray-200">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-12">
                <div className="flex items-center">
                  <label className="inline-flex">
                    <span className="sr-only">Select all</span>
                    <input
                      className="form-checkbox"
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </label>
                </div>
              </th>
              {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <span className="sr-only">Favourite</span>
                </th> */}
              {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-fit w-1/12">
                <div className="font-semibold text-left">ID</div>
              </th> */}
              {columns.map((value) => (
                <th
                  key={value.id}
                  className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="font-semibold text-left">{value.title}</div>
                </th>
              ))}
              {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <span className="sr-only">Menu</span>
              </th> */}
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-gray-200 h-20 overflow-scroll">
            {/* <tbody
              class="bg-grey-light flex flex-col items-center justify-between overflow-y-scroll w-full"
              style={{ height: "50vh" }}
            > */}
            {/* {data == "loading" ? "LOADING" : "TEST"} */}

            {loading ? (
              <div
                className={`w-screen ${
                  fromDashboard == false && "grid min-w-full h-full m-auto"
                }`}
              >
                <div className="m-auto">
                  <Skeleton type="spinner" color="gray" fill="purple" />
                </div>
              </div>
            ) : (
              data
                // .slice(0)
                // .reverse()
                .map((item) => {
                  console.info("DB11 Assessment data Table DATA", item);
                  return (
                    <TableItem
                      data={item}
                      handleClick={handleClick}
                      setModalItem={setModalItem}
                      isChecked={isCheck.includes(item.id)}
                      fromDashboard={fromDashboard}
                      setUserData={props.setUserData}
                    />
                  );
                })
            )}
          </tbody>
        </table>
      </div>
    </div>
    // </div>
    //{" "}
  );
}

export default Table;
