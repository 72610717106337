import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SettingsSidebar from "../../partials/settings/SettingsSidebar";
import AccountPanel from "../../partials/settings/AccountPanel";
import FormPage from "../component/FormPage";
import UsersTable from "../../components/UsersTable";
import UsersPanel from "../../components/UsersPanel";
import UserAddPanel from "../../components/UserAddPanel";
import Billing from "./Billing";
import AssessmentTemplatesPanel from "../../components/AssessmentTemplatesPanel";
import Button from "../../components/Button";
import { _auth } from "../../modules/_auth";

function Account() {
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [panel, setPanel] = useState();
  const settingsTabs = [
    // { id: "account", name: "Account" },
    { id: "users", name: "Users" },
    // { id: "billing", name: "Billing" },
    // { id: "account", name: "Account" },
    { id: "assessment_templates", name: "Templates" },
  ];
  const history = useHistory();
  const profile = _auth.getUserProfile();

  useEffect(() => {
    setPanel(panel);
  }, [panel]);

  useEffect(() => {
    location && location.state
      ? setPanel(location.state.panel)
      : setPanel("users");
  }, []);

  const handleCancel = () => {
    history.push("/manuals");
  };

  return (
    <div
      id="settings"
      className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
    >
      {/* Page header */}
      {/* <div id="settings_header" className="mb-8 ">
        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
          Settings
        </h1>
      </div> */}
      <div
        id="settings_card"
        // style={{ maxHeight: "calc(100vh - 200px)" }}
        className="bg-white shadow-lg rounded-sm border border-gray-200 relative"
      >
        {/* <div
          id="settings_card_header"
          style={{ maxHeight: "calc(100vh - 200px)" }}
          className="relative overflow-scroll"
        >
          Header
        </div> */}
        {/* Content */}
        <div
          style={{
            // maxHeight: "calc(100vh - 225px)",
            // minHeight: "calc(100vh - 225px)",
            maxHeight: "calc(100vh - 150px)",
            minHeight: "calc(100vh - 150px)",
          }}
          className="flex flex-col md:flex-row md:-mr-px"
        >
          <SettingsSidebar
            profile={profile}
            tabname={panel}
            setPanel={setPanel}
            defaultPanel={
              location && location.state ? location.state.panel : "users"
            }
            settingsTabs={settingsTabs}
          />
          {panel == "account" && <AccountPanel />}
          {/* {panel == "users" && <UsersPanel />} */}
          {panel == "users" && <UsersPanel profile={profile} />}
          {panel == "billing" && <Billing />}
          {panel == "assessment_templates" && <AssessmentTemplatesPanel />}
          {/* <AccountPanel /> */}
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-gray-200">
            <div className="flex self-end">
              {/* <button
                onClick={handleCancel}
                className="btn border-gray-200 hover:border-gray-300 text-slate-600"
              >
                Cancel
              </button> */}
              <Button
                color="secondary"
                handleClick={handleCancel}
                // className="btn w-24 bg-primary-500 hover:bg-primary-600 text-white ml-3"
              >
                Done
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Account;
