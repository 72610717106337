/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ListSelector } from "./ListSelector";
import { _assessment } from "../modules/_assessment";
import { usePersistStore, useStore } from "../store/usestore";
import Button from "./Button";
export default function InviteComponent({ done, onModal }) {
  const { guestList, getGuestList } = usePersistStore();
  const { sendAssessment } = useStore();
  const [email, setEmail] = useState("");
  const [assessment_type, setAssessmentType] = useState(null);
  const [assessment_name, setAssessmentName] = useState("");
  const [isGuestExist, setIsGuestExist] = useState(false);
  const [invitedGuests, setInvitedGuests] = useState(guestList);
  const [assessment_names, setAssessmentNames] = useState([]);

  const [inputError, setInputError] = useState("");
  const [people, setPeople] = useState([]);

  useEffect(() => {
    getGuestList();
    setPeople([]);
    setInvitedGuests(guestList);
    setInputError("");
    console.log(guestList, "list");
  }, []);

  useEffect(() => {
    if (invitedGuests.length > 0) {
      let checkTypeExist = invitedGuests.filter(
        (value, i) => value.assessment_type === assessment_type
      );
      console.log(checkTypeExist, "exist");
      if (checkTypeExist.length > 0) {
        setIsGuestExist(true);
      } else {
        setIsGuestExist(false);
      }
    }
  }, [assessment_type, invitedGuests]);

  useEffect(() => {
    (async () => {
      const assessmentNames = await _assessment.getTemplates();
      setAssessmentNames(assessmentNames);
    })();
  }, []);

  console.log(done, "done");
  console.log(guestList, "guests");
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const onDone = () => {
    // e.preventDefault();
    onModal();
    // e.stopPropagation();
    sendAssessment(people, assessment_type);
    getGuestList();
    setPeople([]);
    setInvitedGuests([...invitedGuests, ...people]);
    setAssessmentType(null);
    setAssessmentName("");
  };
  const handleClick = (e, a) => {
    console.log("handleClick", a);
    e.preventDefault();
    e.stopPropagation();
    setAssessmentType(a.key);
    setAssessmentName(a.name);
  };

  const onInvited = (email) => {
    const newList = [];
    newList.push({ email });
    console.log("me");
    setEmail("");
    setPeople([...newList, ...people]);
  };

  const onAdd = (e) => {
    console.log(email, "emailll");
    e.preventDefault();
    e.stopPropagation();
    const newList = [];

    if (email.length > 0 && validateEmail(email)) {
      let checkLocalExist = people.find((value) => {
        return value.email.toString() === email.toString();
      });

      if (!checkLocalExist) {
        newList.push({ email });
        console.log("me");
        setEmail("");
        setPeople([...newList, ...people]);
      } else {
        setInputError("You have already Invited this email");
        setEmail("");
        checkLocalExist = [];
      }
    } else {
      console.log("you");
      setEmail("");
      setInputError("Email is not valid");
    }
  };

  console.log(inputError, "error");

  const onCancel = () => {
    onModal();
    setPeople([]);
    setAssessmentType("");
    // onModal();
  };

  const onRemove = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    people.splice(i, 1);
    setPeople([...people]);
  };

  return (
    <>
      <div className="mx-auto max-w-lg">
        <div>
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-slate-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <h2 className="mt-2 text-lg font-medium text-slate-900">
              Invite others to take this assessment
            </h2>
            <p className="mt-1 text-sm text-slate-500">
              You can send this assessment to as many people as you want. Once
              you send it, you can track who's taken it in the Assessments
              table.
            </p>
          </div>

          {!assessment_type ? (
            <div className="mt-6">
              <ListSelector handleClick={handleClick} data={assessment_names} />
            </div>
          ) : (
            <div className="mt-6">
              <form action="#" className="mt-6 flex">
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setInputError("");
                    setEmail(e.target.value);
                  }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  placeholder="Enter an email"
                />

                <Button
                  type="submit"
                  handleClick={onAdd}
                  color="lime"
                  // size="sm"
                  className="btn-sm ml-4 flex-shrink-0 text-sm font-medium text-white shadow-sm "
                >
                  Add to Invite List
                </Button>
              </form>
              <div>
                {inputError.length > 0 && (
                  <span className="text-sm font-medium text-red-900">
                    {inputError}
                  </span>
                )}
              </div>
              <div className="mt-10">
                {people.length > 0 && (
                  <>
                    <h3 className="text-sm font-medium text-slate-500">
                      Invite List
                    </h3>
                    <ul
                      role="list"
                      className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200 mb-10"
                    >
                      {people.map((person, personIdx) => (
                        <li
                          key={personIdx}
                          className="flex items-center justify-between space-x-3 py-4"
                        >
                          <div className="flex min-w-0 flex-1 items-center space-x-3">
                            <div className="flex-shrink-0">
                              {/* <img
                          className="h-10 w-10 rounded-full"
                          src={person.imageUrl}
                          alt=""
                        /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <div className="min-w-0 flex-1">
                              <p className="truncate text-sm font-medium text-slate-900">
                                {person.email}
                              </p>
                              <p className="truncate text-sm font-medium text-slate-500">
                                {person.role}
                              </p>
                            </div>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              onClick={(e) => onRemove(e, personIdx)}
                              className="inline-flex h-7 items-center rounded-full border border-transparent bg-gray-100 py-2 px-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                            >
                              {/* <PlusIcon
                          className="-ml-1 mr-0.5 h-5 w-5 text-slate-400"
                          aria-hidden="true"
                        /> */}
                              <span className="text-sm font-medium text-slate-900">
                                {" "}
                                Remove{" "}
                                <span className="sr-only">
                                  {person.name}
                                </span>{" "}
                              </span>
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {invitedGuests.length > 0 && (
                  <>
                    <div>
                      {isGuestExist && (
                        <span className="text-sm font-medium text-slate-900 text-center mb-10 mt-10">
                          Recently Invited
                        </span>
                      )}
                      <ul
                        role="list"
                        className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
                      >
                        {invitedGuests.map((person, personIdx) => (
                          <>
                            {person.assessment_type === assessment_type && (
                              <li
                                key={personIdx}
                                className="flex items-center justify-between space-x-3 py-4"
                              >
                                <div className="flex min-w-0 flex-1 items-center space-x-3">
                                  <div className="flex-shrink-0">
                                    {/* <img
                              className="h-10 w-10 rounded-full"
                              src={person.imageUrl}
                              alt=""
                            /> */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <p className="truncate text-sm font-medium text-slate-900">
                                      {person.email}
                                    </p>
                                    <p className="truncate text-sm font-medium text-slate-500">
                                      {person.role}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <button
                                    onClick={() => onInvited(person.email)}
                                    type="button"
                                    className="inline-flex items-center rounded-full border border-transparent bg-gray-100 py-2 px-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                  >
                                    <PlusIcon
                                      className="-ml-1 mr-0.5 h-5 w-5 text-slate-400"
                                      aria-hidden="true"
                                    />
                                    <span className="text-sm font-medium text-slate-900">
                                      {" "}
                                      Invite{" "}
                                      <span className="sr-only">
                                        {person.name}
                                      </span>{" "}
                                    </span>
                                  </button>
                                </div>
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-gray-200">
        <div className="flex flex-wrap justify-end space-x-2">
          <Button
            color="white"
            // className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
            handleClick={(e) => {
              // e.stopPropagation()
              // showHideShareModal();
              // setDone(!done)
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={people.length === 0}
            // className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
            handleClick={(e) => {
              // e.stopPropagation()
              // showHideShareModal();
              // setDone(!done)
              onDone(e);
            }}
          >
            Send Invitation
          </Button>
          {/* <button className="btn-sm bg-primary-500 hover:bg-primary-600 text-white">
              I Understand
            </button> */}
        </div>
      </div>
    </>
  );
}
