import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Logo from "../images/logo.png";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import { usePersistStore } from "../store/usestore";

function ResetPassword() {
  const mvp = false;
  const history = useHistory();
  const [email, setEmail] = useState();
  // const [isAuthenticated, setIsAuthenticated] = useState();
  const [loader, setLoader] = useState(false);

  const { isSuccess, forgotPasswordAsync, error, loginError, isAuthenticated } =
    usePersistStore();
  console.log(error, "error");
  const forgotPassword = async (event) => {
    setLoader(true);
    event.preventDefault();
    // let profile = await _auth.forgotPassword(email,history)
    forgotPasswordAsync(email, history);
    if (isSuccess) {
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="max-w-sm mx-auto px-4">
              <img className="max-w-18 mx-auto px-4 py-8" src={Logo} />
            </div>
            <div className="max-w-sm mx-auto px-4 ">
              <h1 className="text-2xl text-slate-800 font-bold mb-6">
                Forgot Password
              </h1>
              {/* Form */}
              <form onSubmit={forgotPassword}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Registerd Email
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      type="email"
                      autoComplete="on"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  {loader ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading.."
                      />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      class="btn bg-primary-500 hover:bg-primary-600 text-white ml-3"
                      // onClick={auth}
                    >
                      Verify Email
                    </button>
                  )}

                  {/* After login and MFA, check to see if onboarding has completed or 
            if the user has checked the "Don't show this wizard again" checkbox.
            If the user has not checked the do not show again box and has no completed onboarding, redirect to the onboarding page.
             */}
                  {/* <Link
                    className="btn bg-primary-500 hover:bg-primary-600 text-white ml-3"
                    to="/onboard"
                  >
                    Sign In
                  </Link> */}
                </div>
              </form>
              {loginError === true && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  Not Registered
                </div>
              )}
              {/* Footer */}
              {/* <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-primary-500 hover:text-primary-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
