import React from "react";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";

export default function StackedListTwoColumn(props) {
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState(props.type);
  React.useEffect(() => {
    setData(props.data);
    console.info("StackedListTwoColumn", data);
  }, [props.data]);

  React.useEffect(() => {
    setType(props.type);
  }, [props.type]);

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data.map((item) => (
          <li key={item.id}>
            <a href="#" className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="truncate text-sm font-medium text-accent-600">
                    {item.tenantName}
                  </p>
                  <div className="ml-2 flex flex-shrink-0">
                    <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      {type === "competencies" && item.setMaturityLevel}
                      {type === "burden" &&
                        item.resolvedBurden + " hrs/wk resolved "}
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center justify-between text-sm text-gray-500">
                      {/* <UsersIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      /> */}
                      {type === "competencies" &&
                        item &&
                        item.setMaturityScore !== undefined &&
                        "Score: " + item.setMaturityScore.toFixed(2)}
                      {type === "burden" &&
                        item.totalImpediments + " impediments"}
                    </p>
                    {/* <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                     
                      {type == "burden" && "Effort: " + item.effort}
                    </p> */}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    {/* <CalendarIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    /> */}
                    {type == "burden" &&
                      item.remainingBurden + " remaining burden"}
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
