import React, { useState, useEffect, useMemo, useContext } from "react";
import Sidebar from "../partials/Sidebar";
import NarrowSidebar from "../components/NarrowSidebar";
import Header from "../partials/Header";
import { useHistory } from "react-router-dom";
import { _auth } from "../modules/_auth";
import ToastNotification from "../components/ToastNotification";
import { _navigation } from "../modules/_navigation";
import { Store } from "./store";
import BottomNav from "../components/BottomNav";

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [noChange, setNoChange] = useState(false);
  let history = useHistory();
  const profile = _auth.getUserProfile();
  // const role = useMemo(() => profile.role, [role]);
  useEffect(() => {
    _auth.getAuth(history);
  }, []);

  // const navigation = useMemo(
  //   () => _navigation.getNavLinksByRole(profile.role),
  //   [profile]
  // );

  const navigation = useMemo(
    () => _navigation.getNavLinksByTenantType(profile.tenantInfo.type),
    [profile]
  );

  const showHome = useMemo(() => profile.showHome, [profile.showHome]);

  const setSidebarExpanded = (e) => {
    e.preventDefault();
    setSidebarOpen(true);
  };
  const setSidebarClosed = (e) => {
    e.preventDefault();
    setSidebarOpen(false);
  };

  // useEffect(() => {
  //   _auth.getAuth(history);
  //   let profile = _auth.getUserProfile();
  //   setProfile(profile);
  //   // setShowHome(profile.showHome ? profile.showHome : true);
  //   setShowHome(profile.showHome);
  //   console.log("profile in layout showHome", profile);
  //   // const navigation = _navigation.getNavLinksByRole(profile.role);
  //   // setNavigation(navigation);
  //   console.info(" showHome", profile.showHome);
  // }, []);

  // useEffect(() => {
  //   const navigation = _navigation.getNavLinksByRole(profile.role);
  //   setNavigation(navigation);
  //   console.info("navigation in layout", JSON.stringify(navigation));
  // }, [profile]);

  return (
    <div className="relative flex h-screen overflow-y-hidden">
      <Store>
        <Sidebar
          showHome={showHome}
          profile={profile}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          setSidebarClosed={setSidebarClosed}
          navigation={navigation}
        />
        <NarrowSidebar
          showHome={showHome}
          profile={profile}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigationItems={navigation}
        />
        {/* Content area */}
        <div className=" pb-28 flex flex-col flex-1 overflow-y-auto">
          {/*  Site header */}
          <Header
            showHome={showHome}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarExpanded}
            setSidebarClosed={setSidebarClosed}
            navigation={navigation}
          />

          <main>{children}</main>
          <footer
            className="fixed bottom-0 md:hidden lg:hidden xl:hidden sm:bg-slate-900 w-full"
            aria-labelledby="footer-heading"
          >
            <BottomNav
              showHome={showHome}
              profile={profile}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              navigationItems={navigation}
            />
          </footer>
        </div>
      </Store>
    </div>
  );
};

export default Layout;
