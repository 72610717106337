import { _auth } from "./_auth";
import { features } from "./_features";
// const profile = _auth.getUserProfile();
// const showHome = profile.showHome;

import { _stripe } from "./_stripe";

export const _navigation = {
  getHeaderLinks(showHome) {
    console.info("NAVIGATION SHOW HOME", showHome);
    // console.info("NAVIGATION SHOW HOME", showHome);
    const navigation = [
      {
        name: "Home",
        href: "/home",
        current: true,
        icon: "home",
        iconify: "heroicons-outline:home",
        show: true, //showHome == true ? true : false,
      },
      {
        name: "My Dashboard",
        href: "/dashboard",
        current: true,
        icon: "dashboard",
        iconify: "mdi:graph-box-outline",
        show: true,
      },
      {
        name: "My Team",
        href: "/dashboard1",
        current: true,
        icon: "employees",
        iconify: "material-symbols:groups-rounded",
        show: false,
      },
      {
        name: "Competencies",
        href: "/competencies",
        current: false,
        show: true,
        icon: "assessment",
        iconify: "fluent:contact-card-ribbon-24-regular", //"fluent:reward-12-regular", // "material-symbols:featured-play-list-outline-rounded",
        status: "prod",
      },

      {
        name: "Impediments",
        href: "/impediments",
        current: false,
        show: true,
        icon: "marketplace",
        iconify: "fluent:slow-mode-24-regular",
        status: "prod",
      },

      // {
      //   name: "Learning",
      //   href: "/learninghub",
      //   current: false,
      //   icon: "learning",
      //   show: false,
      //   status: "prod",
      // },

      // {
      //   name: "Assessments",
      //   href: "/assessmenthub",
      //   current: false,
      //   icon: "assessment",
      //   show: false,
      //   status: "prod",
      // },

      {
        name: "Assessments",
        href: "/assessments",
        current: false,
        icon: "assessment",
        iconify: "ph:exam",
        show: false,
        status: "prod",
      },
      {
        name: "Action Plans",
        href: "/actionplans",
        current: false,
        icon: "assessment",
        iconify: "icons8:spy",
        show: false,
        status: "prod",
      },
      // {
      //   name: "Employees",
      //   href: "/employees",
      //   current: false,
      //   icon: "employees",
      //   show: true, // features.isEntitled("Employees"),
      //   status: features.getStatus("Employees"),
      // },

      // {
      //   name: "Marketplace",
      //   href: "/marketplace",
      //   current: false,
      //   icon: "marketplace",
      //   show: false, // features.isEntitled("Marketplace"),
      //   status: features.getStatus("Marketplace"),
      // },
      // {
      //   name: "Knowledge Center",
      //   href: "/learn",
      //   current: false,
      //   icon: "learning",
      //   show: false, // features.isEntitled("Knowledge Center"),
      //   status: features.getStatus("Knowledge Center"),
      // },
      // {
      //   name: "Community",
      //   href: "/community",
      //   current: false,
      //   icon: "community",
      //   show: false,
      //   status: "coming soon",
      // },
      // {
      //   name: "Education",
      //   href: "/analytics",
      //   current: false,
      //   icon: "learning",
      //   show: false,
      //   status: "coming soon",
      // },
      // {
      //   name: "Get Help",
      //   href: "/contact",
      //   current: false,
      //   icon: "support",
      //   show: false,
      //   status: "",
      // },
    ];
    return navigation;
  },
  getAdminLinks() {
    // console.info("NAVIGATION SHOW HOME", showHome);
    const navigation = [
      // {
      //   name: "Home",
      //   href: "/home",
      //   current: true,
      //   icon: "home",
      //   iconify: "heroicons-outline:home",
      //   show: true,
      // },
      // {
      //   name: "Global Dashboard",
      //   href: "/dashboard",
      //   current: true,
      //   icon: "dashboard",
      //   iconify: "mdi:graph-box-outline",
      //   show: true,
      // },
      {
        name: "Manuals",
        href: "/manuals",
        current: false,
        show: true,
        icon: "assessment",
        iconify: "fluent:contact-card-ribbon-24-regular", //"fluent:reward-12-regular", // "material-symbols:featured-play-list-outline-rounded",

        status: "prod",
      },

      // {
      //   name: "Teams",
      //   href: "/teams",
      //   current: false,
      //   show: true,
      //   icon: "assessment",
      //   iconify: "ant-design:team-outlined",
      //   status: "prod",
      // },
      // {
      //   name: "Competencies",
      //   href: "/competencies",
      //   current: false,
      //   show: true,
      //   icon: "assessment",
      //   iconify: "fluent:contact-card-ribbon-24-regular", //"fluent:reward-12-regular", // "material-symbols:featured-play-list-outline-rounded",

      //   status: "prod",
      // },

      // {
      //   name: "Impediments",
      //   href: "/impediments",
      //   current: false,
      //   show: true,
      //   icon: "marketplace",
      //   iconify: "fluent:slow-mode-24-regular",
      //   status: "prod",
      // },

      {
        name: "Assessments",
        href: "/assessments",
        current: false,
        icon: "assessment",
        iconify: "ph:exam",
        show: true,
        status: "prod",
      },
      // {
      //   name: "Audit",
      //   href: "/audit",
      //   current: false,
      //   icon: "assessment",
      //   iconify: "icons8:spy",
      //   show: true,
      //   status: "prod",
      // },
    ];
    return navigation;
  },
  getNavLinksByRole(role) {
    console.log("GetNavLinksByRole", role);
    if (role == "Administrator" || role == "Super Admin") {
      return this.getAdminLinks();
    }
    if (role == "Manager" || role == "Collaborator") {
      return this.getHeaderLinks();
    }
    if (role == "Observer") {
      return this.getAdminLinks();
    }
    return this.getHeaderLinks();
  },
  getNavLinksByTenantType(tenantType) {
    if (tenantType == "admin") {
      return this.getAdminLinks();
    }
    if (tenantType == "team") {
      return this.getHeaderLinks();
    }

    return this.getHeaderLinks();
  },
};
