import { features } from "./_features";

export const utils = {
  formatUnicode: (str) => {
    console.log("Formating unicode string", str);
    const newStr = str.replace(/\\u[\dA-F]{4}/gi, (match) => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });
    //escape unicode
    const newStr2 = str.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
      console.log("Formating unicode string", i);
      return `\\` + i.charCodeAt(0) + ";";
    });

    console.log("Formated unicode string", newStr2);
    return newStr;
  },
  cacheData(cacheName, response) {
    if (cacheName) {
      cacheName = cacheName;
    } else {
      cacheName = "cacheData";
    }
    const data = new Response(JSON.stringify(response));

    if ("caches" in window) {
      let url = window.location.origin;

      // Opening given cache and putting our data into it
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  },
  getCacheData: async (key, cache) => {
    console.log("getCacheData --> Checking cache ", key, cache);

    if (!key) {
      key = "tier";
      console.log("getCacheData --> No key provided, setting key", key, cache);
    }

    const cacheName = cache;
    const url = window.location.origin;
    console.log("getCacheData Window location", window.location.origin);
    if (typeof caches === "undefined" || typeof caches === undefined) {
      console.log("getCacheData --> !!NO CACHE AVAILABLE!!, return false");
      return false;
    }
    console.log("getCacheData --> Opening cache", cacheName, url);
    let cacheStorage = await caches.open(cacheName);
    let cachedResponse = await cacheStorage.match(url);

    // If no cache exists
    if (!cachedResponse || !cachedResponse.ok) {
      console.log(
        "getCacheData --> NO CACHE EXISTS FOR URL",
        url,
        " GETTING CACHE FROM SERVER"
      );
      // console.log("getCacheData --> GETTING CACHE FROM SERVER");
      let f = features.featureFlags();
      if (f) {
        console.log(
          "getCacheData --> Got feature flags from server, checking cache again"
        );
        cacheStorage = await caches.open(cacheName);
        cachedResponse = await cacheStorage.match(url);
      }
    }

    if (cacheName == "Stripe") {
      console.log("getCacheData --> stripe cached response", cachedResponse);
      const t = await cachedResponse.json();
      if (t) {
        const tier = t[key];
        if (tier) {
          return tier;
        } else {
          return null;
        }
      }
    } else {
      //not stripe
      console.log(
        "getCacheData --> not stripe, cached response",
        cachedResponse
      );
      if (cachedResponse) {
        console.log("getCacheData --> getting cached response.json()");
        const t = await cachedResponse.json();

        if (t) {
          console.log(
            "getCacheData --> got cached response.json(), returning",
            t
          );
          return t;
        }
      }
    }
    return false;
  },

  checkCache: async (cacheName) => {
    return caches.has(cacheName);
  },

  setLocalStorage(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
    const updated_value = JSON.parse(window.localStorage.getItem(key));
    console.info(key, updated_value);
    // console.info(key, JSON.parse(updated_value));
    return updated_value;
  },
  getLocalStorage(key) {
    var value = JSON.parse(window.localStorage.getItem(key));

    // if (value === null || value === "undefined" || value === "") {
    //   value = [];
    // } else {
    //   value = JSON.parse(value);
    // }

    return value;
  },
  list() {
    let domains = ["0-mail.com"];
    return domains;
  },
};
