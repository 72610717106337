/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useState, useEffect, useRef } from "react";
import {
  KeyIcon,
  UserCircleIcon,
  CreditCardIcon,
  UserGroupIcon,
  PlusIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import Uploader from "./Uppy";
// import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { CalendarIcon, MegaphoneIcon } from "@heroicons/react/24/outline";
import AssessmentTemplateQuestionEditor from "./AssessmentTemplatesQuestEditor";
import { _assessment } from "../modules/_assessment";
import Button from "./Button";
import NotificationBanner from "./NotificationBanner";
import MultiSelectListBox from "./MultiSelectListBox";
import { _marketplace } from "../modules/_marketplace";
import RadioGroupCards from "./RadioGroupCards";

import { CheckIcon, TrashIcon } from "@heroicons/react/20/solid";
import Skeleton from "./Skeleton";
import VerticalAlignContainer from "./VerticalAlignContainer";
import EmptyButton from "./EmptyButton";
import Dialog from "./Dialog";
import DialogBox from "./Dialog";
import { qList } from "../modules/_assessment_questions";
const items = [
  {
    name: "Save Draft",
    description: "Save this template but don't make it available to users",
    href: "#",
    iconColor: "bg-pink-500",
    icon: CalendarIcon,
    status: "draft",
  },
  {
    name: "Publish Template",
    description: "Save this template and make it visible to users.",
    href: "#",
    iconColor: "bg-purple-500",
    icon: CreditCardIcon,
    status: "published",
  },
  // {
  //   name: "Delete Template",
  //   description: "Delete this template and all the related questions.",
  //   href: "#",
  //   iconColor: "bg-yellow-500",
  //   icon: TrashIcon,
  //   status: "deleted",
  // },
];
const navigation = [
  {
    name: "Details",
    href: "#",
    icon: UserCircleIcon,
    current: true,
  },
  { name: "Questions", href: "#", icon: KeyIcon, current: false },
  // { name: "Plan & Billing", href: "#", icon: CreditCardIcon, current: false },
  // { name: "Team", href: "#", icon: UserGroupIcon, current: false },
  { name: "Publish", href: "#", icon: PlusIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AssessmentTemplateCreator(props) {
  const [selectedTab, setSelectedTab] = useState(navigation[0].name);
  const [blurbs, setBlurbs] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [prevScrollposState, setPrevScrollpos] = useState();
  const [item, setItem] = useState(props.modalItem);
  const create = props.createNew;
  const [newObject, setNewObject] = useState(props.modalItem);
  const [changed, setChanged] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [textAreaHeights, setTextAreaHeights] = useState({});
  const [statusLoading, setStatusLoading] = useState();
  const [success, setSuccess] = useState(false);
  const [manualQuestions, setManualQuestions] = useState(true);
  const [addQuestionOption, setAddQuestionOption] = useState(null);
  const [confirmBox, setConfirmBox] = useState(false);
  const [errorMessageBox, setErrorMessageBox] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [questions, setQuestions] = useState([{}]);

  const addQuestionRef = useRef(null);

  const handleScroll = (ref) => {
    // const currentScrollPos = window.pageYOffset;
    console.log("window", ref);
    ref.scrollIntoView();
    // window.scrollTo({
    //   top: ref.offsetTop,
    //   left: 0,
    //   behavior: "smooth",
    // });
  };

  useEffect(() => {
    (async () => {
      const questions = await qList.GetQuestions(item.key ? item.key : null);
      if (questions) {
        console.log("questions", questions);
        setTotalQuestions(questions.length);
        setQuestions(questions);
      }
    })();
  }, []);

  const refreshQuestions = async () => {
    const questions = await qList.GetQuestions(item.key ? item.key : null);
    if (questions) {
      console.log("questions refreshed", questions);
      setTotalQuestions(questions.length);
      setQuestions(questions);
      setAddQuestionOption("manual");
    }
  };

  useEffect(() => {
    //this may not be necessary anymore
    const blurbs =
      create == true ? props.modalItem["blurbs"] : props.modalItem["blurbs"];

    setBlurbs(blurbs);
    console.log("ITEMMMM", blurbs);
    setLoading(false);
  }, []);

  useEffect(() => {
    const newitem = props.modalItem;
    setItem(newitem);
  }, [discard]);

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    console.log("newObject Updated", newObject);
    let changed = false;
    if (JSON.stringify(props.modalItem) != JSON.stringify(newObject)) {
      changed = true;
    } else {
      changed = false;
    }
    setChanged(changed);
  }, [newObject]);

  const checkIfChanged = (val, val1) => {
    if (val != val1) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelect = (e, fieldName) => {
    console.log("handleFieldChange handleSelect", e, fieldName);
    handleFieldChange(e, "multiple", fieldName);
  };

  const handleDeselect = (e) => {
    console.log("handleDeselect", e);
  };

  const handleFieldChange = (e, index, fieldName) => {
    // console.log("handleFieldChange", e.target, index);

    if (index == "multiple") {
      console.log("handleFieldChange", e, index, fieldName);
      let newState = { ...newObject };
      newState[fieldName] = e;
      setNewObject(newState);

      console.log("handleFieldChange audience", newObject);
      return;
    }

    if (index == "single") {
      console.log("handleFieldChange", e, index, fieldName);
      let newState = { ...newObject };
      newState[fieldName] = e;
      setNewObject(newState);
      return newState;
    }

    let { name, value, type, f } = e.target;

    if (name.substring(0, 6) == "blurb-") {
      name = name.substring(6);
      let newState = { ...newObject };
      // newState["blurbs"][name] = value;
      setNewObject((newState) => {
        return { ...newState, blurbs: { ...newState.blurbs, [name]: value } };
      });
      console.log("handleFieldChange blurb", name);
      return;
    }

    if (type == "checkbox") {
      setNewObject((prev) => ({
        ...prev,
        [name]: e.target.checked,
      }));
      return;
    }

    setNewObject({ ...newObject, [name]: value });

    let oldState = { ...newObject };
    let changed = checkIfChanged(oldState, newObject);
    setChanged(changed);
  };

  const handleDiscardChanges = () => {
    console.log(
      "Discarding changes - setting New object and Item to props.modalItem"
    );
    setNewObject(props.modalItem);

    setItem(props.modalItem);
    setChanged(false);
    setDiscard(!discard);
  };

  const handleSaveNewObject = async () => {
    //check newObject for a "key" property
    //if a key is missing, or is "" then return an error
    //if a key is present, then save the object

    if (!newObject.key || newObject.key == "") {
      showErrorMessageBox("Please enter a key");
      return;
    } else {
      console.log("handleSaveNewObject", newObject);
      setLoading(true);
      let updated;
      if (create) {
        updated = await _assessment.saveTemplate(newObject);
        props.setCreateNew(false);
        console.log("handleSaveNewObject created", updated);
      } else {
        updated = await _assessment.updateTemplate(newObject);
      }
      if (updated) {
        console.log("handleSaveNewObject Success", updated);
        setSuccess(true);
        console.log("handleSaveNewObject updating modal item", updated);
        props.updateModalItem(updated);
        console.log("handleSaveNewObject updating newObject", updated);
        setNewObject(updated);
        console.log("handleSaveNewObject saving changed to false", updated);
        setChanged(false);
      } else {
        console.log("handleSaveNewObject Error", updated);
        setSuccess(false);
      }
      setLoading(false);
      console.log("handleSaveNewObject Saved", updated);
      // return updated;
    }
  };

  const handleDeleteAssessmentClick = () => {
    console.log("handleDeleteAssessmentClick", newObject);
    showHideConfirmBox();
  };

  const handleDeleteAssessment = async () => {
    console.log("hanldeDeleteAssessment", newObject);
    setLoading(true);

    // let deleted = true;

    let deleted = await _assessment.deleteTemplate(newObject);
    if (deleted) {
      console.log("hanldeDeleteAssessment Success", deleted);
      setSuccess(true);
      props.updateModalItem({});
      props.showHideModal();
    }
    setLoading(false);
    console.log("hanldeDeleteAssessment Deleted", deleted);
    // return updated;
  };

  const showHideConfirmBox = () => {
    setConfirmBox(!confirmBox);
  };

  const showErrorMessageBox = (msg) => {
    setErrorMessageBox(true);
  };

  const handleErrorMessageBox = () => {
    setErrorMessageBox(false);
  };

  useEffect(() => {
    console.log("statusLoading", statusLoading);
  }, [statusLoading]);

  const handlePublishOptionClick = async (e) => {
    //add e to statusLoading array
    setStatusLoading(e);
    let index = "single";
    let fieldName = "status";
    let newState = handleFieldChange(e, index, fieldName);
    console.log("handlePublishOptionClick newObject", newState);

    let updated = await _assessment.updateTemplate(newState);
    setNewObject(updated);
    props.updateModalItem(updated);
    setTimeout(() => {
      // if (updated) {
      setSuccess(true);
      setStatusLoading("");
      setChanged(false);
      // setItem(updated);
      // }
    }, 300);
    //remove e from statusLoading array
  };

  const isPublishOptionLoading = (e) => {
    return statusLoading == e;
  };

  return (
    <div id="assessment-creator-container" className="lg:grid lg:grid-cols-12 ">
      <aside
        id="creator-sidebar"
        className="border-r p-4 lg:col-span-3 2xl:col-span-2 lg:py-2 lg:px-4 "
      >
        <nav className="space-y-1">
          {navigation.map((navItem, index) => (
            //add a tooltip around the button for disabled items
            <button
              key={navItem.name}
              name={navItem.name}
              disabled={!newObject._id && index != 0}
              onClick={() => {
                handleSetSelectedTab(navItem.name);
              }}
              className={classNames(
                selectedTab === navItem.name &&
                  "bg-gray-50 text-primary-700 hover:text-primary-700 hover:bg-white",
                selectedTab !== navItem.name &&
                  newObject._id &&
                  "text-slate-900 hover:text-slate-900 hover:bg-gray-50",
                !newObject._id && index !== 0
                  ? "text-slate-300 hover:text-slate-300 hover:bg-white cursor-not-allowed"
                  : null,
                "w-full group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
              )}
              aria-current={navItem.current ? "page" : undefined}
            >
              <navItem.icon
                className={classNames(
                  selectedTab === navItem.name &&
                    "text-primary-500 group-hover:text-primary-500",
                  selectedTab !== navItem.name &&
                    newObject._id &&
                    "text-slate-400 group-hover:text-slate-500",
                  !newObject._id && index !== 0
                    ? "text-slate-300 hover:text-slate-300"
                    : null,

                  "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                )}
                aria-hidden="true"
              />
              <span className="truncate">{navItem.name}</span>
            </button>
          ))}
        </nav>
      </aside>

      <div
        id="creator-content"
        style={{
          maxHeight: "calc(100vh - 150px)",
          minHeight: "calc(100vh - 150px)",
        }}
        className="relative w-full bg-gray-50 
        col-span-10 2xl:col-span-10 lg:col-span-9  overflow-y-auto
        overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full
      "
      >
        {/* Banner */}
        {selectedTab == "Details" && changed && (
          <div className="bg-purple-500 z-50 text-sm shadow-sm sticky top-0  py-2 px-4 mb-8 w-screen max-w-full ">
            <div className="sm:flex sm:justify-between sm:items-center">
              {/* Left Action */}

              <div className="flex flex-wrap justify-center text-white font-md sm:justify-start sm:mb-0 -space-x-3 -ml-px">
                You made changes to this template. Save these changes before
                continuing.
              </div>

              {/* // Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <Button
                  color={"white"}
                  audit={false}
                  handleClick={handleDiscardChanges}
                  className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                >
                  Discard Changes
                </Button>
                <Button
                  color={"lime"}
                  audit={false}
                  handleClick={handleSaveNewObject}
                  className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
        {((selectedTab == "Questions" && addQuestionOption == "import") ||
          (selectedTab == "Questions" && addQuestionOption == "manual") ||
          (selectedTab == "Questions" && addQuestionOption == null)) && (
          <div className="bg-white  z-50 text-sm shadow-sm sticky top-0  py-2 px-4 mb-8 w-screen max-w-full ">
            <div className="sm:flex sm:justify-between sm:items-center">
              {/* Left Action */}

              <div className="flex flex-wrap justify-center text-white font-md sm:justify-start sm:mb-0 -space-x-3 -ml-px">
                Choose an option to add questions.
              </div>

              {/* // Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <Button
                  color={"text"}
                  audit={false}
                  // size="sm"
                  handleClick={() => {
                    addQuestionOption == "manual" ||
                    addQuestionOption == null ||
                    addQuestionOption == ""
                      ? handleScroll(addQuestionRef.current)
                      : setAddQuestionOption("manual");
                  }}
                  // onClick={() => {
                  //   handleScroll(addQuestionRef.current);
                  // }}
                  className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                >
                  Add Question
                </Button>
                <Button
                  color={"text"}
                  // size="sm"
                  audit={false}
                  handleClick={(e) => {
                    e.stopPropagation();
                    setAddQuestionOption("import");
                  }}
                  className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                >
                  Import Questions
                </Button>
              </div>
            </div>
          </div>
        )}
        {/* Content */}
        <div className="px-5 lg:px-8 lg:py-6 h-fit space-y-6 ">
          {selectedTab == "Details" && (
            <>
              <div
                id="menu"
                className="bg-purple-100 sm:overflow-hidden sm:rounded-md"
              >
                <div className="space-y-6  py-6 px-4 sm:p-6">
                  <div>
                    <h3 className="text-lg pb-3 font-medium leading-6 text-slate-900 ">
                      Assessment Template
                    </h3>
                    <p className="mt-1 text-sm text-slate-500">
                      Fill in all the fields below. Once you're finished you can
                      add or upload questions for this template.
                    </p>
                  </div>
                </div>
              </div>
              {/* <form action="#" method="POST"> */}
              {/* Info card*/}
              <div className="shadow space-y-6 mb-6 sm:rounded-md">
                <div className=" bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h3 className="text-lg pb-3 font-medium leading-6 text-slate-900 mb-6 border-b">
                      Assessment Details
                    </h3>
                  </div>

                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 lg:col-span-6 ">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Template Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={newObject.name}
                        defaultValue={create == true ? "" : item.name}
                        onChange={(e) => handleFieldChange(e)}
                        // autoComplete="given-name"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-12 lg:col-span-6">
                      <label
                        htmlFor="key"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Unique Key (required)
                      </label>
                      <input
                        type="text"
                        name="key"
                        id="key"
                        required
                        defaultValue={create == true ? "" : item.key}
                        value={newObject.key}
                        disabled={create == true ? false : true}
                        onChange={(e) => handleFieldChange(e)}
                        // autoComplete="family-name"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      />
                      {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        *
                      </div> */}
                    </div>

                    <div className="col-span-12">
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Short Description
                      </label>
                      <input
                        type="text"
                        name="description"
                        id="description"
                        // autoComplete="email"
                        value={newObject.description}
                        defaultValue={create == true ? "" : item.description}
                        onChange={(e) => handleFieldChange(e)}
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-12 lg:col-span-6 xl:col-span-3">
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Assessment type
                      </label>
                      {/* <select
                        id="type"
                        name="type"
                        autoComplete="type"
                        onChange={(e) => handleFieldChange(e)}
                        defaultValue={create == true ? 0 : item.type}
                        value={newObject.type}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      >
                        <option value="0">Select Type</option>
                        <option value="system">System</option>
                        <option value="custom">Custom</option>
                      </select> */}
                      <MultiSelectListBox
                        options={["System", "Custom"]}
                        fieldType="single"
                        label="Assessment Type"
                        name="type"
                        handleSelect={(e, value) => handleSelect(e, value)}
                        handleDeselect={(e) => handleDeselect(e)}
                        defaultValue={create == true ? null : item.type}
                        value={newObject.type}
                      />
                    </div>
                    <div className="col-span-12 lg:col-span-6 xl:col-span-3">
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Categories
                      </label>
                      {/* <select
                        id="categories"
                        name="categories"
                        autoComplete="type"
                        onChange={(e) => handleFieldChange(e)}
                        defaultValue={create == true ? 0 : item.category}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      >
                        <option value="0">Select Category</option>
                        <option value="risk">Risk</option>
                        <option value="awareness">Awareness</option>
                        <option value="compliance">Compliance</option>
                      </select> */}
                      <MultiSelectListBox
                        options={["Compliance", "Knowledge", "Risk"]}
                        fieldType="single"
                        label="Category"
                        name="category"
                        handleSelect={(e, value) => handleSelect(e, value)}
                        handleDeselect={(e) => handleDeselect(e)}
                        defaultValue={create == true ? null : item.category}
                        value={newObject.category}
                      />
                    </div>
                    <div className="col-span-12 lg:col-span-6 xl:col-span-3">
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Audience
                      </label>

                      <MultiSelectListBox
                        options={[
                          "Third Parties",
                          "IT Managers",
                          "Data & Compliance Managers",
                          "All Employees",
                        ]}
                        fieldType="multiple"
                        label="Audience"
                        name="audience"
                        handleSelect={(e, value) => handleSelect(e, value)}
                        handleDeselect={(e) => handleDeselect(e)}
                        defaultValue={create == true ? null : item.audience}
                        value={newObject.audience}
                      />
                    </div>
                    <div className="col-span-12 lg:col-span-6 xl:col-span-3">
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Marketplace Mapping
                      </label>

                      <MultiSelectListBox
                        options={_marketplace
                          .getMarketplaceTabs()
                          .map((item) => {
                            return item.name != "View All" && item.name;
                          })}
                        fieldType="multiple"
                        label="Marketplace Mapping"
                        name="marketplace_mapping"
                        handleSelect={(e, value) => handleSelect(e, value)}
                        handleDeselect={(e) => handleDeselect(e)}
                        defaultValue={
                          create == true
                            ? null
                            : item.marketpalce_mapping
                            ? item.marketplace_mapping
                            : []
                        }
                        value={newObject.marketplace_mapping}
                      />
                    </div>

                    {/* <legend className="text-base font-medium text-slate-900 mb-3">
                      Introduction
                    </legend> */}

                    <div className="col-span-12">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Introduction screen
                      </label>
                      <p className=" mb-6 col-span-12 text-sm text-slate-500">
                        Use the below section to enter language based on the
                        risk score of the individual. This will appear on the
                        action plan.
                      </p>
                      <textarea
                        rows={5}
                        type="text"
                        name="intro"
                        id="intro-textarea"
                        onChange={(e) => handleFieldChange(e)}
                        // placeholder={create == true ? "Introduction" : null}
                        defaultValue={create == true ? "" : item.intro}
                        className={`mb-6 mt-1 block w-full h-fit rounded-md border border-gray-300 py-2 px-3 
                        shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Action Plan Card */}
              <div className="shadow sm:overflow-hidden sm:rounded-md mb-6">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                  <div className="space-y-6">
                    <h3 className="text-lg pb-3 font-medium leading-6 text-slate-900 border-b">
                      Action Plan
                    </h3>
                    <legend className="text-base font-medium text-slate-900">
                      Action Plan Options
                    </legend>
                    <p className="mt-3 col-span-6 text-sm text-slate-500">
                      Select from an option below to choose what screen you want
                      to show at the end of this assessment.
                    </p>
                  </div>
                  <div className=" text-sm">
                    <RadioGroupCards
                      name="action_plan_type"
                      defaultValue={item.action_plan_type}
                      value={newObject.action_plan_type}
                      onChange={(a, b, c) => handleFieldChange(a, b, c)}
                      options={[
                        {
                          id: 1,
                          title: "Action Plan",
                          description:
                            "This assessment will show an action plan at the end.",
                          value: "action_plan",
                          users: "621 users",
                        },
                        {
                          id: 2,
                          title: "Contact Form",
                          description:
                            "This assessment will show a contact form at the end.",
                          value: "contact_form",
                          users: "1200 users",
                        },
                        {
                          id: 3,
                          title: "Thank You Page",
                          description:
                            "This assessment will show a thank you page at the end.",
                          value: "thank_you_page",
                          users: "2740 users",
                        },
                      ]}
                    />
                  </div>
                  <fieldset>
                    <div className="grid grid-cols-6 gap-6 mt-12">
                      <legend className="text-base font-medium text-slate-900">
                        Action Plan screen
                      </legend>
                      <p className="-mt-3 col-span-6 text-sm text-slate-500">
                        Use the below section to enter language based on the
                        risk score of the individual. This will appear on the
                        action plan.
                      </p>
                      {!loading &&
                        Object.keys(blurbs).map((key, index) => {
                          console.log("blurbs", key);
                          return (
                            <div className="col-span-6 row-span-2 sm:col-span-6">
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-medium text-slate-700"
                              >
                                {key}
                              </label>
                              <textarea
                                rows={4}
                                type="text"
                                name={`blurb-${key}`}
                                id={`blurb-${key}`}
                                onChange={(e) => handleFieldChange(e)}
                                defaultValue={item["blurbs"][key]}
                                // disabled={create == true ? false : true}
                                // autoComplete="family-name"
                                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                              />
                            </div>
                          );
                        })}
                    </div>
                  </fieldset>
                </div>
                {/* <div className="bg-gray-50 px-4 py-3 text-right sm:px-6"></div> */}
              </div>
            </>
          )}
          {selectedTab == "Questions" &&
            questions.length == 0 &&
            // create &&
            addQuestionOption == null && (
              <VerticalAlignContainer className="w-3/4 " noShadow transparent>
                <EmptyButton handleClick={(e) => setAddQuestionOption(e)} />
              </VerticalAlignContainer>
            )}

          {selectedTab == "Questions" &&
            addQuestionOption == "import" &&
            (console.log("props.assessmenttype", newObject.key),
            (
              <Uploader
                refreshQuestions={refreshQuestions}
                assessment_type={newObject.key}
                // setAddQuestionOption={() => setAddQuestionOptionAfterUppy()}
              />
            ))}

          {selectedTab == "Questions" &&
            // questions.length == 0 &&
            addQuestionOption == "manual" && (
              <AssessmentTemplateQuestionEditor
                assessment_type={newObject.key}
                questions={questions}
                addQuestionRef={addQuestionRef}
                refreshQuestions={refreshQuestions}
                setQuestions={setQuestions}
              />
            )}

          {selectedTab == "Questions" &&
            questions.length > 0 &&
            (addQuestionOption == "" || addQuestionOption == null) && (
              //  !create && (
              <AssessmentTemplateQuestionEditor
                assessment_type={newObject.key}
                questions={questions}
                addQuestionRef={addQuestionRef}
                refreshQuestions={refreshQuestions}
                setQuestions={setQuestions}
              />
            )}

          {selectedTab == "Publish" && (
            <div className="flex flex-col p-auto h-full m-auto justify-center items-center content-center  w-full overflow-hidden">
              <div className="p-10  shadow-lg rounded-md bg-white">
                <div className="mx-auto max-w-lg">
                  <h2 className="text-lg font-medium text-slate-900">
                    Save your assessment template
                  </h2>
                  <p className="mt-1 text-sm text-slate-500">
                    Choose from the options below to save your assessment
                    template.
                  </p>
                  <ul
                    role="list"
                    className="mt-6 divide-y divide-gray-200 border-t border-b border-gray-200"
                  >
                    {items.map((it, itemIdx) => (
                      <li
                        key={itemIdx}
                        onClick={() => handlePublishOptionClick(it.status)}
                        className={classNames(
                          "hover:shadow-lg"

                          // `${
                          //   it.status == newObject.status && "bg-gray-100"
                          //   // "outline-none border-none ring-2 ring-offset-2 ring-primary-500"
                          // }`
                          // "hover:shadow-lg"
                        )}
                      >
                        <div className="group relative flex items-start space-x-3 py-4">
                          <div className="flex-shrink-0">
                            <span
                              className={classNames(
                                it.iconColor,
                                "inline-flex items-center justify-center h-10 w-10 rounded-lg"
                              )}
                            >
                              <it.icon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div className="text-sm font-medium text-slate-900">
                              <a href={it.href}>
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                />
                                {it.name}{" "}
                                {newObject.status == it.status &&
                                  (isPublishOptionLoading(it.status) ? (
                                    <span>
                                      <Skeleton
                                        type="spinner"
                                        color="gray"
                                        fill="purple"
                                        className="inline-flex h-6 w-6"
                                        forIcon={true}
                                      />
                                    </span>
                                  ) : (
                                    <CheckIcon
                                      color="green"
                                      className="inline-flex h-6 w-6"
                                    />
                                  ))}
                              </a>
                            </div>
                            <p className="text-sm text-slate-500">
                              {it.description}
                            </p>
                          </div>
                          <div className="flex-shrink-0 self-center">
                            <ChevronRightIcon
                              className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-6 flex">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteAssessmentClick();
                      }}
                      className="text-sm font-medium text-red-600 hover:text-red-500"
                    >
                      Or delete this template
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {confirmBox && (
          <DialogBox
            handleConfirmClick={handleDeleteAssessment}
            loading={loading}
            success={success}
            title={"Delete Assessment Template"}
            message={
              "Are you sure you want to delete this assessment template? Your template and all related questions will be removed. This action cannot be undone."
            }
            OKbuttonText={"Delete"}
            CancelButton={true}
          />
        )}
        {errorMessageBox && (
          <DialogBox
            handleConfirmClick={handleErrorMessageBox}
            loading={loading}
            success={success}
            title={"Error"}
            message={"The unique key field is required."}
            OKbuttonText={"OK"}
            CancelButton={false}
          />
        )}
      </div>
    </div>
  );
}
