import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/20/solid";

const timeline = [
  {
    id: 1,
    content: "Take the Self Assessment",
    target: "Self Assessment",
    href: "",
    date: "Sep 20",
    datetime: "2020-09-20",
    icon: CheckIcon,
    iconBackground: "bg-blue-500",
  },
  {
    id: 2,
    content: "Claim your impediments",
    target: "impediments",
    href: "#",
    date: "Sep 22",
    datetime: "2020-09-22",
    icon: CheckIcon,
    iconBackground: "bg-blue-500",
  },
  {
    id: 3,
    content: "Associate your impediments with competencies",
    target: "Martha Gardner",
    href: "#",
    date: "Sep 28",
    datetime: "2020-09-28",
    icon: CheckIcon,
    iconBackground: "bg-blue-500",
  },
  {
    id: 4,
    content: "Estimate your burden and effort",
    target: "Bethany Blake",
    href: "#",
    date: "Sep 30",
    datetime: "2020-09-30",
    icon: CheckIcon,
    iconBackground: "bg-blue-500",
  },
  {
    id: 5,
    content: "Review the competencies and add new impediments",
    target: "Katherine Snyder",
    href: "#",
    date: "Oct 4",
    datetime: "2020-10-04",
    icon: CheckIcon,
    iconBackground: "bg-blue-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GettingStarted() {
  return (
    <div
      className={`col-span-full lg:col-span-3  shadow-lg border rounded-lg row-span-1  bg-white p-2  overflow-hidden grow-0`}
    >
      <header className="flex items-center justify-between  px-5 py-4 border-b border-gray-100">
        <h2 className="  font-semibold text-slate-800">Getting Started</h2>
      </header>

      <div className="flow-root ">
        <ul role="list" className="-mb-8">
          {timeline.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== timeline.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        event.iconBackground,
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      <event.icon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">
                        {event.content}{" "}
                        {/* <a
                          href={event.href}
                          className="font-medium text-gray-900"
                        >
                          {event.target}
                        </a> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>

    // <div className="bg-white shadow p-6 overflow-hidden sm:rounded-lg">
    //   <div className="flow-root ">
    //     <ul role="list" className="-mb-8">
    //       {timeline.map((event, eventIdx) => (
    //         <li key={event.id}>
    //           <div className="relative pb-8">
    //             {eventIdx !== timeline.length - 1 ? (
    //               <span
    //                 className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
    //                 aria-hidden="true"
    //               />
    //             ) : null}
    //             <div className="relative flex space-x-3">
    //               <div>
    //                 <span
    //                   className={classNames(
    //                     event.iconBackground,
    //                     "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
    //                   )}
    //                 >
    //                   <event.icon
    //                     className="h-5 w-5 text-white"
    //                     aria-hidden="true"
    //                   />
    //                 </span>
    //               </div>
    //               <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
    //                 <div>
    //                   <p className="text-sm text-gray-500">
    //                     {event.content}{" "}
    //                     {/* <a
    //                       href={event.href}
    //                       className="font-medium text-gray-900"
    //                     >
    //                       {event.target}
    //                     </a> */}
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </li>
    //       ))}
    //     </ul>
    //   </div>
    // </div>
  );
}
