import React, { useState } from "react";
import { useEffect } from "react";
import DeleteButton from "../partials/actions/DeleteButton";
import DateSelect from "../components/DateSelect";
import FilterButton from "../components/DropdownFilter";
// import OrdersTable from "../partials/employees/EmployeesTable";
import PaginationClassic from "../components/PaginationClassic";
import { useHistory } from "react-router-dom";
import Image01 from "../images/user-40-01.jpg";
import Image02 from "../images/user-40-02.jpg";
import Image03 from "../images/user-40-03.jpg";
import Image04 from "../images/user-40-04.jpg";
import Image05 from "../images/user-40-05.jpg";
import Image06 from "../images/user-40-06.jpg";
import Image07 from "../images/user-40-07.jpg";
import Image08 from "../images/user-40-08.jpg";
import Image09 from "../images/user-40-09.jpg";
import Image10 from "../images/user-40-10.jpg";
// import Table from "../components/Table";
import { _employees } from "../modules/_employees";
import Table, {
  SelectColumnFilter,
  GlobalFilter,
} from "../components/ReactTable";

import Button from "../components/Button";
import { isConstructorDeclaration } from "typescript";
import { FeatureFlag } from "../components/FeatureFlag";
import { CloudUploadIcon } from "@heroicons/react/24/outline";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
function Orders() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const history = useHistory();

  const RenderActions = (props) => {
    // console.log("RenderActions", props.data);
    return (
      <div className="flex items-center space-x-3">
        <Button
          size="sm"
          rounded
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={() => viewDetails(props.row.original, props.data)}
        >
          <svg
            className="w-4 h-4 mr-2 -ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>View</span>
        </Button>
      </div>
    );
  };

  //get employees from api

  useEffect(() => {
    (async () => {
      let employeeList = await _employees.getEmployeesByTenantId();
      if (employeeList) {
        // console.log("ViewDetails employeeList", employeeList);
        setEmployees(employeeList);
        setList(employeeList);
        setLoading(false);
      }
    })();
  }, []);

  const viewDetails = (row, list) => {
    // console.log("ViewDetails", list);
    let state = { data: row, list: list };
    let path = "/employees/profile";
    return history.push({
      pathname: path,
      state: state,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Title",
        accessor: "Title",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Role",
        accessor: "Role",
        Filter: SelectColumnFilter, // new
        filter: "includes", // new
      },
      {
        Header: "Manager",
        accessor: "Manager",
        Filter: SelectColumnFilter, // new
        filter: "includes", // new
      },
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: RenderActions,
      },
    ],
    []
  );

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const getData = () => {
    const data = employees;
    console.log("employeeData", data);
    return data;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-0 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Employees
          </h1>
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <FeatureFlag featureName="Import Employees" type="flag">
            <Button
              color="lime"
              handleClick={(e) => {
                console.log("Upload employees");
              }}
              className="btn bg-primary-500 hover:bg-primary-600 text-white"
            >
              <DocumentPlusIcon className="h-5 w-5" />
              <span className="hidden xs:block ml-2">Import Employees</span>
            </Button>
          </FeatureFlag>
          {/* <DeleteButton selectedItems={selectedItems} />

          <DateSelect />

          <FilterButton align="right" />

          <button className="btn bg-primary-500 hover:bg-primary-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add Order</span>
          </button> */}
        </div>
      </div>

      {/* Table */}
      {/* <OrdersTable selectedItems={handleSelectedItems} data={employees} /> */}
      <div
        id="tableCard"
        className="bg-white shadow-lg rounded-sm border border-gray-200 relative"
        style={{ maxHeight: "70vh", minHeight: "70vh" }}
      >
        <div className="px-4 sm:px-6 w-full max-w-9xl mx-auto">
          <div className="sm:flex sm:justify-between sm:items-center ">
            <header className=" py-4">
              <h2 className="font-semibold text-slate-800">
                Employees{" "}
                <span className="text-slate-400 font-medium">
                  {/* {datacheck !== null && datacheck.length} */}
                </span>
              </h2>
            </header>

            {/* Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"></div>
          </div>
        </div>

        <Table
          columns={columns}
          data={getData()}
          loading={loading}
          fromDashboard={true}
        />
        {/* <Table
          loading={loading}
          style={{ maxHeight: "65vh", minHeight: "65vh" }}
          selectedItems={handleSelectedItems}
          columns={columns}
          data={employees}
          viewDetails={viewDetails}
        /> */}
      </div>

      {/* Pagination */}
      {/* <div className="mt-8">
        <PaginationClassic />
      </div> */}
    </div>
  );
}

export default Orders;
