/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
// import { templates } from "../modules/_templates";
// import AssessmentSelect from "../AssessmentSelect";
// import { Projects } from "../modules/_projects";

import RightActionArea from "../components/ActionAreaRight";
import Button from "../components/Button";
import ModalBasic from "../components/ModalBasic";
// import NewProject from "./NewProject";
// import ProjectDetails from "./ProjectDetails";
import { utils } from "../modules/_utils";
import { _assessment } from "../modules/_assessment";
import { _auth } from "../modules/_auth";

import { TrashIcon } from "@heroicons/react/20/solid";
import AssessmentSelect from "./AssessmentSelect";

export default function ManualsList(props) {
  const history = useHistory();
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [newProjectStep, setNewProjectStep] = useState(1);
  const [selectedProject, setSelectedProject] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [deletionEvent, setDeletionEvent] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedProjectModalOpen, setSelectedProjectModalOpen] =
    useState(false);

  let profile = _auth.getUserProfile();

  console.log(profile, "profile");
  useEffect(() => {
    (async () => {
      const assessment_types = await _assessment.getTemplates();
      console.log("assessment_types", assessment_types);
      setData(assessment_types);
    })();
  }, []);

  useEffect(() => {
    console.log(">resppp");
    _auth.getAuth(history);
  }, []);
  const goToStartAssessment = (a) => {
    let type = a.key;
    let name = a.name;
    console.info("goToStartAssessment", type);
    let state = null;
    if (type != "pre") {
      state = {
        isPreassessment: false,
        assessment_type: type,
        assessment_name: name,
        resume: false,
        // new_history_item: _assessment.getJsonTemplate(),
      };
    } else {
      state = {
        isPreassessment: true,
        assessment_type: "pre",
        assessment_name: a.name,
        resume: false,
      };
    }

    return history.push({
      pathname: "/assessment",
      state: state,
    });
  };

  // useEffect(() => {
  //   setNewProjectStep(1);
  //   alert("New Project Modal Open", newProjectStep);
  // }, [newProjectModalOpen]);

  const handleOpenProjectClick = (project) => {
    setSelectedProject(project);
    setSelectedProjectModalOpen(true);
  };

  const navigateToProject = (project) => {
    // alert("Navigate to project", JSON.stringify(project));
    const state = { project: project };
    history.push({
      pathname: "/projects/project-details",
      state: state,
    });
  };

  const handleOpenNewProjectModalClick = () => {
    setNewProjectStep(1);
    setNewProjectModalOpen(true);
  };

  const handleAfterCreateProject = (project) => {
    console.log("Project Created", project);
    //update data to include new project
    let oldData = data;
    let newData = [...oldData, project];
    setData(newData);
    setNewProjectStep(1);
  };

  // const handleAfterDeleteProject = (id) => {
  //   console.log("Project Created", project);
  //   //update data to include new project
  //   let oldData = data;
  //   let newData = [...oldData, project];
  //   setData(newData);
  // };

  const handleCloseProjectClick = () => {
    setSelectedProject({});
    setSelectedProjectModalOpen(false);
  };

  // const showDeleteConfirm = (project) => {
  //   setShowDeleteConfirm(true);
  //   setSelectedProject(project);
  // };

  const handleCancelDeleteProjectClick = () => {
    // e.preventDefault();
    setSuccess(true);
    setDeletionEvent(null);
    setIdToDelete(null);
    setShowDeleteConfirm(false);
  };

  const handleDeleteProjectClick = (e, id) => {
    setShowDeleteConfirm(true);
    setDeletionEvent(e);
    setIdToDelete(id);
  };

  // const deleteProject = async (e) => {
  //   // setDeleteConfirmed(true);
  //   deletionEvent.preventDefault();
  //   console.log("Delete Project", idToDelete);
  //   const deleted = await Projects.delete(idToDelete);

  //   if (deleted) {
  //     //remove from data
  //     let oldData = data;
  //     let newData = oldData.filter((item) => item._id !== idToDelete);
  //     setData(newData);

  //     setSuccess(true);
  //     setDeletionEvent(null);
  //     setIdToDelete(null);
  //     setShowDeleteConfirm(false);
  //   }
  // };

  return (
    // Page Container
    <div id="page_container" className="sm:px-6 p-6 w-full max-w-9xl mx-auto">
      {/* Header area */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        <div className="mb-0 sm:mb-0 inline-flex ">
          <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
            Manuals
          </h1>
        </div>
        <div>
          <p className="text-sm text-slate-600 ml-2 mt-1">
            Tap any manual below to start an assessment
          </p>
        </div>
        {/* Right Action Area */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* <Button
            // size="sm"
            color="secondary"
            handleClick={() => handleOpenNewProjectModalClick()}
            // shadow
          >
            <svg
              className="w-3 h-3 fill-current opacity-90 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">New Manual</span>
          </Button> */}
        </div>
      </div>
      {/* Grid area for cards - make scrollable */}
      {/* <AssessmentSelect /> */}
      {/* <div className="grid grid-cols-12 gap-6"> */}
      <ul className="grid grid-cols-12  gap-6">
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            {
              return (
                <li
                  key={item.key}
                  className="col-span-full md:col-span-6 lg:col-span-4"
                >
                  <button
                    disabled={
                      item.status !== "published" && item.status !== "beta"
                    }
                    onClick={() => goToStartAssessment(item)}
                    // className="w-full h-full text-left py-3 px-4 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out"
                    className="w-full h-full text-left py-3 px-4 rounded bg-white 
                border border-gray-300
                shadow-sm duration-150 ease-in-out
                hover:border-gray-300 hover:shadow-md hover:bg-gray-50
                focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500
                
               "
                  >
                    <div className="flex items-center">
                      <div className="grow">
                        <div className="flex flex-wrap items-center justify-between mb-0.5">
                          <span className="font-semibold text-slate-800">
                            {item.name}
                            {item.status != "published" && (
                              <span className="normal-case text-xs italic text-primary-500 align-top ml-1">
                                {item.status}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="text-xs">{item.description}</div>
                      </div>
                    </div>
                  </button>
                </li>
                // <div
                //   className={`row-span-1 col-span-full xl:col-span-4
                //   bg-white hover:bg-gray-50 shadow-sm hover:shadow-lg
                //   border border-gray-200"
                //  rounded-md overflow-hidden grow-0`}
                // >
                //   <div className="flex flex-col h-full">
                //     <header className="flex items-center justify-between  px-3 py-3 border-b border-gray-100">
                //       <h2 className="  font-semibold text-slate-800">
                //         BC-{item.projectId} - {item.name}
                //       </h2>
                //       <RightActionArea>
                //         <div className="inline-flex items-center text-xs font-semibold text-primary-600 mr-4">
                //           Created {utils.formatDate(item.createdAt, "short")}
                //         </div>
                //
                //         <Button
                //           iconButton
                //           size="sm"
                //           color="secondary"
                //           handleClick={(e) =>
                //             handleDeleteProjectClick(e, item._id)
                //           }
                //         >
                //           <TrashIcon className="w-4 h-4" />
                //         </Button>
                //       </RightActionArea>
                //     </header>
                //     <div className="p-3 space-y-2">
                //       <div className="text-sm">
                //         <span className="font-semibold">Client:</span>{" "}
                //         {item.client && item.client.name}
                //       </div>
                //       <div className="text-sm">
                //         <span className="font-semibold">Deadline:</span>{" "}
                //         {utils.formatDate(item.deadline, "short")}
                //       </div>
                //       <p className="line-clamp-3 text-sm font-medium text-slate-600">
                //         {item.description}
                //       </p>
                //     </div>

                //     <footer className=" mt-auto items-center justify-end p-2 border-t border-gray-100">
                //       <RightActionArea>
                //
                //         <Button
                //           size="sm"
                //           handleClick={() => {
                //             //   alert("Navigate to project", JSON.stringify(item));
                //             navigateToProject(item);
                //           }}
                //           color="outline"
                //         >
                //
                //           Open
                //         </Button>
                //       </RightActionArea>
                //     </footer>
                //   </div>
                // </div>
              );
            }
          })}
        {/* </div> */}
      </ul>
    </div>
  );
}
